import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const LongArrowRightIcon = () => {
    return (
        <Svg
            width={13}
            height={12}
            viewBox="0 0 13 12"
            fill="none"
        >
            <Path
                d="M10.375 6.53h-7.5M7.35 3.517l3.026 3.012L7.35 9.542"
                stroke="#1F2136"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export { LongArrowRightIcon };
