import React, { useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import LoginInput from './LoginInput';
import { ILoginUI, LOGIN_UI_BUTTON_PRESSED_TYPES } from './LoginUtils';
import { MobileHeaderProps } from '../../helperComponents/Header/MobileHeader';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { imageUtils } from '../../../utils/imageUtils';
import { LogoText } from '../../helperComponents/LogoText';
import { BackButton } from '../../helperComponents/BackButton';
import { getColors } from '../../../constants/colors';

const COLORS = getColors();

const LoginUI = ({ onButtonPressed, email, setEmail, password, setPassword, errorMessage, setErrorMessage }: ILoginUI) => {
    const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: false, showBackArrow: true });
    return (
        <ComponentWrapper mobileHeaderOptions={mobileHeaderOptions.current} hideWebHeader innerContainerStyle={styles.container} >
            <MB_Image style={styles.backgroundImage} source={imageUtils.images.loginBackground} disableInteraction />
            <LogoText />

            <LoginInput
                containerStyle={[styles.loginInput]}
                onButtonPressed={onButtonPressed}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
            />

            <BackButton
                style={{ position: 'absolute', left: 20, top: 50 }}
                onPress={() => onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_HOME)}
                arrowColor={COLORS.white}
                textStyle={{color: COLORS.white}}
                title="Back to home page"
            />
        </ComponentWrapper>
    );
};

export default LoginUI;

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    loginInput: {
        marginTop: 26,
    },
    backgroundImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
});
