import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const InstagramIcon = React.memo(({ color = '#EEF2F7' }: { color?: string }) => {
    return (
        <Svg
            width={17}
            height={18}
            viewBox="0 0 17 18"
            fill="none"
        >
            <Path
                d="M8.39 6.666a2.181 2.181 0 00-2.176 2.177c0 1.198.978 2.177 2.176 2.177a2.181 2.181 0 002.177-2.177A2.181 2.181 0 008.39 6.666zm6.528 2.177c0-.901.009-1.794-.042-2.694-.05-1.045-.289-1.973-1.053-2.737-.766-.766-1.692-1.002-2.737-1.053-.901-.05-1.794-.042-2.694-.042-.901 0-1.794-.009-2.694.042-1.045.05-1.973.29-2.737 1.053-.765.766-1.002 1.692-1.053 2.737-.05.901-.042 1.794-.042 2.694 0 .9-.008 1.794.042 2.694.05 1.045.29 1.973 1.053 2.737.766.766 1.692 1.002 2.737 1.053.901.05 1.794.042 2.694.042.901 0 1.795.008 2.694-.042 1.045-.05 1.973-.29 2.737-1.053.766-.766 1.002-1.692 1.053-2.737.052-.9.042-1.793.042-2.694zM8.39 12.192a3.344 3.344 0 01-3.348-3.349A3.344 3.344 0 018.39 5.494a3.344 3.344 0 013.35 3.349 3.344 3.344 0 01-3.35 3.349zm3.486-6.053a.781.781 0 110-1.564.781.781 0 110 1.564z"
                fill={color}
            />
        </Svg>
    );
});

export { InstagramIcon };
