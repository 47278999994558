import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

const TwitterIcon = React.memo(({ color = '#EEF2F7' }: { color?: string }) => {
    return (
        <Svg
            width={17}
            height={18}
            viewBox="0 0 17 18"
            fill="none"
        >
            <G clipPath="url(#clip0_1021_13355)">
                <Path
                    d="M5.586 15.12c5.915 0 9.15-4.9 9.15-9.15 0-.14-.003-.278-.009-.416a6.545 6.545 0 001.604-1.665 6.416 6.416 0 01-1.846.506 3.228 3.228 0 001.414-1.778 6.45 6.45 0 01-2.042.78 3.216 3.216 0 00-5.48 2.933 9.131 9.131 0 01-6.63-3.36 3.213 3.213 0 00.996 4.293 3.19 3.19 0 01-1.457-.402v.041a3.217 3.217 0 002.58 3.153 3.229 3.229 0 01-1.453.055 3.22 3.22 0 003.005 2.234 6.452 6.452 0 01-3.995 1.376c-.259 0-.515-.015-.767-.044a9.104 9.104 0 004.93 1.444"
                    fill={color}
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1021_13355">
                    <Path
                        fill="#fff"
                        transform="translate(.14 .483)"
                        d="M0 0H16.72V16.72H0z"
                    />
                </ClipPath>
            </Defs>
        </Svg>
    );
});

export { TwitterIcon };
