import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, Text, TextStyle } from 'react-native';
import { getColors } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { MB_Image, mbGetSmallestImage } from '@mightybyte/rnw.components.image';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { EmptyProfileIconSvg } from '../../resources/svgComponents/EmptyProfileIconSvg';
import { ImageUrls } from '@id/business';

const COLORS = getColors();

interface IProfileIcon {
    containerStyle?: StyleProp<ViewStyle>,
    image: ImageUrls | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    fontStyle?: StyleProp<TextStyle>,
}

const ProfileIcon = ({ containerStyle, image, firstName, lastName, fontStyle }: IProfileIcon) => {
    const profileImage = mbGetSmallestImage(image, { asUri: true });

    if (profileImage) {
        return (
            <View style={[styles.container, containerStyle]}>
                <MB_Image style={styles.imageStyle} source={profileImage} disableInteraction />
            </View>
        );
    }

    return (
        <View style={[styles.container, containerStyle]}>
            {firstName && lastName ?
                <Text style={[styles.noImageText, fontStyle]}>{firstName.charAt(0)}{lastName.charAt(0)}</Text>
                :
                <EmptyProfileIconSvg containerStyle={{ width: '100%', height: '100%' }} />
            }
        </View>
    );
};

export { ProfileIcon };

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        width: 44,
        height: 44,
        borderRadius: 300,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.backgroundPrimary,
        overflow: 'hidden',
    },
    imageStyle: {
        width: '100%',
        height: '100%',
    },
    noImageText: mbTextStyles([
        textStyles.normalText, {
            fontWeight: 'bold',
        },
    ]),
});
