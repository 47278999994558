import * as Font from 'expo-font';

import {
    PlusJakartaSans_200ExtraLight,
    PlusJakartaSans_300Light,
    PlusJakartaSans_400Regular,
    PlusJakartaSans_500Medium,
    PlusJakartaSans_600SemiBold,
    PlusJakartaSans_700Bold,
    PlusJakartaSans_800ExtraBold,
    PlusJakartaSans_200ExtraLight_Italic,
    PlusJakartaSans_300Light_Italic,
    PlusJakartaSans_400Regular_Italic,
    PlusJakartaSans_500Medium_Italic,
    PlusJakartaSans_600SemiBold_Italic,
    PlusJakartaSans_700Bold_Italic,
    PlusJakartaSans_800ExtraBold_Italic,
} from '@expo-google-fonts/plus-jakarta-sans';

export const fontLoader = async (): Promise<any> => {
    const expoFontPromise = Font.loadAsync({
        AntDesign: require('../../node_modules/react-native-vector-icons/Fonts/AntDesign.ttf'),
        Feather: require('../../node_modules/react-native-vector-icons/Fonts/Feather.ttf'),
        FontAwesome: require('../../node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf'),
        MaterialCommunityIcons: require('../../node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
        MaterialIcons: require('../../node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
        Entypo: require('../../node_modules/react-native-vector-icons/Fonts/Entypo.ttf'),

        Jakarta_200: PlusJakartaSans_200ExtraLight,
        Jakarta_200_italic: PlusJakartaSans_200ExtraLight_Italic,
        Jakarta_300: PlusJakartaSans_300Light,
        Jakarta_300_italic: PlusJakartaSans_300Light_Italic,
        Jakarta_400: PlusJakartaSans_400Regular,
        Jakarta_400_italic: PlusJakartaSans_400Regular_Italic,
        Jakarta_500: PlusJakartaSans_500Medium,
        Jakarta_500_italic: PlusJakartaSans_500Medium_Italic,
        Jakarta_600: PlusJakartaSans_600SemiBold,
        Jakarta_600_italic: PlusJakartaSans_600SemiBold_Italic,
        Jakarta_700: PlusJakartaSans_700Bold,
        Jakarta_700_italic: PlusJakartaSans_700Bold_Italic,
        Jakarta_800: PlusJakartaSans_800ExtraBold,
        Jakarta_800_italic: PlusJakartaSans_800ExtraBold_Italic,
    });

    return Promise.all([expoFontPromise]);
};
