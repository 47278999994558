import React, { useRef, useState } from 'react';
import { SERVER_ERROR_CODES, STRING_CONSTANTS } from '../../../constants/constants';
import { useQueryClient } from '@tanstack/react-query';
import { UniversalProps } from '../../../typesAndInterfaces/componentProps';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../context/SignedInContext';
import SignupUI from './SignupUI';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { MB_passwordUtils, MB_PASSWORD_UTIL_ERROR_TYPES } from '@mightybyte/rnw.utils.password-utils';
import { MB_TextInputToolTipPortalRef } from '@mightybyte/rnw.components.text-input';

const SignUp = ({ navigation }: UniversalProps) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [signUpCode, setSignUpCode] = useState('');
  const queryClient = useQueryClient();
  const { setSignedInStatus } = useSignedInContext();


  const generatePasswordErrorArray = (errorArray: MB_PASSWORD_UTIL_ERROR_TYPES[], doPasswordsMatch: boolean): { message: string, isError: boolean }[] => {
    const errors: { message: string, isError: boolean }[] = [];

    errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.min, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.min) || errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.max) });
    errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.lowercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.lowercase) });
    errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.uppercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.uppercase) });
    errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.digits, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.digits) });
    errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.spaces, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.spaces) });
    if (errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.unknown)) {
      errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.unknown, isError: true });
    }

    errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.passwordMismatch, isError: !doPasswordsMatch });

    return errors;
  };

  const [passwordErrorArray, setPasswordErrorArray] = useState<{ message: string, isError: boolean }[]>(generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));

  const signUp = useSignUp();

  const makeSignUpRequest = () => {
    if (!validateFields) {
      return;
    }

    if (!signUpCode) {
      setErrorMessage('Please enter your register code');
      return false;
    }

    signUp.mutate(
      {
        email,
        firstName: firstName,
        lastName: lastName,
        password,
        signUpCode,
      },
      {
        onSuccess: data => {
          MB_accessTokenUtils.setAccessToken(data);
          setSignedInStatus(SIGNED_IN_STATUS.signedIn);
          queryClient.invalidateQueries([USER_QUERY_KEYS.getCurrentUserData]);
        },
        onError: error => {
          if (error.errorCode === SERVER_ERROR_CODES.EMAIL_ALREADY_IN_USE) {
            setErrorMessage(STRING_CONSTANTS.EMAIL_ALREADY_IN_USE);
            return;
          }

          setErrorMessage('Failed to sign up, please try again');
        },
      },
    );
  };

  const validateFields = () => {
    if (!firstName) {
      setErrorMessage('First Name is a required field');
      return false;
    } else if (!lastName) {
      setErrorMessage('Last Name is a required field');
      return false;
    } else if (!email) {
      setErrorMessage('Email is a required field');
      return false;
    } else if (!password) {
      setErrorMessage('Password is a required field');
      return false;
    }
    else if (confirmPassword !== password) {
      setErrorMessage('Re-enter password doesn\'t match password');
      return false;
    }

    const validatePassword = MB_passwordUtils.validatePassword(password);

    if (validatePassword.errorMessage) {
      setErrorMessage(validatePassword.errorMessage);
      return false;
    }

    return true;
  };

  const onSubmitPressed = () => {
    makeSignUpRequest();
  };


  //todo: implement navigateToHome
  //const navigateToHome = () => navigation.navigate('HomeNavigator', { screen: 'GameNavigator' });
  const navigateToHome = ()=> {console.log('navigateToHome')}
  const navigateToLogin = () => navigation.navigate('Login');

  const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

  return <SignupUI
    firstName={firstName}
    lastName={lastName}
    setFirstName={setFirstName}
    setLastName={setLastName}
    setErrorMessage={setErrorMessage}
    email={email}
    password={password}
    confirmPassword={confirmPassword}
    setEmail={setEmail}
    setPassword={setPassword}
    setConfirmPassword={setConfirmPassword}
    setPasswordErrorArray={setPasswordErrorArray}
    generatePasswordErrorArray={generatePasswordErrorArray}
    portalRef={portalRef}
    passwordErrorArray={passwordErrorArray}
    signUpCode={signUpCode}
    setSignUpCode={setSignUpCode}
    errorMessage={errorMessage}
    onSubmitPressed={onSubmitPressed}
    signUp={signUp}
    navigateToHome={navigateToHome}
    navigateToLogin={navigateToLogin}
  />;
};

export { SignUp };
