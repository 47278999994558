import React from 'react';

import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { textStyles } from '../../constants/textStyles';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

const LiveAndOfflinePill = ({ style, isLive }: { style?: StyleProp<ViewStyle>, isLive: boolean }) => {
    return (
        <View style={[styles.container, { backgroundColor: isLive ? '#E5FFF8' : '#FFEEEE' }, style]}>
            <Text style={[styles.label, { color: isLive ? '#17B472' : '#DD3636' }]}>{isLive ? 'LIVE' : 'OFFLINE'}</Text>
        </View>
    );
};

export { LiveAndOfflinePill };



const styles = StyleSheet.create({
    container: {
        width: 67,
        height: 31,
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    label: mbTextStyles([
        textStyles.smallerText, {
            fontWeight: '600',
        },
    ]),
});
