import React, { useRef } from 'react';
import { Animated, Dimensions, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Pressable } from 'react-native';
import {
  mbApplyTransparency,
  mbTextStyles,
} from '@mightybyte/rnw.utils.style-utils';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { textStyles } from '../../../constants/textStyles';
import { HamBurgerIcon } from '../../../resources/svgComponents/HamBurgerIcon';
import { useNavigation } from '@react-navigation/core';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';


const Menu = () => {
  const [isMenuOpen, showMenu, hideMenu] = MB_utilHooks.useBool();


  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const moveXAnim = useRef(new Animated.Value(0)).current;
  const colorAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const navigation = useNavigation<UniversalScreenNavigationProp>();


  const startAnimations = Animated.parallel([
    Animated.timing(moveXAnim, {
      toValue: -320,
      duration: 300,
      useNativeDriver: true,
    }),
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }),
    Animated.timing(colorAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: false,
    }),
  ]);

  const closeAnimation = Animated.parallel([
    Animated.timing(moveXAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }),
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }),
    Animated.timing(colorAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: false,
    }),
  ]);

  const onBackDropPress = () => {
    hideMenu();
    closeAnimation.start();
  };

  const onMenuIcon = () => {
    if (isMenuOpen) {
      hideMenu(); // TODO: We should probably use a reducer for hideMenu so we can set the valeu and cll close Animation at the same time.
      closeAnimation.start();
    } else {
      showMenu();
      startAnimations.start();
    }
  };


  const onMenuItem = (menuItem: 'AboutUs' | 'ContactUs') => {
    hideMenu();
    closeAnimation.start();
    navigation.navigate(menuItem);
  };

  return (
    <>

        <Pressable style={styles.menuIcon} onPress={() => onMenuIcon()}>
          <HamBurgerIcon color="#FFF" size={20} />
        </Pressable>

      <Animated.View
        style={[
          styles.container, {
            transform: [{ translateX: moveXAnim }],
          },
        ]}
      >

        <TouchableOpacity style={styles.item} onPress={() => onMenuItem('AboutUs')}>
          <Text style={styles.itemText}>About Us</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item}>
          <Text style={styles.itemText}>Advertise</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => onMenuItem('ContactUs')}>
          <Text style={styles.itemText}>Contact</Text>
        </TouchableOpacity>

      </Animated.View>
      <Animated.View
        pointerEvents={isMenuOpen ? 'auto' : 'none'}
        style={[
          styles.backdrop, {
            opacity: fadeAnim,
          },
        ]}
      >
        <Pressable onPress={onBackDropPress} style={styles.backdropPressable} />
      </Animated.View>
    </>
  );
};

export default React.memo(Menu);

const styles = StyleSheet.create({
  menuIconWrapper: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  menuIcon: {},
  container: {
    position: 'fixed' as 'absolute',
    height: Dimensions.get('window').height,
    width: 320,
    zIndex: 100,
    right: -320,
    backgroundColor: 'white',
    top: 0,
    paddingTop: 40,
  },
  backdrop: {
    position: 'fixed' as 'absolute',
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
    backgroundColor: mbApplyTransparency('#000000', 0.4),
    left: 0,
    top: 0,
  },
  backdropPressable: {
    width: '100%',
    height: '100%',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    marginBottom: 10,
    paddingLeft: 20,
  },
  itemText: mbTextStyles([
    textStyles.smallText, {
      color: '#757575',
      textAlign: 'left',
      paddingLeft: 12,
    },
  ]),
  selectedItemText: mbTextStyles([
    textStyles.smallText, {
      color: '#BC4DF4',
      textAlign: 'left',
      paddingLeft: 12,
    },
  ]),
  divider: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#e1e1e1',
    marginBottom: 20,
  },
  versionInfoText: {
    ...mbTextStyles({
      ...textStyles.tinyText,
      alignSelf: 'flex-start',
      color: '#000000',
    }),
  },
});
