import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

const ShopIcon = React.memo(() => {
    return (
        <Svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
        >
            <G clipPath="url(#clip0_1004_11797)">
                <Path d="M5.602 15.2h28.8v22.373h-28.8V15.2z" fill="#C4E2FF" />
                <Path
                    d="M18.127 32.75a1.276 1.276 0 01-1.275-1.275v-8.55c0-.703.572-1.275 1.275-1.275h13.35c.703 0 1.275.572 1.275 1.275v8.55c0 .703-.572 1.275-1.275 1.275h-13.35z"
                    fill="#B3DAFE"
                />
                <Path
                    d="M32.002 6.373h-24V1.325c0-.29.235-.525.525-.525h22.95c.29 0 .525.235.525.525v5.048z"
                    fill="#FFE177"
                />
                <Path
                    d="M8.002 1.325v5.048h3.09V.8H8.528a.525.525 0 00-.525.525z"
                    fill="#FFD15B"
                />
                <Path
                    d="M27.2 3.78H12.8a.58.58 0 110-1.159h14.4a.58.58 0 010 1.16z"
                    fill="#EAF6FF"
                />
                <Path
                    d="M34.402 8.773h-28.8V6.125c0-.29.235-.525.525-.525h27.75c.29 0 .525.235.525.525v2.648z"
                    fill="#C4E2FF"
                />
                <Path
                    d="M5.602 6.125v2.648H7.92V5.6H6.127a.525.525 0 00-.525.525zM5.602 15.2v22.373h2.4V18.33a3.925 3.925 0 002.4.816c.902 0 1.734-.305 2.4-.816a3.925 3.925 0 002.4.816c.902 0 1.734-.305 2.4-.816a3.925 3.925 0 002.4.816c.902 0 1.734-.305 2.4-.816a3.925 3.925 0 002.4.816c.902 0 1.734-.305 2.4-.816a3.925 3.925 0 002.4.816c.902 0 1.734-.305 2.4-.816a3.925 3.925 0 002.4.816V15.2h-28.8z"
                    fill="#B3DAFE"
                />
                <Path d="M8.001 12.8h-4.8l2.4-4.8h4.114l-1.714 4.8z" fill="#EA433F" />
                <Path d="M12.802 12.8h-4.8L9.716 8h4.115l-1.029 4.8z" fill="#EAF6FF" />
                <Path
                    d="M17.6 12.8h-4.8L13.83 8h4.114l-.343 4.8zM27.201 12.8h-4.8L22.06 8h4.114l1.028 4.8zM36.801 12.8h-4.8L30.287 8h4.114l2.4 4.8z"
                    fill="#EA433F"
                />
                <Path
                    d="M5.601 17.6a2.4 2.4 0 01-2.4-2.4v-2.4h4.8v2.4a2.4 2.4 0 01-2.4 2.4z"
                    fill="#D32F2F"
                />
                <Path
                    d="M10.402 17.6a2.4 2.4 0 01-2.4-2.4v-2.4h4.8v2.4a2.4 2.4 0 01-2.4 2.4z"
                    fill="#D8ECFE"
                />
                <Path
                    d="M15.2 17.6a2.4 2.4 0 01-2.4-2.4v-2.4h4.8v2.4a2.4 2.4 0 01-2.4 2.4z"
                    fill="#D32F2F"
                />
                <Path
                    d="M20.002 17.6a2.4 2.4 0 01-2.4-2.4v-2.4h4.8v2.4a2.4 2.4 0 01-2.4 2.4z"
                    fill="#D8ECFE"
                />
                <Path
                    d="M24.8 17.6a2.4 2.4 0 01-2.4-2.4v-2.4h4.8v2.4a2.4 2.4 0 01-2.4 2.4z"
                    fill="#D32F2F"
                />
                <Path
                    d="M29.601 17.6a2.4 2.4 0 01-2.4-2.4v-2.4h4.8v2.4a2.4 2.4 0 01-2.4 2.4z"
                    fill="#D8ECFE"
                />
                <Path
                    d="M34.402 17.6a2.4 2.4 0 01-2.4-2.4v-2.4h4.8v2.4a2.4 2.4 0 01-2.4 2.4z"
                    fill="#D32F2F"
                />
                <Path
                    d="M12.802 12.8h-4.8L9.716 8h4.115l-1.029 4.8zM22.402 12.8h-4.8l.342-4.8h4.115l.343 4.8zM32.002 12.8h-4.8L26.174 8h4.114l1.714 4.8z"
                    fill="#EAF6FF"
                />
                <Path
                    d="M7.252 37.55V22.925c0-.703.572-1.275 1.275-1.275h6.15c.703 0 1.275.572 1.275 1.275V37.55h-8.7z"
                    fill="#B3DAFE"
                />
                <Path
                    d="M15.202 36.8h-7.2V22.925c0-.29.235-.525.525-.525h6.15c.29 0 .525.235.525.525V36.8z"
                    fill="#528FD8"
                />
                <Path
                    d="M8.002 22.925V36.8h2.318V22.4H8.527a.525.525 0 00-.525.525z"
                    fill="#0573CE"
                />
                <Path
                    d="M12.8 31.38a.58.58 0 01-.58-.58v-1.2a.58.58 0 111.16 0v1.2c0 .32-.26.58-.58.58zM32.002 29.6l-1.07.773H18.89l-1.288-.773v-6.675c0-.29.235-.525.525-.525h13.35c.29 0 .525.235.525.525V29.6z"
                    fill="#EAF6FF"
                />
                <Path
                    d="M17.602 22.925V29.6l1.159.773h1.159V22.4h-1.793a.525.525 0 00-.525.525z"
                    fill="#D8ECFE"
                />
                <Path
                    d="M32.002 29.6h-14.4v1.875c0 .29.235.525.525.525h13.35c.29 0 .525-.235.525-.525V29.6z"
                    fill="#528FD8"
                />
                <Path
                    d="M17.602 29.6v1.875c0 .29.235.525.525.525h1.793v-2.4h-2.318z"
                    fill="#0573CE"
                />
                <Path
                    d="M36.801 39.2h-33.6v-1.24c0-.64.519-1.16 1.16-1.16h31.281c.64 0 1.16.52 1.16 1.16v1.24z"
                    fill="#FFE177"
                />
                <Path
                    d="M6.678 36.8H4.36c-.64 0-1.159.52-1.159 1.16v1.24H5.52v-1.24c0-.64.519-1.16 1.16-1.16z"
                    fill="#FFD15B"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1004_11797">
                    <Path
                        fill="#fff"
                        transform="translate(.8 .8)"
                        d="M0 0H38.4V38.4H0z"
                    />
                </ClipPath>
            </Defs>
        </Svg>
    );
});

export { ShopIcon };
