import React, { useCallback, useMemo, useRef } from 'react';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { getColors } from '../../../../constants/colors';
import { BackButton } from '../../../helperComponents/BackButton';
import { CreateOrEditCompanyNavProps } from '../../../../typesAndInterfaces/componentProps';
import { MB_ToggleSwitch } from '@mightybyte/rnw.components.toggle-switch';
import { MB_TabView, MB_TabViewRef } from '@mightybyte/rnw.components.tab-view';
import { CreateCompanyBasicDetails } from './CreateCompanyBasicDetails';
import { CreateCompanyTagsAndDescription } from './CreateCompanyTagsAndDescription';
import { CreateCompanyProductsAndServices } from './CreateCompanyProductsAndServices';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { trpc } from '../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { CompanyStatus } from '@id/business';

const COLORS = getColors();

const CreateOrEditCompany = ({ navigation, route }: CreateOrEditCompanyNavProps) => {
  const companyId = route.params?.companyId;

  const trpcContext = trpc.useContext();

  const { data: companyToEdit, isLoading: isLoadingCompany } = trpc.admin.company.getById.useQuery({ id: companyId ?? '' }, { enabled: companyId !== undefined });

  const { mutate: editCompany, isLoading: isLoadingCompanyEdit } = trpc.admin.company.edit.useMutation({
    onSuccess: () => trpcContext.admin.company.getById.invalidate({ id: companyId }),
    onError: () => mbShowPopUp({ title: STRING_CONSTANTS.ERROR, message: STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN }),
  });

  const tabRef = useRef<MB_TabViewRef>(null);

  const onCompanyCreatedOrEdited = useCallback((id: string) => {
    if (!companyId) {
      navigation.replace('CreateOrEditCompany', { companyId: id });
    } else {
      trpcContext.admin.company.getById.invalidate({ id: companyId });
    }
  }, [companyId, navigation, trpcContext.admin.company.getById]);

  const tabData = useMemo(() => {
    return {
      titles: ['Basic Details', ...(companyId ? ['Tags & Description', 'Products & Services'] : [])],
      screens: [() =>
        <CreateCompanyBasicDetails companyToEdit={companyToEdit?.company} onCreatOrEdited={onCompanyCreatedOrEdited} />,
      ...((companyId && companyToEdit?.company) ? [() => <CreateCompanyTagsAndDescription companyToEdit={companyToEdit.company} onEdited={onCompanyCreatedOrEdited} />, () => <CreateCompanyProductsAndServices companyToEdit={companyToEdit.company} />] : []),
      ],
    };
  }, [companyId, companyToEdit, onCompanyCreatedOrEdited]);

  function onBackPressed() {
    navigation.reset({
      index: 0,
      routes: [{ name: 'Companies' }],
    });
  }

  if (companyId && (!companyToEdit || isLoadingCompany)) {
    return (<LoadingPage />);
  }

  // TODO: Hovo: We might need to hide the toggle when creating the company.

  return (
    <ComponentWrapper innerContainerStyle={styles.container} hideHeaderLogo>

      <View style={styles.topContainer}>

        <BackButton style={{ position: 'absolute', zIndex: 1, left: 0 }} onPress={onBackPressed} />
        <Text style={styles.title}>{companyToEdit ? companyToEdit.company.name : 'Add Company'}</Text>
        {companyId &&
          <View style={{ flexDirection: 'row', marginStart: 16 }}>
            <MB_ToggleSwitch // TODO: HOvo: need to disable is company is loading
              isToggled={companyToEdit?.company.status === CompanyStatus.live}
              onToggle={(newValue) => editCompany({ id: companyId, status: newValue ? CompanyStatus.live : CompanyStatus.offline })}
              trackBar={styles.toggleSwitchTrack}
              thumbButton={styles.toggleSwitchThumb}
            />
            <Text style={styles.toggleSwitchText}>{companyToEdit?.company.status === CompanyStatus.live ? 'Live' : 'Offline'}</Text>
            {isLoadingCompanyEdit &&
              <View style={{ position: 'absolute', backgroundColor: '#00000066', width: '100%', height: '100%', borderRadius: 16 }} />
            }
          </View>
        }
      </View>

      <MB_TabView
        MB_Ref={tabRef}
        titles={tabData.titles}
        screens={tabData.screens}
        style={{ backgroundColor: 'transparent' }}
        tabLabelStyle={styles.tabText}
        tabIndicatorStyle={styles.tabIndicatorStyle}
        swipeEnabled={false}
        tabStyle={styles.tabStyle}
        indicatorContainerStyle={styles.tabIndicatorContainer}
      />


    </ComponentWrapper>
  );
};

export { CreateOrEditCompany };

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 44,
    backgroundColor: COLORS.grayBackground,
    paddingTop: 26,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    height: 44,
    marginBottom: 20,
  },
  title: mbTextStyles([
    textStyles.largerText, {
      fontSize: 21,
      color: COLORS.primaryColor,
      marginTop: 5,
    },
  ]),
  noCompaniesTitle: mbTextStyles([
    textStyles.largeText, {
      fontWeight: '600',
      color: COLORS.primaryColor,
      marginTop: 20,
    },
  ]),
  noCompaniesSubtitle: mbTextStyles([
    textStyles.smallText, {
      fontSize: 13,
      fontWeight: '400',
      color: COLORS.bodyText2,
      marginTop: 6,
    },
  ]),
  noCompaniesButton: {
    marginTop: 20,
  },
  tabStyle: {
    borderRadius: 50,
    width: 700,
    backgroundColor: COLORS.white,
    alignSelf: 'center',
    shadowColor: 'transparent',
  },
  tabIndicatorStyle: {
    height: undefined,
    top: 0,
    bottom: 0,
    backgroundColor: COLORS.lightBlue,
    borderRadius: 50,
  },
  tabIndicatorContainer: {
    backgroundColor: COLORS.white,
    borderRadius: 50,
    marginBottom: 32,
    padding: 4,
    alignSelf: 'center',
  },
  tabText: mbTextStyles([
    textStyles.smallText, {
      fontSize: 13,
      fontWeight: '500',
      color: COLORS.bodyText2,
      marginTop: 6,
    },
  ]),
  toggleSwitchTrack: {
    activeBackgroundColor: COLORS.secondaryColor,
    inActiveBackgroundColor: COLORS.strokes,
    width: 50,
    radius: 30,
    height: 30,
    borderWidth: 3,
    borderActiveColor: COLORS.secondaryColor,
    borderInActiveColor: COLORS.strokes,
  },
  toggleSwitchThumb: {
    activeBackgroundColor: COLORS.white,
    inActiveBackgroundColor: COLORS.white,
    height: 30,
    width: 30,
  },
  toggleSwitchText: mbTextStyles([
    textStyles.normalText, {
      color: COLORS.primaryColor,
      marginStart: 8,
      marginTop: 10,
      minWidth: 70,
      textAlign: 'left',
    },
  ]),
});
