import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { MB_passwordUtils } from '@mightybyte/rnw.utils.password-utils';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

import { MB_PasswordToolTip, TOOLTIP_POSITION, TOOLTIP_ARROW_DIRECTION } from '@mightybyte/rnw.components.tool-tip';
import { textStyles } from '../../../constants/textStyles';
import { SignupUIProps } from './SignupUtils';
import { getColors } from '../../../constants/colors';

interface SignupInputProps extends SignupUIProps {
  containerStyle?: StyleProp<ViewStyle>,
}

const SignupInput = ({
  containerStyle,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  errorMessage,
  setErrorMessage,
  signUp,
  onSubmitPressed,
  passwordErrorArray,
  setPasswordErrorArray,
  generatePasswordErrorArray,
  portalRef,
}: SignupInputProps) => {
  return (
    <View style={[styles.container, containerStyle]}>
        <>
          <View style={styles.signupCodeContainerMobile}>
            <MB_TextInput
              style={styles.textInputContainerStyle}
              value={firstName}
              onChangeText={(newText: string) => {
                setFirstName(newText);
                setErrorMessage('');
              }}
              title="First Name *"
              placeholder="ex: Alex"
              maxLength={25}
            />
            <MB_TextInput
              style={[styles.textInputContainerStyle, styles.lastName]}
              value={lastName}
              onChangeText={(newText: string) => {
                setLastName(newText);
                setErrorMessage('');
              }}
              title="Last Name *"
              placeholder="ex: Doe"
              maxLength={25}
            />
          </View>
          <MB_TextInput
            style={[styles.textInputContainerStyle, styles.textInputWrapperStyle]}
            value={email}
            onChangeText={(newText: string) => {
              setEmail(newText);
              setErrorMessage('');
            }}
            title="Email *"
            placeholder="you@example.com"
            textContentType="emailAddress"
          />
          <MB_TextInput
            style={[styles.textInputContainerStyle, styles.textInputWrapperStyle]}
            value={password}
            onChangeText={(newText: string) => {
              setPassword(newText);
              setErrorMessage('');
              setPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === confirmPassword));
            }}
            onFocus={() => {
              setErrorMessage('');
              setPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(password).errorArray, password === confirmPassword));
            }}
            title="Password *"
            secureTextEntry
            wrapInFormElement
            textContentType="password"
            placeholder="Enter password"
            maxLength={256}
            toolTipData={{
              portalRef: portalRef,
              showOnFocus: true,
              toolTipPosition: TOOLTIP_POSITION.top,
              moveBy: {
                x:  0,
                y: -185,
              },
              toolTipElement: <MB_PasswordToolTip errorArray={passwordErrorArray} arrowDirection={TOOLTIP_ARROW_DIRECTION.down } />,
            }}
          />
          <MB_TextInput
            style={[styles.textInputContainerStyle, styles.textInputWrapperStyle]}
            value={confirmPassword}
            onChangeText={(newText: string) => {
              setConfirmPassword(newText);
              setErrorMessage('');
              setPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === password));
            }}
            onFocus={() => {
              setErrorMessage('');
              setPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(confirmPassword).errorArray, confirmPassword === password));
            }}
            title="Confirm password *"
            placeholder="Re-enter password"
            secureTextEntry
            wrapInFormElement
            textContentType="password"
            maxLength={256}
            toolTipData={{
              portalRef: portalRef,
              showOnFocus: true,
              toolTipPosition: TOOLTIP_POSITION.left,
              moveBy: {
                x:  0,
                y: -60,
              },
              toolTipElement: <MB_PasswordToolTip errorArray={passwordErrorArray} arrowDirection={TOOLTIP_ARROW_DIRECTION.down } containerStyle={{ marginTop: '-33%' }} />,
            }}
          />
        </>
      )
      {errorMessage !== undefined && errorMessage !== '' && <Text style={textStyles.popUpErrorMessageText}>{errorMessage}</Text>}
      <MB_Button
        title={'Next'}
        textStyle={textStyles.normalText}
        onPress={onSubmitPressed}
        style={[styles.submitButton, { marginTop: errorMessage ? 16 : 28 }]}
        disabled={false}
        loading={signUp.isLoading}
      />
    </View>
  );
};

export default SignupInput;

const COLORS = getColors();

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  textInputWrapperStyle: {
    marginTop: 24,
  },
  textInputContainerStyle: {
    ...mbPlatformStyle({
      mobile: {
        width: '100%',
        marginTop: 14,
        paddingLeft: 14,
        backgroundColor: COLORS.backgroundPrimary,
        borderWidth: 0,
        borderRadius: 10,
      },
    }),
  },
  greenText: {
    color: '#44ffff',
  },
  signupCodeContainerMobile: {
    width: '100%',
  },

  lastName: {
    marginTop: 28,
  },
  submitButton: {
    borderRadius: 10,
    marginTop: 28,
    backgroundColor: COLORS.buttonPrimaryBg,
    alignSelf: 'stretch',
    width: undefined,
  },
  textInputTitleStyle: {
    fontWeight: '600',
    color: COLORS.textPrimary,
  },
});
