import React, { useCallback } from 'react';
import { View, Text, StyleProp, ViewStyle, TouchableOpacity } from 'react-native';
import { InstagramIcon } from '../../../resources/svgComponents/InstagramIcon';
import { LinkedInIcon } from '../../../resources/svgComponents/LinkedInIcon';
import { TwitterIcon } from '../../../resources/svgComponents/TwitterIcon';
import { LogoText } from '../../helperComponents/LogoText';
import { textStyles } from '../../../constants/textStyles';
import { getColors } from '../../../constants/colors';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { useNavigation } from '@react-navigation/core';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import DynamicStyleSheet, { useDynamicStyles } from '../../../utils/DynamicStyleSheet';

const colors = getColors();

const Footer = ({ style }: { style?: StyleProp<ViewStyle> }) => {

    const styles = useDynamicStyles(dynamicStyles);
    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const comingSoon = useCallback(() => {
        mbShowPopUp({
            title: 'Coming Soon',
            message: 'this feather coming soon!',
        });
    }, []);

    return (
        <View style={[styles.footer, style]}>
            <View style={styles.footerContainer}>
                <View style={styles.footerLogo}>
                    <LogoText />
                    <View style={styles.footerIcons}>
                        <TouchableOpacity onPress={comingSoon}>
                            <LinkedInIcon />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={comingSoon}>
                            <TwitterIcon />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={comingSoon}>
                            <InstagramIcon />
                        </TouchableOpacity>
                    </View>
                </View>
                {/*
                <View>
                    <Text style={styles.footerItemTitle}>Explore Suppliers</Text>
                    <Text onPress={comingSoon} style={styles.footerListElmText}>List of Suppliers</Text>
                    <Text onPress={comingSoon} style={styles.footerListElmText}>Sports Category/Arena Type</Text>
                    <Text onPress={comingSoon} style={styles.footerListElmText}>Equipments</Text>
                </View>
                */}
                <View style={styles.footerSpacer} />
                <View>
                    <Text style={styles.footerItemTitle}>For Suppliers</Text>
                    <Text onPress={comingSoon} style={styles.footerListElmText}>Advertise With Us</Text>
                </View>
                <View style={styles.footerSpacer} />
                <View>
                    <Text style={styles.footerItemTitle}>Quick Links</Text>
                    <Text onPress={() => navigation.navigate('ContactUs')} style={styles.footerListElmText}>Contact</Text>
                    <Text onPress={() => navigation.navigate('AboutUs')} style={styles.footerListElmText}>About Us</Text>
                    <Text onPress={() => navigation.navigate('Login')} style={styles.footerListElmText}>Login</Text>
                </View>
            </View>
            <View style={styles.footerSectionSpacer} />
            <View style={styles.footerInformation}>
                <Text style={styles.footerInformationText}>© 2023  International Sports Directory.</Text>
                <Text onPress={comingSoon} style={styles.footerInformationText}>Terms & Conditions</Text>
                <Text onPress={comingSoon} style={styles.footerInformationText}>Privacy Policy</Text>
            </View>
        </View>
    );
};

export { Footer };

const dynamicStyles = DynamicStyleSheet.create({
    footer: {
        marginTop: 156,
        backgroundColor: colors.primaryColor,
        paddingTop: 49,
        paddingBottom: 30,
        paddingHorizontal: 60,
        alignItems: ({ isMediumAndDown }) => isMediumAndDown ? 'center' : undefined,
    },
    footerContainer: {
        flexWrap: 'wrap',
        flexDirection: ({ isMediumAndDown }) => isMediumAndDown ? undefined : 'row',
        alignItems: ({ isMediumAndDown }) => isMediumAndDown ? 'center' : undefined,
    },
    footerLogo: {
        marginRight: 'auto',
    },
    footerIcons: {
        flexDirection: 'row',
        width: 104,
        justifyContent: 'space-between',
        alignSelf: 'center',
        marginTop: 20,
        marginBottom: ({ isMediumAndDown }) => isMediumAndDown ? -11 : undefined,
    },
    footerItemTitle: {
        ...textStyles.smallText,
        color: colors.lightBlue,
        fontWeight: '700',
        marginBottom: 3,
        marginTop: ({ isMediumAndDown }) => isMediumAndDown ? 41 : undefined,
        textAlign: ({ isMediumAndDown }) => isMediumAndDown ? 'center' : undefined,
    },
    footerListElmText: {
        ...textStyles.smallText,
        fontSize: 13,
        color: colors.lightBlue,
        fontWeight: '400',
        marginTop: 15,
        textAlign: ({ isMediumAndDown }) => isMediumAndDown ? 'center' : undefined,
    },
    footerSpacer: {
        width: ({ isMediumAndDown }) => isMediumAndDown ? 0 : 70,
    },
    footerInformation: {
        width: 457,
        justifyContent: 'space-between',
        marginTop: ({ isMediumAndDown }) => isMediumAndDown ? 12 : 81,
        flexDirection: ({ isMediumAndDown }) => isMediumAndDown ? undefined : 'row',
    },
    footerSectionSpacer: {
        backgroundColor: colors.strokes,
        height: 0.3,
        width: ({ isMediumAndDown }) => isMediumAndDown ? 217 : 0,
        marginTop: ({ isMediumAndDown }) => isMediumAndDown ? 24 : 0,
    },
    footerInformationText: {
        ...textStyles.smallerText,
        color: colors.lightBlue,
        marginTop: ({ isMediumAndDown }) => isMediumAndDown ? 10 : undefined,
    },
});
