import React, { useCallback, useMemo, useState } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { getColors } from '../../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { CompaniesNavProps } from '../../../typesAndInterfaces/componentProps';
import { EmptyTableView } from '../../helperComponents/EmptyTableView';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { MB_PageIndicator } from '@mightybyte/rnw.components.page-indicator';
import { DEFAULT_TABLE_MAX_ROWS } from '../../../constants/constants';
import { format as formatDate, parseISO } from 'date-fns';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { LiveAndOfflinePill } from '../../helperComponents/LiveAndOfflinePill';
import { AddButton } from '../../helperComponents/AddButton';
import { trpc } from '../../../apiCalls/trpcClient';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { CompanyStatus } from '@id/business';

const COLORS = getColors();

const headerStyles = StyleSheet.create({
  headerTextStyle: mbTextStyles([
    textStyles.smallerText, {
      fontWeight: '700',
      color: COLORS.primaryColor,
    },
  ]),
});

const header = [
  { content: 'No.', conatinerStyle: { height: 85, minWidth: 80, maxWidth: 80, paddingLeft: 22 }, textStyle: headerStyles.headerTextStyle },
  { content: 'COMPANY NAME', conatinerStyle: { height: 85, minWidth: 200, maxWidth: 200 }, textStyle: headerStyles.headerTextStyle },
  { content: 'EMAIL ADDRESS', conatinerStyle: { height: 85, minWidth: 180, maxWidth: 180 }, textStyle: headerStyles.headerTextStyle },
  { content: 'WEBSITE', conatinerStyle: { height: 85, minWidth: 180, maxWidth: 18 }, textStyle: headerStyles.headerTextStyle },
  { content: 'LOCATION', conatinerStyle: { height: 85, minWidth: 180, maxWidth: 180, paddingRight: 22 }, textStyle: headerStyles.headerTextStyle },
  { content: 'DATE ADDED', conatinerStyle: { height: 85, minWidth: 100, maxWidth: 100 }, textStyle: headerStyles.headerTextStyle },
  { content: 'STATUS', conatinerStyle: { height: 85, minWidth: 80, maxWidth: 80 }, textStyle: headerStyles.headerTextStyle },
];

const Companies = ({ navigation }: CompaniesNavProps) => {
  const trpcContext = trpc.useContext();

  const [page, setPage] = useState(1);

  const { data: companiesData, isFetching, isPreviousData } = trpc.admin.company.getPage.useQuery({ page, pageSize: DEFAULT_TABLE_MAX_ROWS }); // TODO: Hovo: What to do with token

  const keyExtractor = useCallback((index) => companiesData?.companies[index]?.id ?? index, [companiesData?.companies]);

  const deleteCompany = trpc.admin.company.delete.useMutation({
    onSuccess: () => {
      trpcContext.admin.company.getPage.invalidate();
    },
  });

  const data = useMemo(() => {
    return companiesData?.companies.map((item, index) => {
      return [
        (page - 1) * DEFAULT_TABLE_MAX_ROWS + index + 1,
        <Text style={styles.tableRowTextBold} numberOfLines={1}>{item.name}</Text>,
        item.email,
        item.website,
        <Text style={styles.tableRowText} numberOfLines={2}>{item.address.text}</Text>,
        formatDate(parseISO(item.createdAt), 'MM/dd/yy'),
        <LiveAndOfflinePill isLive={item.status === CompanyStatus.live} />,
        <MB_Button
          style={styles.tableButton}
          leftElement={<FontAwesome name="pencil" size={16} color={COLORS.accentColor} />}
          onPress={() => navigation.navigate('CreateOrEditCompany', { companyId: item.id })}
        />,
        <MB_Button
          leftElement={<Feather name="trash-2" size={21} color={COLORS.red} />}
          style={styles.tableButton}
          onPress={() => mbShowPopUp({
            title: 'Delete Company',
            message: `Are you sure you want to delete "${item.name}"`,
            buttonText: 'Delete Company',
            buttonAction: () => new Promise((resolve) => {
              deleteCompany.mutate({ id: item.id }, {
                onSuccess: () => {
                  mbHidePopUp();
                  mbShowToast({
                    text1: 'Success',
                    text2: 'Company deleted successfully',
                  });
                  resolve();
                },
                onError: () => {
                  mbHidePopUp();
                  mbShowToast({
                    type: 'error',
                    text1: 'Error',
                    text2: 'Something went wrong. Please try again.',
                  });
                  resolve();
                },
              });
            }),
            buttonStyle: {
              container: {},
            },
            secondaryButtonText: 'Cancel',
            secondaryButtonStyle: {
              text: {},
              container: { backgroundColor: COLORS.white },
            },
          })}
        />,
      ];
    });
  }, [companiesData?.companies, deleteCompany, navigation, page]);

  return (
    <ComponentWrapper innerContainerStyle={styles.container} hideHeaderLogo>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={styles.title}>Companies</Text>
        <AddButton buttonText="Add Company" onAddPress={() => navigation.navigate('CreateOrEditCompany')} />
      </View>

      {companiesData?.companies && companiesData?.companies.length === 0 ?
        <EmptyTableView
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          title="No companies yet"
          subtitle="Start adding companies to populate the directory."
          buttonText="Add Company"
          onAddPress={() => navigation.navigate('CreateOrEditCompany')}
        />
        :
        <>
          <MB_Table
            header={header}
            headerStyle={styles.tableHeader}
            data={data}
            style={styles.tableStyle}
            rowStyle={styles.rowStyle}
            keyExtractor={keyExtractor}
            rowTextStyle={styles.tableRowText}
            loading={companiesData ? (isFetching && isPreviousData) : isFetching}
          />

          <MB_PageIndicator
            current={page}
            pages={Math.ceil((companiesData?.totalItems ?? 0) / DEFAULT_TABLE_MAX_ROWS)}
            style={styles.pageIndicatorStyle}
            onChange={setPage}
          />
        </>
      }
    </ComponentWrapper>
  );
};

export { Companies };

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 44,
    backgroundColor: COLORS.grayBackground,
    paddingTop: 32,
  },
  title: mbTextStyles([
    textStyles.largerText, {
      fontSize: 21,
      color: COLORS.primaryColor,
      alignSelf: 'center',
    },
  ]),
  noCompaniesTitle: mbTextStyles([
    textStyles.largeText, {
      fontWeight: '600',
      color: COLORS.primaryColor,
      marginTop: 20,
    },
  ]),
  noCompaniesSubtitle: mbTextStyles([
    textStyles.smallText, {
      fontSize: 13,
      fontWeight: '400',
      color: COLORS.bodyText2,
      marginTop: 6,
    },
  ]),
  noCompaniesButton: {
    marginTop: 20,
  },
  rowStyle: {
    borderColor: COLORS.strokes,
  },
  tableHeader: {
    height: 66,
    backgroundColor: COLORS.lightBlue,
    borderBottomWidth: 0,
  },
  tableStyle: {
    backgroundColor: COLORS.white,
    marginTop: 44,
  },
  tableRowText: mbTextStyles([
    textStyles.smallerText, {
      color: COLORS.bodyText2,
      textAlign: 'left',
    },
  ]),
  tableRowTextBold: mbTextStyles([
    textStyles.smallerText, {
      fontWeight: '600',
      fontSize: 13,
      color: COLORS.bodyText2,
      textAlign: 'left',
    },
  ]),
  pageIndicatorStyle: {
    paddingVertical: 18,
  },
  tableButton: {
    height: 30,
    backgroundColor: 'transaprent',
  },
});
