import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getColors } from '../../constants/colors';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { trpc } from '../../apiCalls/trpcClient';
import { textStyles } from '../../constants/textStyles';
import { mbTagSearch } from '@mightybyte/rnw.components.tags';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { TagDataForClient, TagType } from '@id/business';

const COLORS = getColors();

interface AddNewSTagProps {
    type: TagType,
    isVisible: boolean,
    onDismiss: (props: { name: string, createdOrSelected: 'created' | 'selected' } | undefined) => void,
    allTags: TagDataForClient[],
}

const AddNewTag = ({ type, isVisible, onDismiss, allTags }: AddNewSTagProps) => {
    const [tagName, setTagName] = useState('');
    const [error, setError] = useState(' ');
    const [alreadyExists, setAlreadyExists] = useState(false);
    const [existingTagsFound, setExistingTagsFound] = useState<string[]>([]);

    const trpcContext = trpc.useContext();

    const { mutate: addTag, isLoading } = trpc.admin.tag.create.useMutation({
        onSuccess: () => {
            trpcContext.tag.getAll.invalidate({ type });
            onDismiss({ name: tagName, createdOrSelected: 'created' });
        },
        onError: () => {
            // TODO: Add error for existing name
            setError('Something went wrong');
        },
    });

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={() => onDismiss(undefined)}
            title="Add New Sports Tag"
        >
            <MB_TextInput
                title="Tag Name"
                showRequiredAsteriks
                placeholder="Tag Name"
                value={tagName}
                onChangeText={(name) => {
                    setAlreadyExists(allTags.map(tag => tag.name).includes(name));
                    setError(' ');
                    setTagName(name);

                    const existingTags = mbTagSearch(allTags.map(t => ({ title: t.name })), name);
                    setExistingTagsFound(existingTags.map(e => e.item.title).slice(0, 3));
                }}
            />

            <View style={styles.existingTagsContainer}>
                {existingTagsFound.length > 0 &&
                    <>
                        <Text style={styles.alreadyExistingTagsTitle}>Are you sure you want to use that tag?</Text>
                        <Text style={styles.alreadyExistingTagssubtitle}>Below are some similar tags that already exist</Text>
                        <View style={styles.existingTagsInnerContainer}>
                            {existingTagsFound.map(existing => {
                                return (
                                    <MB_Button
                                        title={existing}
                                        onPress={() => onDismiss({ name: existing, createdOrSelected: 'selected' })}
                                        style={styles.tagButtonContainer}
                                        textStyle={styles.tagButtonText}
                                    />
                                );
                            })}
                        </View>
                    </>
                }
            </View>

            <MB_Button
                title="Add Tag"
                style={{ marginTop: 16, marginBottom: 16 }}
                onPress={() => addTag({ type, name: tagName })}
                disabled={tagName === '' || isLoading || alreadyExists}
                loading={isLoading}
            />
            <Text style={textStyles.popUpErrorMessageText}>{alreadyExists ? 'Tag already exists' : error}</Text>
        </MB_Modal>
    );
};


export { AddNewTag };

const styles = StyleSheet.create({
    existingTagsContainer: {
        marginTop: 12,
        minHeight: 84,
        alignSelf: 'stretch',
    },
    existingTagsInnerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 8,
        alignSelf: 'center',
    },
    alreadyExistingTagsTitle: mbTextStyles([
        textStyles.normalText, {
            fontWeight: '600',
            color: COLORS.primaryColor,
        },
    ]),
    alreadyExistingTagssubtitle: mbTextStyles([
        textStyles.smallerText, {
            fontSize: 13,
            color: COLORS.bodyText,
            marginTop: 4,
        },
    ]),
    tagButtonContainer: {
        backgroundColor: COLORS.lightBlue,
        borderRadius: 7,
        marginHorizontal: 8,
    },
    tagButtonText: mbTextStyles([
        textStyles.smallerText, {
            fontWeight: '500',
            color: COLORS.accentColor,
        },
    ]),
});
