import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { getColors } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { AddButton } from './AddButton';

const COLORS = getColors();

const EmptyTableView = ({ style, title, subtitle, buttonText, onAddPress }: { style?: StyleProp<ViewStyle>, title: string, subtitle: string, buttonText: string, onAddPress: () => void }) => {

    return (
        <View style={style}>
            <View style={{ backgroundColor: '#F1F2F7', borderRadius: 300, width: 129, height: 129 }} />
            <Text style={styles.noCompaniesTitle}>{title}</Text>
            <Text style={styles.noCompaniesSubtitle}>{subtitle}</Text>
            <AddButton buttonText={buttonText} onAddPress={onAddPress}  style={{marginTop: 20}}/>
        </View>
    );
};

export { EmptyTableView };

const styles = StyleSheet.create({
    noCompaniesTitle: mbTextStyles([
        textStyles.largeText, {
            fontWeight: '600',
            color: COLORS.primaryColor,
            marginTop: 20,
        },
    ]),
    noCompaniesSubtitle: mbTextStyles([
        textStyles.smallText, {
            fontSize: 13,
            fontWeight: '400',
            color: COLORS.bodyText2,
            marginTop: 6,
        },
    ]),
});
