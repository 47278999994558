import React, { useCallback, useEffect, useState } from 'react';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import { SearchIcon } from '../../../resources/svgComponents/SearchIcon';
import { FilterButton, SearchFilter } from './FilterButton';
import DynamicStyleSheet, { useDynamicStyles } from '../../../utils/DynamicStyleSheet';
import { getColors } from '../../../constants/colors';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { useNavigation } from '@react-navigation/core';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import { textStyles } from '../../../constants/textStyles';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { trpc } from '../../../apiCalls/trpcClient';
import { TagType } from '@id/business';
import { MB_Tag, mbTagSearch } from '@mightybyte/rnw.components.tags';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';

const colors = getColors();

interface SearchProps {
    style?: StyleProp<ViewStyle>,
    filter?: SearchFilter,
    value?: string
}

const Search = ({ filter, value, style }: SearchProps) => {

    const [searchFilter, setSearchFilter] = useState<SearchFilter>(filter ?? SearchFilter.Equipment);
    const [searchValue, setSearchValue] = useState(value ?? '');
    const [isSuggestionsVisible, showSuggestions, hideSuggestions] = MB_utilHooks.useBool(false);

    const { data } = trpc.tag.getAll.useQuery({ type: searchFilter === SearchFilter.Sport ? TagType.sports : TagType.product });
    const styles = useDynamicStyles(dynamicStyles, { minWidth: 375, maxWidth: 1440 });
    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const suggestions = searchFilter === SearchFilter.Supplier ? [] : mbTagSearch(data?.tags.map(tag => ({ title: tag.name })) ?? [], searchValue);

    useEffect(() => {
        setSearchValue(value ?? '');
        setSearchFilter(filter ?? SearchFilter.Equipment);
    }, [filter, value]);

    const onChangeText = useCallback((text: string) => setSearchValue(text), []);
    const onBlur = useCallback(() => setTimeout(hideSuggestions, 120), [hideSuggestions]);

    const onSearch = useCallback(() => {
        navigation.navigate('SearchPage', { filter: searchFilter, query: searchValue });
    }, [navigation, searchFilter, searchValue]);

    const onSuggestion = useCallback((query: string) => {
        navigation.navigate('SearchPage', { filter: searchFilter, query });
    }, [navigation, searchFilter]);

    const onFilter = useCallback(() => setTimeout(hideSuggestions, 80), [hideSuggestions]);

    const renderRow = useCallback(({ item }: { item: MB_Tag }) => (
        <MB_OnHoverWrapper
            key={item.title}
            onHoverWrapperStyleModifier={styles.hover}
            wrapperStyle={styles.option}
            onPress={() => onSuggestion(item.title)}
        >
            <Text style={styles.optionText}>{item.title}</Text>
        </MB_OnHoverWrapper>
    ), [onSuggestion, styles.hover, styles.option, styles.optionText]);

    return (
        <View style={[styles.searchContainer, style]}>
            <FilterButton
                filterType={searchFilter}
                onFilterChange={setSearchFilter}
                onPress={onFilter}
            />
            <View style={styles.pipe} />
            <View style={styles.search}>
                {(isSuggestionsVisible && suggestions.length > 0) &&
                    <View style={[styles.filterModal, { height: Math.min(36 * suggestions.length, 36 * 5) }]}>
                        {suggestions.map(renderRow)}
                    </View>
                }
                <MB_TextInput
                    value={searchValue}
                    onChangeText={onChangeText}
                    style={styles.searchInput}
                    leftIcon={<SearchIcon containerStyle={styles.searchIcon} />}
                    placeholder="Search for suppliers by equipments"
                    placeholderTextColor={colors.bodyText}
                    textInputStyle={styles.searchInput}
                    onFocus={showSuggestions}
                    onBlur={onBlur}
                />
            </View>
            <MB_Button
                title="Search"
                style={styles.searchButton}
                onPress={onSearch}
            />
        </View>
    );
};

export { Search };

const dynamicStyles = DynamicStyleSheet.create({
    searchContainer: {
        flexDirection: 'row',
        alignSelf: 'center',
        backgroundColor: '#FFF',
        borderRadius: 7,
        alignItems: 'center',
        marginTop: [29, 45],
        width: [338, 952],
        zIndex: 1,
    },
    pipe: {
        height: 25,
        width: 1,
        backgroundColor: colors.strokes,
        marginLeft: 6,
    },
    search: {
        zIndex: 1,
    },
    searchInput: {
        height: 58,
        borderRadius: 0,
        borderWidth: 0,
        width: [226, 404],
    },
    searchButton: {
        backgroundColor: colors.secondaryColor,
        paddingVertical: 15,
        paddingHorizontal: 30,
        marginLeft: 'auto',
        marginRight: 6,
        position: ({ width }) => width < 730 ? 'absolute' : undefined,
        top: ({ width }) => width < 730 ? 58 + 10 : undefined,
        width: ({ width }) => width < 730 ? '100%' : undefined,
    },
    searchIcon: {
        marginRight: 10,
    },
    filterModal: {
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: 0,
            radius: 10,
            color: '#000000',
            opacity: 0.05,
            elevation: 10,
        }),
        top: 58,
        position: 'absolute',
        width: [226, 680],
        height: 108,
        elevation: 5,
        backgroundColor: colors.white,
        marginTop: 5,
        borderRadius: 7,
        overflow: 'hidden',
    },
    hover: {
        backgroundColor: colors.lightBlue,
    },
    option: {
        paddingLeft: 15,
        justifyContent: 'center',
        height: 36,
    },
    optionText: mbTextStyles([
        textStyles.smallText, {
            fontSize: 13,
            textAlign: 'left',
            fontWeight: '400',
            color: colors.primaryColor,
        },
    ]),
});

