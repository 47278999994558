import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { getColors } from '../../../../constants/colors';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { ProductManagementModalContent } from './ProductManagementModalContent';
import { CompanyDataForClient, ProductDataForClient } from '@id/business';
import { trpc } from '../../../../apiCalls/trpcClient';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { AddButton } from '../../../helperComponents/AddButton';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Feather from 'react-native-vector-icons/Feather';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { mbGetSmallestImage } from '../../../../utils/imageUtils';

const COLORS = getColors();

const CreateCompanyProductsAndServices = ({ companyToEdit }: { companyToEdit: CompanyDataForClient }) => {
    const [addModalData, setAddModalData] = useState<{ productToEdit?: ProductDataForClient, isVisible: boolean }>({ isVisible: false });

    const { data: products } = trpc.admin.product.getAllByCompanyId.useQuery({ companyId: companyToEdit.id });

    const trpcContext = trpc.useContext();
    const deleteProduct = trpc.admin.product.delete.useMutation({
        onMutate: (data) => {
            trpcContext.admin.product.getAllByCompanyId.cancel({ companyId: companyToEdit.id });
            const previousCompanies = trpcContext.admin.product.getAllByCompanyId.getData({ companyId: companyToEdit.id });

            trpcContext.admin.product.getAllByCompanyId.setData({ companyId: companyToEdit.id }, old => old?.filter(prod => prod.id !== data.id));

            return { previousCompanies };
        },
        onSuccess: () => {
            mbShowToast({ text1: 'Success' }); // TODO: Hovo: Clean up
        },
        onError: (_error, _prodId, context) => {
            mbShowPopUp({ title: 'Error', message: 'Failed to delete the product. Please try again later' }); // TODO: Hovo: Clean up
            trpcContext.admin.product.getAllByCompanyId.setData({ companyId: companyToEdit.id }, context?.previousCompanies);
        },
        onSettled: () => {
            trpcContext.admin.product.getAllByCompanyId.invalidate({ companyId: companyToEdit.id });
        },
    });

    const onCreateOrEdit = useCallback(() => {
        trpcContext.admin.product.getAllByCompanyId.invalidate({ companyId: companyToEdit.id });
        mbShowToast({ text1: 'Success' }); // TODO: Hovo: Clean up;
        setAddModalData({ isVisible: false });
    }, [companyToEdit.id, trpcContext.admin.product.getAllByCompanyId]);

    function onDeletePressed(productId: string) {
        mbShowPopUp({ // TODO: Hovo: Fix color scheme and style
            title: 'Delete Product',
            message: 'Are you sure you want to delete this product?',
            buttonText: 'Delete',
            buttonAction() {
                deleteProduct.mutate({ id: productId });
                mbHidePopUp();
            },
            secondaryButtonText: 'Cancel',
        });
    }

    if (!products) {
        return <LoadingPage />;
    }

    return (
        <View style={{ backgroundColor: COLORS.white, borderRadius: 10, paddingHorizontal: 26, paddingVertical: 26, minHeight: 600 }}>
            <AddButton buttonText="Add New Product" onAddPress={() => setAddModalData({ isVisible: true })} style={{ alignSelf: 'flex-end', marginBottom: 34 }} />
            <MB_Modal
                isVisible={addModalData.isVisible}
                onDismiss={() => setAddModalData({ isVisible: false })}
                title="Add New Product"
                childrenWrapperStyle={styles.modalWrapper}
            >
                <ProductManagementModalContent companyId={companyToEdit.id} productToEdit={addModalData.productToEdit} onCreateOrEdit={onCreateOrEdit} />
            </MB_Modal>
            {products.length === 0 ?
                <EmptyTableView
                    style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                    title="No products yet"
                    subtitle="Add products being sold by this company."
                    buttonText="Add New Product"
                    onAddPress={() => setAddModalData({ isVisible: true })}
                />
                :
                <View style={{ flexWrap: 'wrap', flexDirection: 'row', alignItems: 'flex-start' }}>
                    {products.map(product => {
                        const productImage = mbGetSmallestImage(product.imageUrl);
                        return (
                            <View style={{ width: 172, height: 165, margin: 8 }}>
                                {productImage ?
                                    <MB_Image source={productImage} style={styles.imageContainer} />
                                    :
                                    <View style={[styles.imageContainer, { backgroundColor: 'orange' }]} />
                                }
                                <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                    <Text style={styles.nameText} numberOfLines={1}>{product.name}</Text>
                                    <TouchableOpacity style={styles.editTouchable} onPress={() => setAddModalData({ isVisible: true, productToEdit: product })}>
                                        <FontAwesome name="pencil" size={16} color={COLORS.accentColor} />
                                    </TouchableOpacity>
                                </View>
                                <TouchableOpacity style={styles.deleteIconContainer} onPress={() => onDeletePressed(product.id)}>
                                    <Feather name="trash-2" color={COLORS.red} size={18} />
                                </TouchableOpacity>
                            </View>
                        );
                    })}
                </View>
            }
        </View>
    );
};


export { CreateCompanyProductsAndServices };

const styles = StyleSheet.create({
    modalWrapper: {
        width: '80%',
        maxWidth: 800,
        alignItems: 'stretch',
    },
    nameText: mbTextStyles([
        textStyles.smallText, {
            fontSize: 13,
            fontWeight: '600',
            textAlign: 'left',
            color: COLORS.accentColor,
            marginTop: 12,
        },
    ]),
    editTouchable: {
        height: 44,
        width: 44,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    imageContainer: {
        borderRadius: 5,
        width: '100%',
        flex: 1,
    },
    deleteIconContainer: {
        width: 31,
        height: 31,
        borderRadius: 300,
        position: 'absolute',
        right: -9,
        top: -12,
        backgroundColor: '#FFF0F0',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
