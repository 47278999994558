import React, { useCallback, useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/core';
import { View, StyleSheet, Text, StyleProp, ViewStyle, TouchableOpacity } from 'react-native';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { utils } from '../../../utils/utils';
import { useSignedInContext } from '../../../context/SignedInContext';
import { getColors } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { mbTextStyles, mbShadow } from '@mightybyte/rnw.utils.style-utils';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import { MB_AnimatedChevron, MB_AnimatedChevronRef } from '@mightybyte/rnw.components.animated-chevron';
import { utilHooks } from '../../../hooks/utilHooks';
import { ModalDropdown } from '@mightybyte/rnw.components.modal-dropdown';
import { ProfileIcon } from '../ProfileIcon';
import { LogoText } from '../LogoText';
import { MB_Button } from '@mightybyte/rnw.components.button';
import DynamicStyleSheet, { useBreakpoints, useDynamicStyles } from '../../../utils/DynamicStyleSheet';
import Menu from './Menu';

const COLORS = getColors();


const Header = React.memo(({ containerStyle, hideLogo, altLogoColors }: { containerStyle?: StyleProp<ViewStyle>, hideLogo: boolean, altLogoColors?: boolean }) => {
    const [isMenuDropdownVisible, setIsMenuDropdownVisible] = useState(false);

    const { isSignedIn, signOut, currentUserData } = useSignedInContext();

    utilHooks.useWidthChange(isMenuDropdownVisible, useCallback(() => modalDropdownRef.current?.hide(), []));

    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const modalDropdownRef = useRef<ModalDropdown>(null);

    let menuItems: string[] = ['Logout'];

    const foundOutSourceChevronRef = useRef<MB_AnimatedChevronRef>(null);

    const styles = useDynamicStyles(dynamicSyles, { minWidth: 375, maxWidth: 1440 });
    const { isMediumAndDown } = useBreakpoints();

    const onOptionSelected = (index: number) => {
        switch (menuItems[index]) {
            case 'Logout':
                utils.showLogOutPopUp(signOut);
                break;
        }
    };

    const onLoginClick = () => {
        navigation.navigate('Login');
    };

    return (
        <View style={[styles.container, containerStyle]}>
            {hideLogo !== true &&
                <TouchableOpacity onPress={() => navigation.reset({ index: 0, routes: [{ name: 'HomePage' }] })}>
                    <LogoText altColors={altLogoColors} />
                </TouchableOpacity>
            }
            {!isSignedIn &&
                <View style={styles.menuContainer}>
                    <Text style={altLogoColors ? styles.menuBtnAlt : styles.menuBtn}>About Us</Text>
                    <View style={styles.space} />
                    <Text style={altLogoColors ? styles.menuBtnAlt : styles.menuBtn}>Advertise</Text>
                    <View style={styles.space} />
                    <Text style={altLogoColors ? styles.menuBtnAlt : styles.menuBtn}>Contact</Text>
                </View>
            }
            {isSignedIn ?
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto' }}>
                    <ModalDropdown
                        ref={modalDropdownRef}
                        options={menuItems}
                        onDropdownWillHide={() => {
                            setIsMenuDropdownVisible(false);
                            foundOutSourceChevronRef.current?.setToInitialRotation();
                        }}
                        onDropdownWillShow={() => {
                            setIsMenuDropdownVisible(true);
                            foundOutSourceChevronRef.current?.setToFinalRotation();
                        }}
                        dropdownStyle={styles.profileIconDropdown}
                        renderSeparator={() => null}
                        renderRow={(option: any, index: any) => {
                            return (
                                <View>
                                    <MB_OnHoverWrapper
                                        wrapperStyle={{
                                            paddingVertical: 9,
                                            paddingHorizontal: 10,
                                            marginTop: Number(index) === 0 ? 15 : 0,
                                            marginBottom: Number(index) === menuItems.length - 1 ? 15 : 0,
                                            borderRadius: 8,
                                        }}
                                        onHoverWrapperStyleModifier={{
                                            backgroundColor: COLORS.backgroundPrimary,
                                        }}
                                        onPress={() => {
                                            onOptionSelected(Number(index));
                                            modalDropdownRef.current?.hide();
                                        }}
                                    >
                                        <Text style={[textStyles.normalText, { textAlign: 'left', color: COLORS.primaryColor }]}>{option}</Text>
                                    </MB_OnHoverWrapper>
                                </View>
                            );
                        }}
                    >
                        <View style={styles.profileIconContainer}>
                            <ProfileIcon image={undefined} firstName={currentUserData?.firstName} lastName={currentUserData?.lastName} fontStyle={{ fontSize: 16 }} />
                            <Text style={[textStyles.normalText, { marginStart: 4, fontSize: 18 }]}>{currentUserData?.firstName}</Text>
                            <MB_AnimatedChevron ref={foundOutSourceChevronRef} chevronSize={16} chevronColor={COLORS.primaryColor} />
                        </View>
                    </ModalDropdown>
                </View>
                :
                (isMediumAndDown ?
                    <Menu />
                    :
                    <MB_Button
                        title="Login"
                        style={styles.loginButton}
                        textStyle={styles.loginButtonText}
                        onPress={onLoginClick}
                    />
                )
            }
        </View>

    );
});

export { Header };

const dynamicSyles = DynamicStyleSheet.create({
    container: {
        ...mbShadow({ elevation: 3, color: '#000000', opacity: 0.1, radius: 2, offsetWidth: 1, offsetHeight: 1 }),
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 75,
        paddingHorizontal: [20, 60],
        backgroundColor: COLORS.white,
        zIndex: 3,
    },
    profileIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginStart: 16,
    },
    profileIconDropdown: StyleSheet.flatten([
        mbShadow({ elevation: 3, color: COLORS.backgroundPrimary, opacity: 0.45, radius: 15, offsetWidth: 2, offsetHeight: 2 }), {
            width: 277,
            height: 'auto',
            elevation: 5,
            backgroundColor: COLORS.backgroundPrimary,
            borderRadius: 10,
            borderWidth: 0,
            overflow: 'hidden',
            paddingHorizontal: 16,
        },
    ]),
    loginButton: {
        backgroundColor: COLORS.secondaryColor,
        width: 97,
        height: 40,
        borderRadius: 6,
    },
    loginButtonText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.primaryColor,
        },
    ]),
    menuContainer: {
        display: {
            xs: 'none',
            l: 'flex',
        },
        paddingVertical: 4,
        minWidth: 20,
        flex: 1,
        flexDirection: 'row',
        marginLeft: 89,
    },
    menuBtn: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.white,
            fontSize: 15,
        },
    ]),
    menuBtnAlt: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.primaryColor,
            fontSize: 15,
        },
    ]),
    space: {
        width: 43,
    },
});
