import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const EmptyProfileIconSvg = React.memo(({ containerStyle, preserveAspectRatio = true }: { containerStyle?: StyleProp<ViewStyle>, preserveAspectRatio?: boolean }) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={'100%'}
                height={'100%'}
                viewBox="0 0 164 164"
                preserveAspectRatio={preserveAspectRatio ? undefined : 'none'}
            >
                <Path fill="#CDD1D9" d="M0 0h164v164H0z" />
                <Path
                    d="M15.118 159.813c.34-2.539.602-5.104 1.046-7.616 3.429-18.452 13.06-32.874 28.344-43.735 10.574 10.024 23.032 15.311 37.583 15.285 14.5 0 26.932-5.235 37.609-15.337 2.119 1.675 4.344 3.271 6.386 5.077 12.876 11.437 20.466 25.702 22.534 42.845.131 1.151.314 2.329.471 3.48V164H15.09c.026-1.387.026-2.8.026-4.188l.002.001Z"
                    fill="#6E7A91"
                />
                <Path
                    d="M85.26 30c2.643.523 5.339.864 7.904 1.596 15.703 4.371 27.324 18.269 28.947 34.522 2.25 22.535-14.291 42.321-36.93 44.178-20.388 1.701-39.152-12.825-42.607-33.003-3.717-21.566 9.762-41.665 31.145-46.402 1.728-.393 3.507-.602 5.26-.89h6.282L85.26 30Z"
                    fill="#6E7A91"
                />
            </Svg>
        </View>
    );
});

export { EmptyProfileIconSvg };
