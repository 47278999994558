import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { getColors } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { ILoginInput } from './LoginUtils';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

export enum LOGIN_UI_BUTTON_PRESSED_TYPES {
    SIGN_IN,
    NAVIGATE_HOME,
    NAVIGATE_FORGOT_PASSWORD,
    NAVIGATE_SIGN_UP,
}

const LoginInput = ({ containerStyle, onButtonPressed, email, setEmail, password, setPassword, errorMessage, setErrorMessage }: ILoginInput) => {

    return (
        <View style={[styles.container, containerStyle]}>
            <Text style={styles.title}>Sign In</Text>

            <MB_TextInput
                style={styles.textInputStyle}
                value={email}
                onChangeText={(newText: string) => {
                    setEmail(newText);
                    setErrorMessage('');
                }}
                title="Email address *"
                placeholder="you@example.com"
                textContentType="emailAddress"
            />

            <MB_TextInput
                style={styles.textInputStyle}
                value={password}
                onChangeText={(newText: string) => {
                    setPassword(newText);
                    setErrorMessage('');
                }}
                title="Password *"
                placeholder="Enter password"
                secureTextEntry
                textContentType="password"
                wrapInFormElement
            />

            {isMobileApp &&
                <MB_Button
                    title="Forgot Password?"
                    onPress={() => onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_FORGOT_PASSWORD)}
                    textStyle={styles.forgotPasswordText}
                    style={styles.forgotPasswordButton}
                />
            }

            {errorMessage !== '' &&
                <Text style={[textStyles.popUpErrorMessageText, { marginTop: 8 }]}>{errorMessage}</Text>
            }

            <MB_Button
                title="Sign In"
                onPress={() => onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.SIGN_IN)}
                disabled={!email || !password}
                style={styles.signInButtonStyle}
            />
        </View>
    );
};

export default LoginInput;

const COLORS = getColors();

const styles = StyleSheet.create({
    container: {
        alignItems: 'stretch',
        backgroundColor: COLORS.backgroundPrimary,
        paddingVertical: 31,
        paddingHorizontal: 28,
        borderRadius: 7,
        ...mbPlatformStyle({
            web: {
                minWidth: 442,
            },
        }),
    },
    title: mbTextStyles([
        textStyles.largerText, {
            color: COLORS.textPrimary,
            fontSize: 21,
        },
    ]),
    textInputStyle: {
        width: '100%',
        marginTop: 21,
    },
    forgotPasswordButton: {
        backgroundColor: 'transparent',
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginBottom: 24,
        height: isMobile ? 18 : undefined,
        alignSelf: 'flex-end',
    },
    forgotPasswordText: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.textPrimary,
        },
    ]),
    signInButtonStyle: {
        marginTop: isMobileApp ? 8 : 46,
        width: 138,
        alignSelf: 'center',
    },
    signInButtonText: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.buttonPrimaryText,
        },
    ])

    ,
});

