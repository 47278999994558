import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Companies } from '../../components/screens/admin/Companies';
import { CreateOrEditCompany } from '../../components/screens/admin/createCompany/CreateOrEditCompany';

export type CompaniesNavigatorParamList = {
    Companies: undefined,
    CreateOrEditCompany: {companyId?: string} | undefined
}

const Stack = createNativeStackNavigator<CompaniesNavigatorParamList>();

const CompaniesNavigator = () => {

    return (
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                }}
            >
                <Stack.Screen name="Companies" component={Companies} />
                <Stack.Screen name="CreateOrEditCompany" component={CreateOrEditCompany} />
            </Stack.Navigator>
    );
};

export { CompaniesNavigator };
