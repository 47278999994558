import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

const CheckMark = React.memo(() => {
    return (
        <Svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <Circle cx={12} cy={12} r={12} fill="#CCF04E" />
            <Path
                d="M7.625 12.624l2.917 2.917 5.833-6.25"
                stroke="#1F2136"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
});

export { CheckMark };
