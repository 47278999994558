import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const PeopleIcon = React.memo(({ color, size = 24 }: { color?: string, size?: number }) => {
  return (
    <Svg
      width={size}
      height={size * (16 / 24)}
      viewBox="0 0 24 16"
      fill="none"
    >
      <Path
        d="M24 10.6c0 .6-1.2 1-2.6 1.2-.9-1.7-2.7-3-4.8-3.9.2-.3.4-.5.6-.8h.8c3.1-.1 6 1.8 6 3.5zM6.8 7H6c-3.1 0-6 1.9-6 3.6 0 .6 1.2 1 2.6 1.2.9-1.7 2.7-3 4.8-3.9L6.8 7zM12 8c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0 1c-4.1 0-8 2.6-8 5 0 2 8 2 8 2s8 0 8-2c0-2.4-3.9-5-8-5zm5.7-3h.3c1.7 0 3-1.3 3-3s-1.3-3-3-3c-.5 0-.9.1-1.3.3.8 1 1.3 2.3 1.3 3.7 0 .7-.1 1.4-.3 2zM6 6h.3C6.1 5.4 6 4.7 6 4c0-1.4.5-2.7 1.3-3.7C6.9.1 6.5 0 6 0 4.3 0 3 1.3 3 3s1.3 3 3 3z"
        fill={color ?? '#FF5F15'}
      />
    </Svg>
  );
});

export { PeopleIcon };
