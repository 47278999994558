import React from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { Text } from 'react-native';

const SuppliersAndAds = () => {

  return (
    <ComponentWrapper>
      <Text style={{ fontSize: 32, color: 'red' }}>Coming Soon</Text>
    </ComponentWrapper>
  );
};

export { SuppliersAndAds };
