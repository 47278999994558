import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { MB_Button } from '@mightybyte/rnw.components.button';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { getColors } from '../../constants/colors';

const COLORS = getColors();

const AddButton = ({ buttonText, onAddPress, style }: { buttonText: string, onAddPress: () => void, style?: StyleProp<ViewStyle> }) => {
    return (
        <MB_Button
            title={buttonText}
            leftElement={<AntDesign name="plus" color={COLORS.primaryColor} size={18} />}
            style={style}
            onPress={onAddPress}
        />
    );
};


export { AddButton };

