import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

export enum LOGIN_UI_BUTTON_PRESSED_TYPES {
    SIGN_IN,
    NAVIGATE_HOME,
    NAVIGATE_FORGOT_PASSWORD,
    NAVIGATE_SIGN_UP,
}

export interface ILoginInput {
    containerStyle?: StyleProp<ViewStyle>;
    onButtonPressed: (buttonType: LOGIN_UI_BUTTON_PRESSED_TYPES) => void;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export interface ILoginUI {
    onButtonPressed: (buttonType: LOGIN_UI_BUTTON_PRESSED_TYPES) => void;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}
