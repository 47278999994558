import React from 'react';
import { Linking, Text, View } from 'react-native';
import { Header } from '../../helperComponents/Header/Header';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { Search } from '../HomePage/Search';
import DynamicStyleSheet, { useDynamicStyles } from '../../../utils/DynamicStyleSheet';
import { getColors } from '../../../constants/colors';
import { trpc } from '../../../apiCalls/trpcClient';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { LoadingPage } from '../../helperComponents/LoadingPage';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { textStyles } from '../../../constants/textStyles';
import { imageUtils, mbGetSmallestImage } from '../../../utils/imageUtils';
import { CompanyPageNavProps } from '../../../typesAndInterfaces/componentProps';
import { ProductCard } from './ProductCard';
import { Footer } from '../HomePage/Footer';
import { NavIndicator } from '../../helperComponents/NavIndicator';

const colors = getColors();

const CompanyPage = ({ route }: CompanyPageNavProps) => {

    const styles = useDynamicStyles(dynamicStyles, { minWidth: 375, maxWidth: 1440 });
    const { data: company, isLoading: isCompanyLoading } = trpc.company.getById.useQuery({ id: route.params.id });

    if (!company || isCompanyLoading) {
        return <LoadingPage />;
    }

    return (
        <ComponentWrapper hideWebHeader>
            <Header
                hideLogo={false}
                containerStyle={styles.header}
                altLogoColors
            />
            <View style={styles.cover} />
            <Search style={styles.search} />
            <NavIndicator style={styles.navIndicator} />
            <View style={styles.content}>
                <View style={styles.companyInfo}>
                    <MB_Image style={styles.logo} source={mbGetSmallestImage(company?.logoUrl) ?? imageUtils.images.contactUsCover} disableInteraction />
                    <View style={styles.companyContent}>
                        <Text style={styles.name} numberOfLines={1}>{company.name}</Text>
                        <View style={styles.details}>
                            <View style={styles.detail}>
                                <MaterialIcons name="location-pin" size={18} />
                                <Text style={[styles.detailText, styles.clickable]}>{company.address.city}, {company.address.state}</Text>
                            </View>
                            {company.website !== undefined && company.website !== '' &&
                                <>
                                    <Text style={styles.dot}>•</Text>
                                    <View style={styles.detail}>
                                        <MaterialCommunityIcons name="web" size={18} />
                                        <Text style={[styles.detailText, styles.clickable]} onPress={() => Linking.openURL(company.website as string)}>Visit website</Text>
                                    </View>
                                </>
                            }
                            <Text style={[styles.dot]}>•</Text>
                            <View style={styles.detail}>
                                <MaterialCommunityIcons name="email" size={18} />
                                <Text style={styles.detailText}>{company.email}</Text>
                            </View>
                            <Text style={[styles.dot]}>•</Text>
                            <View style={styles.detail}>
                                <FontAwesome name="phone" size={18} />
                                <Text style={styles.detailText}>{company.phone}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={styles.description}>{company.description}</Text>
                <Text style={styles.productsTitle}>List of supplies</Text>
                <View style={styles.products}>
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                    <ProductCard />
                </View>
            </View>
            <Footer style={styles.footer} />
        </ComponentWrapper>
    );
};

export { CompanyPage };

const dynamicStyles = DynamicStyleSheet.create({
    header: {
        backgroundColor: 'transparent',
    },
    cover: {
        position: 'absolute',
        width: '100%',
        zIndex: -1,
        backgroundColor: colors.lightBlue,
        height: [235, 199],
    },
    search: {
        marginTop: [30, 25],
    },
    navIndicator: {
        marginTop: 82,
        marginLeft: [22, 60],
        display: {
            xs: 'none',
            l: 'flex',
        },
    },
    content: {
        marginTop: [123, 61],
        paddingHorizontal: [22, 60],
    },
    companyInfo: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    companyContent: {
        marginLeft: 15,
        flex: 1,
    },
    name: {
        ...textStyles.largeText,
        fontSize: [17, 24],
        textAlign: 'left',
        color: colors.primaryColor,
    },
    logo: {
        width: [48, 60],
        height: [48, 60],
        borderRadius: 360,
    },
    details: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    dot: {
        ...textStyles.smallerText,
        marginTop: 9,
        marginHorizontal: 8,
        letterSpacing: -0.12,
        color: colors.primaryColor,
    },
    hide: {
        opacity: 0,
    },
    detail: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 9,
    },
    detailText: {
        ...textStyles.smallerText,
        fontSize: [12, 13],
        textAlign: 'left',
        color: colors.primaryColor,
        marginLeft: 6,
        letterSpacing: -0.12,
    },
    clickable: {
        textDecorationLine: 'underline',
    },
    description: {
        ...textStyles.smallText,
        fontSize: [12, 13],
        textAlign: 'left',
        color: colors.bodyText2,
        marginTop: [13, 42],
        lineHeight: 24,
    },
    footer: {
        marginTop: [62, 77],
    },
    productsTitle: {
        ...textStyles.largeText,
        fontSize: [15, 24],
        textAlign: 'left',
        color: colors.primaryColor,
        marginTop: [33, 61],
    },
    products: {
        flexDirection: 'row',
        justifyContent: {
            xs: 'center',
            l: 'space-between',
        },
        flexWrap: 'wrap',
    },
});
