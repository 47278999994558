import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const RightArrow = React.memo(() => {
    return (
        <Svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
        >
            <Path
                d="M14.813 9.794H3.563M10.275 5.276l4.538 4.518-4.538 4.518"
                stroke="#CCF04E"
                strokeWidth={1.2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
});

export { RightArrow };
