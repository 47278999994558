import React, { ComponentProps, FC, ReactNode } from 'react';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';

export const utils = {
    createErrorObject: (message: string, errorCode: SERVER_ERROR_CODES | string): ServerError => {
        return {
            message,
            errorCode,
            status: 'error',
        };
    },
    /**
     *
     * @param version string version 1
     * @param version2 string version 2
     * @returns -1 if version 1 < version 2, 0 if version 1 === version 2, 1 if version 1 > version 2
     */
    compareVersion: (version: string, version2: string) => {
        return version.localeCompare(version2, undefined, { numeric: true, sensitivity: 'base' });
    },

    combineComponents: (components: FC[]): FC => {
        const reducerFunction = (
            AccumulatedComponents: FC<ReactNode>,
            CurrentComponent: FC<ReactNode>,
        ): FC<ReactNode> => {
            return ({ children }: ComponentProps<FC<ReactNode>>): JSX.Element => {
                const Component = AccumulatedComponents as FC<ReactNode>;
                return (
                    <Component>
                        <CurrentComponent>{children}</CurrentComponent>
                    </Component>
                );
            };
        };

        return components.reduce(reducerFunction, ({ children }: ComponentProps<FC<ReactNode>>): JSX.Element => (
            <>{children}</>
        )) as FC;
    },
    showLogOutPopUp: (onSignOut?: () => void, onPopUpClosed?: () => void) => {
        mbShowPopUp({
            title: 'Are you sure you want to log out ?',
            buttonText: 'Yes',
            buttonAction: () => {
                // TODO: This is kind of ugly. We needed to do this because of some issues with iOS pop-ups and also on Android it was trying to perform an action on a component that was unmounted.
                mbHidePopUp();
                setTimeout(() => onPopUpClosed?.(), 0);
                setTimeout(() => onSignOut?.(), 400);
            },
            secondaryButtonText: 'No',
        });
    },
    getRelativeValue: (minValue: number, minRelativeValue: number, maxValue: number, maxRelativeValue: number, value: number) => {
        if (value <= minValue) {
            return minRelativeValue;
        } else if (value >= maxValue) {
            return maxRelativeValue;
        } else {
            if (minRelativeValue < maxRelativeValue) {
                return minRelativeValue + ((value - minValue) * ((maxRelativeValue - minRelativeValue) / (maxValue - minValue)));
            } else {
                return minRelativeValue - ((value - minValue) * ((minRelativeValue - maxRelativeValue) / (maxValue - minValue)));
            }
        }
    },
    enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
        return (Object.values(enm) as unknown as string[]).includes(value)
            ? value as unknown as T
            : undefined;
    },
};

