import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

enum FONT_STYLES {
    JakartaBase = 'Jakarta',
    Jakarta_200 = 'Jakarta_200',
    Jakarta_200_italic = 'Jakarta_200_italic',
    Jakarta_300 = 'Jakarta_300',
    Jakarta_300_italic = 'Jakarta_300_italic',
    Jakarta_400 = 'Jakarta_400',
    Jakarta_400_italic = 'Jakarta_400_italic',
    Jakarta_500 = 'Jakarta_500',
    Jakarta_500_italic = 'Jakarta_500_italic',
    Jakarta_600 = 'Jakarta_600',
    Jakarta_600_italic = 'Jakarta_600_italic',
    Jakarta_700 = 'Jakarta_700',
    Jakarta_700_italic = 'Jakarta_700_italic',
    Jakarta_800 = 'Jakarta_800',
    Jakarta_800_italic = 'Jakarta_800_italic',
}

const textStyles = StyleSheet.create({
    superLargeText: mbTextStyles({
        fontFamily: FONT_STYLES.JakartaBase,
        fontSize: 44,
        fontWeight: '700',
        color: 'white',
        textAlign: 'center',
    }),
    largestText: mbTextStyles({
        fontFamily: FONT_STYLES.JakartaBase,
        fontSize: 32,
        fontWeight: '700',
        color: 'white',
        textAlign: 'center',
    }),
    largerText: mbTextStyles({
        fontFamily: FONT_STYLES.JakartaBase,
        fontSize: 28,
        fontWeight: '600',
        color: 'white',
        textAlign: 'center',
    }),
    largeText: mbTextStyles({
        fontFamily: FONT_STYLES.JakartaBase,
        fontSize: 24,
        fontWeight: '500',
        color: 'white',
        textAlign: 'center',
    }),
    normalText: mbTextStyles({
        fontFamily: FONT_STYLES.JakartaBase,
        fontSize: 16,
        fontWeight: '500',
        color: 'white',
        textAlign: 'center',
    }),
    smallText: mbTextStyles({
        fontFamily: FONT_STYLES.JakartaBase,
        fontSize: 14,
        fontWeight: '500',
        color: 'white',
        textAlign: 'center',
    }),
    smallerText: mbTextStyles({
        fontFamily: FONT_STYLES.JakartaBase,
        fontWeight: 'normal',
        fontSize: 12,
        color: 'white',
        textAlign: 'center',
    }),
    tinyText: mbTextStyles({
        fontFamily: FONT_STYLES.JakartaBase,
        fontWeight: 'normal',
        fontSize: 10,
        color: 'white',
        textAlign: 'center',
    }),
    popUpErrorMessageText: mbTextStyles({
        fontFamily: FONT_STYLES.JakartaBase,
        fontWeight: 'normal',
        fontSize: 12,
        textAlign: 'center',
        color: 'red',
    }),
});

export { textStyles, FONT_STYLES };
