import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const FacebookIcon = React.memo(({ color = '#EEF2F7' }: { color?: string }) => {
    return (
        <Svg
            width={21}
            height={22}
            viewBox="0 0 21 22"
            fill="none"
        >
            <Path
                d="M13.23 5.155h1.645V2.373a22.843 22.843 0 00-2.397-.123c-2.38 0-4.008 1.453-4.008 4.113v2.292H5.784v3.115H8.47v7.98h3.22v-7.98h2.678l.402-3.115h-3.08V6.669c0-.919.245-1.514 1.54-1.514z"
                fill={color}
            />
        </Svg>
    );
});

export { FacebookIcon };
