import React from 'react';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { Text, View } from 'react-native';
import DynamicStyleSheet, { useDynamicStyles } from '../../../utils/DynamicStyleSheet';
import { imageUtils } from '../../../utils/imageUtils';
import { textStyles } from '../../../constants/textStyles';
import { getColors } from '../../../constants/colors';
import { mbShadow } from '@mightybyte/rnw.utils.style-utils';

const colors = getColors();

const ProductCard = () => {

    const styles = useDynamicStyles(dynamicStyles, { minWidth: 375, maxWidth: 1440 });

    return (
        <View style={styles.container}>
            <MB_Image style={styles.logo} source={imageUtils.images.contactUsCover} />
            <View style={styles.info}>
                <Text style={styles.name}>Equipment Name</Text>
                <Text style={styles.desc}>Lorem ipsum dolor sit amet consectetur. Cras natoque neque eleifend quis faucibus feugiat. Morbi hac turpis tortor gravida. Est ullamcorper ut sed in. Nisl at mattis quis convallis sem dictumst habitasse in ut. At consequat vel vitae nibh pretium et sem. Nec sed sit leo nullam ultricies celerisque proin</Text>
            </View>
        </View>
    );
};

export { ProductCard };

const dynamicStyles = DynamicStyleSheet.create({
    container: {
        paddingHorizontal: 17,
        paddingVertical: 18,
        borderBottomColor: colors.secondaryColor,
        borderBottomWidth: 2,
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: 0,
            radius: 9,
            elevation: 9,
            opacity: 0.05,
            color: '#000000',
        }),
        flexDirection: {
            xs: 'column',
            m: 'row',
        },
        width: ({ width, isMediumAndDown }) => isMediumAndDown ? DynamicStyleSheet.getRelativeValue(375, 332, 1440, 622, width) : (width - 200) / 2,
        marginTop: 35,
    },
    logo: {
        width: 142,
        height: 135,
        borderRadius: 5,
    },
    info: {
        marginLeft: {
            xs: 0,
            m: 28,
        },
        flex: 1,
        marginTop: 6,
    },
    name: {
        ...textStyles.normalText,
        fontSize: 16,
        textAlign: 'left',
        color: colors.primaryColor,
        marginTop: {
            xs: 28,
            m: 0,
        },
    },
    desc: {
        ...textStyles.smallerText,
        fontSize: 12,
        textAlign: 'left',
        color: colors.bodyText2,
        marginTop: 7,
        letterSpacing: -0.12,
        lineHeight: 22,
    },
});
