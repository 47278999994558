import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';
import { StyleProp, View, ViewStyle } from 'react-native';

const SearchIcon = React.memo(({ containerStyle }: { containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
            >
                <Circle
                    cx={7.82491}
                    cy={7.82491}
                    r={6.74142}
                    stroke="#CCF04E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    d="M12.514 12.864l2.643 2.636"
                    stroke="#CCF04E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </View>
    );
});

export { SearchIcon };
