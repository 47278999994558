import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { getColors } from '../../../../constants/colors';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { UploadLogo } from '../../../../resources/svgComponents/UploadLogo';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { MB_LocationPicker } from '@mightybyte/rnw.components.location-picker';
import { envs } from '../../../../../env';
import { trpc } from '../../../../apiCalls/trpcClient';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { MB_RESOURCE_PICKER_TYPE, MB_ResourceUploader, MB_ResourceUploaderFunctionProps } from '@mightybyte/rnw.components.resource-uploader';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import isEqual from 'lodash/isEqual';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { resourceUploadApiCalls } from '../../../../apiCalls/resourceUploadApiCalls';
import { Address, CompanyDataForClient, CompanyStatus, ImageFileExtension } from '@id/business';
import { utils } from '../../../../utils/utils';
import { mbGetSmallestImage } from '../../../../utils/imageUtils';

// TODO: Hovo: When deleting last company on page, it displayed no companies instead of switching the page

const COLORS = getColors();

const CreateCompanyBasicDetails = ({ companyToEdit, onCreatOrEdited }: { companyToEdit?: CompanyDataForClient, onCreatOrEdited: (companyId: string) => void }) => {
    const [isResourcePickerVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();

    const [logo, setLogo] = useState<MB_ResourceUploaderFunctionProps | undefined>();

    const getUploadUrl = trpc.admin.company.getLogoUploadUrl.useMutation();

    const [name, setName] = useState('');
    const [address, setAddress] = useState<Address | undefined>();
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');

    useEffect(() => {
        if (companyToEdit) {
            setName(companyToEdit.name);
            setAddress(companyToEdit.address);
            setPhone(companyToEdit.phone);
            setEmail(companyToEdit.email);
            setWebsite(companyToEdit.website ?? '');
        }
    }, [companyToEdit]);

    function onCreatedOrEdited({ id }: { id: string }) {
        mbShowToast({ text1: `Success ${companyToEdit === undefined ? 'Creating' : 'Editing'} company` });
        // TODO: Fix the toast
        onCreatOrEdited(id); // TOOD: Hovo: Fix this
    }

    const companyCreator = trpc.admin.company.create.useMutation({
        onSuccess: onCreatedOrEdited,
        onError: () => {
            // TODO: Hovo: Address this
        },
    });

    const companyEditor = trpc.admin.company.edit.useMutation({
        onSuccess: onCreatedOrEdited,
        onError: () => {
            // TODO: Hovo: Address this
        },
    });

    const isCreateOrEditDisabled = () => {
        if (companyCreator.isLoading || companyEditor.isLoading) {
            return true;
        }

        if (!name || !address || !phone || !email) {
            return true;
        }

        if (companyToEdit) {
            // TODO: Fix the address checker
            if (name === companyToEdit.name && isEqual(address, companyToEdit.address) && phone === companyToEdit.phone &&
                email === companyToEdit.email && website === companyToEdit.website) {
                return true;
            }
        }

        return false;
    };

    // TODO: Hovo: We need to combine this into a util
    function imageUploader({ fileProps, uploadProgressListener }: { fileProps: MB_ResourceUploaderFunctionProps, uploadProgressListener: (percentCompleted: number) => void }): Promise<string> {

        return new Promise<string>((resolve, reject) => {
            if (!fileProps.blob) {
                reject('Something went wrong');
            }

            const extension = utils.enumFromStringValue(ImageFileExtension, fileProps.mimeType.split('/')[1]);

            if (!extension) {
                reject('Error: Invalid image format');
                return;
            }

            getUploadUrl.mutate({ fileExtension: extension }, {
                onSuccess: async (data) => {
                    resourceUploadApiCalls.uploadResource({
                        method: data.requestMethod,
                        url: data.signedUrl,
                        imageData: fileProps.blob!,
                        headers: data.headers,
                        uploadProgress: uploadProgressListener,
                    })
                        .then(() => {
                            resolve(data.fileName);
                        })
                        .catch(() => reject('Something went wrong during upload'));
                },
                onError: (error) => {
                    // TODO: HOVO: Finish
                    console.log('Error data', error);
                },
            });
        });
    }

    const imageVideoUploader = (uploadProgressListener: (percentCompleted: number) => void) => async (fileProps: MB_ResourceUploaderFunctionProps): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!companyToEdit) {
                setLogo(fileProps);
                resolve();
                return;
            }


            imageUploader({ fileProps, uploadProgressListener })
                .then(fileName => {
                    companyEditor.mutate({ id: companyToEdit.id, logoFilename: fileName }, {
                        onSuccess: () => {
                            onCreatedOrEdited({ id: companyToEdit.id });
                            resolve();
                        },
                        onError: () => reject('Something went wrong during upload'),
                    });
                })
                .catch(error => reject(error));
        });
    };

    // TODO: Hovo: we need to enable editing company here as well

    async function createCompany(companyStatus: CompanyStatus) {
        if (name && address && phone && email && address.text && address.country && address.state) {
            let logoName: string | undefined;

            if (logo !== undefined) {
                try {
                    logoName = await imageUploader({ fileProps: logo, uploadProgressListener: () => { } });
                } catch {
                    console.error('ERROR CREATING COMPANY LOGO');
                }
            }

            companyCreator.mutate({
                logoFilename: logoName,
                email,
                name,
                address,
                status: companyStatus,
                phone,
                website,
            });
        }
    }

    function onAddOrEditPressed() {
        if (companyToEdit) {
            companyEditor.mutate({
                id: companyToEdit.id,
                email,
                name,
                address,
                phone,
                website,
            });
        } else {
            mbShowPopUp({
                title: 'Company Status',
                message: 'Would  you like to create this company in Live or offline mode?',
                buttonText: 'Live',
                buttonStyle: {
                    container: { backgroundColor: '#E5FFF8' },
                    text: { color: COLORS.green },
                },
                buttonAction: () => {
                    createCompany(CompanyStatus.live);
                    mbHidePopUp();
                },
                secondaryButtonText: 'Offline',
                secondaryButtonStyle: {
                    container: { backgroundColor: '#FFEEEE' },
                    text: { color: COLORS.red },
                },
                secondaryButtonAction: () => {
                    createCompany(CompanyStatus.offline);
                    mbHidePopUp();
                },
            });
        }
    }

    return (
        <View style={{ backgroundColor: COLORS.white, borderRadius: 10, paddingHorizontal: 26, paddingVertical: 26 }}>
            <MB_ResourceUploader
                isVisible={isResourcePickerVisible}
                onDismiss={() => {
                    hideResourcePicker();
                }}
                uploaderFunction={imageVideoUploader}
                pageTitle={'Select Company Logo'}
                uploadButtonTitle="Upload"
                resourcePickerType={MB_RESOURCE_PICKER_TYPE.image}
            />

            <Pressable style={{ alignItems: 'center', alignSelf: 'center' }} onPress={showResourcePicker}>
                {(!companyToEdit?.logoUrl && logo === undefined) ?
                    <>
                        <UploadLogo />
                    </>
                    :
                    <>
                        <MB_Image source={mbGetSmallestImage(companyToEdit?.logoUrl) ?? logo?.uri ?? ''} style={{ width: 94, height: 94, borderRadius: 300 }} disableInteraction />
                    </>
                }
                <Text style={styles.uploadLogoTitle}>Upload Logo</Text>
                <Text style={styles.uploadLogoSubtitle}>{'(.jpg, .jpeg or .png file\nformat supported)'}</Text>
            </Pressable>
            <MB_TextInput
                title="Company Name"
                placeholder="Company name"
                showRequiredAsteriks
                style={[styles.textInput, { marginTop: 0 }]}
                value={name}
                onChangeText={setName}
            />

            <Text style={styles.addressTitle}>
                Address line<Text style={{ color: 'red' }}>*</Text>
            </Text>
            <MB_LocationPicker
                apiKey={envs.LOCATION_PICKER_API}
                containerStyle={{ zIndex: 1 }}
                style={styles.locationPickerStyle}
                arrowColor={COLORS.primaryColor}
                placeholderStyle={{ color: COLORS.placeholderTextColor }}
                dropdownLabelStyle={{ color: COLORS.primaryColor }}
                dropdownContainerStyle={styles.locationPickerDropdown}
                searchTextInput={styles.locationPickerSearchText}
                labelStyle={styles.locationPickerEntry}
                highlightColor={mbApplyTransparency('#000000', 0.5)}
                itemSeparatorStyle={{ height: 1, backgroundColor: COLORS.strokes }}
                onSelect={addr => {

                    if (!addr.city || !addr.country || !addr.lat || !addr.lng || !addr.postalCode || !addr.state || !addr.text) {
                        mbShowToast({ text1: 'Error', text2: 'Please select a valid address', type: 'error' });
                    } else {
                        setAddress({
                            city: addr.city,
                            country: addr.country,
                            lat: addr.lat,
                            lng: addr.lng,
                            postalCode: addr.postalCode,
                            state: addr.state,
                            text: addr.text,
                        });
                    }
                }}
                value={companyToEdit?.address.text}
            />

            <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ marginEnd: 6, flex: 1 }}>
                    <MB_TextInput
                        title="Phone number"
                        placeholder="Phone number"
                        showRequiredAsteriks
                        style={{ width: '100%' }}
                        value={phone}
                        onChangeText={setPhone}
                    />
                </View>
                <View style={{ marginStart: 6, flex: 1 }}>
                    <MB_TextInput
                        title="Email address"
                        placeholder="Email address"
                        showRequiredAsteriks
                        style={{ width: '100%' }}
                        value={email}
                        onChangeText={setEmail}
                    />
                </View>
            </View>

            <MB_TextInput
                title="Website url"
                placeholder="Website url"
                style={styles.textInput}
                value={website}
                onChangeText={setWebsite}
            />

            <MB_Button
                title={companyToEdit ? 'Save Changes' : 'Add Company'}
                style={{ marginTop: 42, alignSelf: 'flex-end', paddingHorizontal: 32 }}
                onPress={onAddOrEditPressed}
                disabled={isCreateOrEditDisabled()}
                loading={companyCreator.isLoading || companyEditor.isLoading}
            />
        </View>
    );
};


export { CreateCompanyBasicDetails };

const styles = StyleSheet.create({
    container: {
    },
    textInput: {
        width: '100%',
        marginTop: 22,
    },
    uploadLogoTitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.accentColor,
            marginTop: 12,
        },
    ]),
    uploadLogoSubtitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.bodyText2,
            fontSize: 11,
            fontWeight: '300',
        },
    ]),
    addressTitle: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.bodyText,
            marginBottom: 8,
            fontWeight: '600',
            fontSize: 13,
            textAlign: 'left',
            marginTop: 22,
        },
    ]),
    locationPickerStyle: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: COLORS.strokes,
    },
    locationPickerDropdown: {
        backgroundColor: COLORS.white,
        ...mbShadow({ elevation: 3, color: '#000000', opacity: 0.5, radius: 2, offsetWidth: 0, offsetHeight: 0 }),
        marginTop: 6,
        borderRadius: 5,
    },
    locationPickerSearchText: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.primaryColor,
            textAlign: 'left',
        },
    ]),
    locationPickerEntry: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.primaryColor,
            textAlign: 'left',
            fontSize: 11,
            fontWeight: '600',
        },
    ]),
});
