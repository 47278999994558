import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_Tags, MB_Tag, MB_TagsRef } from '@mightybyte/rnw.components.tags';
import { getColors } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { MB_Button } from '@mightybyte/rnw.components.button';

const COLORS = getColors();

interface CustomLocationPickerProps {
    containerStyle?: StyleProp<ViewStyle>
    MB_Ref: React.RefObject<MB_TagsRef>
    title: string
    initialTags: MB_Tag[] | undefined
    allTags: MB_Tag[] | undefined
    onTagsChange: (tags: MB_Tag[]) => void
    onAddNewPressed: () => void
}

const CustomTags = (props: CustomLocationPickerProps) => {
    return (
        <View style={[styles.container, props.containerStyle]}>
            <MB_Tags
                MB_Ref={props.MB_Ref}
                title={props.title}
                initialTags={props.initialTags}
                tags={props.allTags}
                containerStyle={styles.tagsContainer}
                onTagsChange={props.onTagsChange}
                disableAddNewTag
                titleStyle={styles.tagsTitle}
                textInputStyle={styles.tagsTextInputTextStyle}
                tagPickerStyle={styles.tagPickerStyle}
                subContainerStyle={styles.tagsSubContainer}
                itemSeparatorStyle={styles.itemSeparator}
                tagTextStyle={styles.tagText}
                tagHoverStyle={styles.tagHover}
                tagBadgeStyle={styles.tagBadge}
                tagBadgeTextStyle={styles.tagBadgeText}
                emptyTagsTextStyle={styles.emptyTagsText}
                tagBadgeX={{
                    color: '#FFF',
                    size: 10,
                }}
            />
            <MB_Button
                title="Add New Tag"
                onPress={props.onAddNewPressed}
                style={styles.addTagBtn}
            />
        </View>
    );
};


export { CustomTags };

const styles = StyleSheet.create({
    container: {
        padding: 4,
        flexDirection: 'row',
        alignItems: 'flex-end',
        zIndex: 1,
    },
    tagsContainer: {
        zIndex: 1,
        flex: 1,
        backgroundColor: 'transparent',
    },
    tagsSubContainer: {
        borderWidth: 1,
        borderColor: COLORS.secondaryColor,
    },
    tagsTextInputTextStyle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.textInputText,
            textAlign: 'left',
        },
    ]),
    tagsTitle: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.bodyText,
            fontWeight: '600',
            fontSize: 13,
        },
    ]),
    tagPickerStyle: {
        backgroundColor: '#FFF',
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: 0,
            radius: 5,
            color: '#242424',
            opacity: 0.1,
            elevation: 5,
        }),
    },
    itemSeparator: {
        backgroundColor: COLORS.strokes,
        height: 0.3,
    },
    tagHover: {
        backgroundColor: COLORS.strokes,
    },
    tagBadge: {
        backgroundColor: COLORS.strokes,
    },
    tagBadgeText: {
        color: '#FFF',
    },
    tagText: {
        color: COLORS.headerText,
    },
    emptyTagsText: {
        color: COLORS.headerText,
    },
    addTagBtn: {
        height: 60,
        marginStart: 8,
    },
});
