import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';

export enum ColorSchemaTypes {
    dark = 'dark',
    light = 'light',
}

type ColorSchemaType = ColorSchemaTypes;

type ColorSchema = {
    textPrimary: string;
    textSecondary: string;
    textSecondary2: string;
    backgroundPrimary: string;
    buttonPrimaryBg: string;
    buttonPrimaryText: string;
    buttonSecondaryBg: string;
    buttonSecondaryText: string;
    strokes: string;
    placeholderTextColor: string;
    textInputText: string;
    rowActiveBackground: string;
    primaryColor: string;
    secondaryColor: string;
    grayBackground: string;
    bodyText: string;
    bodyText2: string;
    accentColor: string;
    white: string;
    lightBlue: string;
    red: string;
    green: string;
    lightGray: string;
    headerText: string;
    greyText: string;
};

const darkSchema: ColorSchema = {
    // TODO: See if we need this
    textPrimary: '#1F2136',
    textSecondary: '#CCF04E',
    textSecondary2: '#737795',
    backgroundPrimary: '#FFFFFF',
    buttonPrimaryBg: '#9747FF',
    buttonPrimaryText: '#FFFFFF',
    buttonSecondaryBg: '#9747FF',
    buttonSecondaryText: '#FFFFFF',
    strokes: '#C4C4C4',
    placeholderTextColor: mbApplyTransparency('#555555', 0.6),
    textInputText: '#333333',
    rowActiveBackground: mbApplyTransparency('#ffffff', 0.07),
    primaryColor: '#1F2136',
    secondaryColor: '#CCF04E',
    grayBackground: '#F7F8FA',
    bodyText: '#333333',
    bodyText2: '#555555',
    accentColor: '#1D52E8',
    white: '#ffffff',
    lightBlue: '#EBEFFF',
    red: '#DD3636',
    green: '#17B472',
    lightGray: '#DEDEDE',
    headerText: '#000',
    greyText: '#777',
};

const lightSchema: ColorSchema = {
    textPrimary: '#1F2136',
    textSecondary: '#CCF04E',
    textSecondary2: '#737795',
    backgroundPrimary: '#FFFFFF',
    buttonPrimaryBg: '#9747FF',
    buttonPrimaryText: '#FFFFFF',
    buttonSecondaryBg: '#9747FF',
    buttonSecondaryText: '#FFFFFF',
    strokes: '#C4C4C4',
    placeholderTextColor: mbApplyTransparency('#555555', 0.6),
    textInputText: '#333333',
    rowActiveBackground: mbApplyTransparency('#ffffff', 0.07),
    primaryColor: '#1F2136',
    secondaryColor: '#CCF04E',
    grayBackground: '#F7F8FA',
    bodyText: '#333333',
    bodyText2: '#555555',
    accentColor: '#1D52E8',
    white: '#ffffff',
    lightBlue: '#EBEFFF',
    red: '#DD3636',
    green: '#17B472',
    lightGray: '#DEDEDE',
    headerText: '#000',
    greyText: '#777',
};

export const getColors = (mode?: ColorSchemaType | undefined): ColorSchema => {
    if (!mode) {
        return lightSchema;
    }

    switch (mode) {
        case ColorSchemaTypes.dark:
            return darkSchema;
        case ColorSchemaTypes.light:
            return lightSchema;
        default:
            return darkSchema;
    }
};
