import React from 'react';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { View, Text, Linking, StyleProp, ViewStyle } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { imageUtils, mbGetSmallestImage } from '../../../utils/imageUtils';
import DynamicStyleSheet, { useDynamicStyles } from '../../../utils/DynamicStyleSheet';
import { textStyles } from '../../../constants/textStyles';
import { getColors } from '../../../constants/colors';
import { mbShadow } from '@mightybyte/rnw.utils.style-utils';
import { CompanyDataForClient } from '@id/business';
import { useNavigation } from '@react-navigation/core';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';

const colors = getColors();

interface CompanyCardProps {
    company: CompanyDataForClient,
    style?: StyleProp<ViewStyle>
}

const CompanyCard = ({ company, style }: CompanyCardProps) => {

    const styles = useDynamicStyles(dynamicStyles, { minWidth: 375, maxWidth: 1440 });
    const navigation = useNavigation<UniversalScreenNavigationProp>();

    return (
        <View style={[styles.container, style]}>
            <View style={styles.header}>
                <MB_Image style={styles.logo} source={mbGetSmallestImage(company?.logoUrl) ?? imageUtils.images.contactUsCover} disableInteraction />
                <View style={styles.headerContent}>
                    <Text style={styles.name} numberOfLines={1}>{company.name}</Text>
                    <View style={styles.details}>
                        <View style={styles.detail}>
                            <MaterialIcons name="location-pin" size={18} />
                            <Text style={[styles.detailText, styles.clickable]}>{company.address.city}, {company.address.state}</Text>
                        </View>
                        {company.website !== undefined && company.website !== '' &&
                            <>
                                <Text style={styles.dot}>•</Text>
                                <View style={styles.detail}>
                                    <MaterialCommunityIcons name="web" size={18} />
                                    <Text style={[styles.detailText, styles.clickable]} onPress={() => Linking.openURL(company.website as string)}>Visit website</Text>
                                </View>
                            </>
                        }
                        <Text style={[styles.dot]}>•</Text>
                        <View style={styles.detail}>
                            <MaterialCommunityIcons name="email" size={18} />
                            <Text style={styles.detailText}>{company.email}</Text>
                        </View>
                        <Text style={[styles.dot]}>•</Text>
                        <View style={styles.detail}>
                            <FontAwesome name="phone" size={18} />
                            <Text style={styles.detailText}>{company.phone}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <Text style={styles.description} numberOfLines={5}>{company.description}</Text>
            <MB_Button
                title="View supplier"
                style={styles.viewBtn}
                onPress={() => navigation.navigate('CompanyPage', { id: company.id })}
            />
        </View>
    );
};


export { CompanyCard };

const dynamicStyles = DynamicStyleSheet.create({
    container: {
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: 0,
            radius: 9,
            color: '#000000',
            opacity: 0.05,
            elevation: 9,
        }),
        width: [352, 635],
        backgroundColor: '#FFFFFF',
        paddingVertical: 20,
        paddingHorizontal: 21,
        borderBottomWidth: 2,
        borderBottomColor: colors.secondaryColor,
        marginTop: 27,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerContent: {
        marginLeft: 15,
        flex: 1,
    },
    name: {
        ...textStyles.normalText,
        fontSize: 18,
        textAlign: 'left',
        color: colors.primaryColor,
    },
    logo: {
        width: 54,
        height: 54,
        borderRadius: 360,
    },
    details: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    dot: {
        ...textStyles.smallerText,
        marginTop: 9,
        marginHorizontal: 8,
        letterSpacing: -0.12,
        color: colors.primaryColor,
    },
    hide: {
        opacity: 0,
    },
    detail: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 9,
    },
    detailText: {
        ...textStyles.smallerText,
        textAlign: 'left',
        color: colors.primaryColor,
        marginLeft: 6,
        letterSpacing: -0.12,
    },
    clickable: {
        textDecorationLine: 'underline',
    },
    description: {
        ...textStyles.smallText,
        fontSize: 13,
        textAlign: 'left',
        color: colors.bodyText2,
        marginTop: 20,
    },
    viewBtn: {
        width: 124,
        marginTop: 20,
        alignSelf: 'flex-end',
    },
});
