import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { createNavigationContainerRef, NavigationContainer, NavigatorScreenParams } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { ReactNode, useEffect, useState } from 'react';
import { envs } from '../../env';
import { LOADING_PAGE_GIVE_UP_ACTION_TYPES, LoadingPage } from '../components/helperComponents/LoadingPage';
import { ServerIsDownPage } from '../components/helperComponents/ServerIsDownPage';
import { Login } from '../components/screens/Login/Login';
import { NotFound } from '../components/screens/NotFound';
import { SignUp } from '../components/screens/Signup/SignUp';
import { SIGNED_IN_STATUS, useSignedInContext } from '../context/SignedInContext';
import { AdminNavigator, AdminNavigatorParamList } from './adminNavigator/AdminNavigator';
import { trpc } from '../apiCalls/trpcClient';
import { HomePage } from '../components/screens/HomePage/HomePage';
import { AboutUs } from '../components/screens/AboutUs/AboutUs';
import { ContactUs } from '../components/screens/ContactUs/ContactUs';
import { UserRole } from '@id/business';
import { SearchFilter } from '../components/screens/HomePage/FilterButton';
import { SearchPage } from '../components/screens/SearchPage/SearchPage';
import { CompanyPage } from '../components/screens/CompanyPage/CompanyPage';


export type RootStackParamList = {
    Login: undefined
    AboutUs: undefined,
    ContactUs: undefined,
    SignUp: undefined
    InstallApp: undefined
    NotFound: undefined
    LandingPage: undefined
    HomePage: undefined
    SearchPage: { filter: SearchFilter, query: string }
    CompanyPage: { id: string }
    AdminNavigator: NavigatorScreenParams<AdminNavigatorParamList>
};

const Stack = createNativeStackNavigator<RootStackParamList>();

const navRef = createNavigationContainerRef<RootStackParamList>();

export const getRootNavRef = () => {
    return navRef.isReady() ? navRef : undefined;
};

const config: { screens: { [key in keyof RootStackParamList]: string | { path: string, screens: Object } } } = {
    screens: {
        Login: 'login',
        AboutUs: 'aboutUs',
        ContactUs: 'contactUs',
        SignUp: 'signup',
        InstallApp: 'installApp',
        LandingPage: 'landingPage',
        NotFound: '*',
        HomePage: 'homePage',
        SearchPage: 'searchPage',
        CompanyPage: 'companyPage',
        AdminNavigator: {
            path: 'adminNavigator',
            screens: {
                Companies: {
                    path: 'companies',
                    screens: {
                        Companies: 'companies',
                        CreateOrEditCompany: 'CreateOrEditCompany',
                    },
                },
                SuppliersAndAds: 'suppliersAndAds',
                Analytics: 'analytics',
            },
        },
    },
};

const linking = {
    prefixes: [
        envs.WEBSITE_BASE_URL,
    ],
    config,
};

const RootNavigation = ({ onReady }: { onReady: (isReady: boolean) => void }) => {
    const [serverIsDown, setServerIsDown] = useState<boolean | undefined>();
    const { isSignedIn, signedInStatus, setSignedInStatus } = useSignedInContext();

    const {
        data: serverVersion,
        failureCount: serverVersionRequestFailureCount,
        isLoading: isLoadingServerVersion,
        refetch: refetchServerVersion,
    } = trpc.util.getVersion.useQuery(undefined, { retry: 2, enabled: !serverIsDown, refetchOnWindowFocus: false });

    //todo replace getServerVersion by useGetMinimumMobileJSVersion: check if backend return version number
    const { data: minimumMobileJSVersion } = trpc.util.getMinimumMobileJSVersion.useQuery(undefined, { enabled: isMobileApp && !serverIsDown, refetchOnWindowFocus: false });
    const { data: currentUserData, refetch: refetchCurrentUserData } = trpc.user.getCurrentUserData.useQuery(undefined, { enabled: isSignedIn && !serverIsDown });

    // Initial check for sign in status based on stored token.
    useEffect(() => {
        if (signedInStatus === SIGNED_IN_STATUS.loading && !serverIsDown) {
            MB_accessTokenUtils.getAccessToken()
                .then((accessToken) => {
                    if (accessToken) {
                        setSignedInStatus(SIGNED_IN_STATUS.signedIn);
                    } else {
                        setSignedInStatus(SIGNED_IN_STATUS.signedOut);
                    }
                });
        }
    }, [onReady, serverIsDown, setSignedInStatus, signedInStatus]);

    // Checks to see if server is down or not by pulling the backend version.
    useEffect(() => {
        if (serverVersion) {
            setServerIsDown(false);
            if (isSignedIn) {
                refetchCurrentUserData();
            }
        } else if (!serverIsDown && serverVersionRequestFailureCount >= 3) {
            setServerIsDown(true);
        }

        onReady(true);
    }, [onReady, serverVersion, signedInStatus, serverVersionRequestFailureCount, serverIsDown, refetchCurrentUserData, isSignedIn]);

    function renderCommonRoutes() {
        return (
            <>
                <Stack.Screen name="HomePage" component={HomePage} />
                <Stack.Screen name="SearchPage" component={SearchPage} />
                <Stack.Screen name="CompanyPage" component={CompanyPage} />
                <Stack.Screen name="AboutUs" component={AboutUs} />
                <Stack.Screen name="ContactUs" component={ContactUs} />
            </>
        );
    }

    function renderPublicRoutes() {
        if (isSignedIn && currentUserData?.userData.userRole) {
            if (currentUserData?.userData.userRole === UserRole.admin) {
                return (
                    <Stack.Screen name="AdminNavigator" component={AdminNavigator} />
                );
            }

            return (
                <>
                     {renderCommonRoutes()}
                </>
            );
        }
    }

    function renderGuestRoutes(): ReactNode {
        if (!isSignedIn) {
            return (
                <>
                    {renderCommonRoutes()}
                    <Stack.Screen name="Login" component={Login} />
                    <Stack.Screen name="SignUp" component={SignUp} />
                </>
            );
        }

        return null;
    }

    if (serverIsDown) {
        return <ServerIsDownPage onTryAgainPressed={refetchServerVersion} isLoading={isLoadingServerVersion} />;
    }

    if (signedInStatus === SIGNED_IN_STATUS.loading || isSignedIn && currentUserData === undefined || !serverVersion || (isMobileApp && !minimumMobileJSVersion)) {
        return <LoadingPage giveUpActionTypes={LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut} hideGiveUpButton={serverIsDown === undefined} />;
    }

    return (
        <NavigationContainer
            linking={linking}
            ref={navRef}
        >
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                    gestureEnabled: false,
                }}
            >
                {renderPublicRoutes()}
                {renderGuestRoutes()}
                <Stack.Screen name="NotFound" component={NotFound} />
            </Stack.Navigator>
        </NavigationContainer >
    );
};

export default RootNavigation;
