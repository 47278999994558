import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const People = React.memo((props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 22}
                height={props.size ? props.size * (17 / 22) : 17}
                viewBox="0 0 22 17"
                fill="none"
            >
                <Path
                    d="M16.888 7.397a2.876 2.876 0 002.472-2.841 2.875 2.875 0 00-2.406-2.836M18.729 10.75c1.35.202 2.294.675 2.294 1.65 0 .671-.445 1.107-1.163 1.381"
                    stroke="#fff"
                    strokeWidth={0.8}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    clipRule="evenodd"
                    d="M10.887 11.164c-3.214 0-5.96.487-5.96 2.432 0 1.944 2.729 2.445 5.96 2.445 3.214 0 5.958-.482 5.958-2.428s-2.727-2.45-5.958-2.45zM10.887 8.388a3.819 3.819 0 10-3.82-3.82 3.804 3.804 0 003.792 3.82h.028z"
                    stroke="#fff"
                    strokeWidth={0.8}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    d="M4.885 7.397a2.875 2.875 0 01-2.472-2.841A2.875 2.875 0 014.819 1.72M3.044 10.75C1.693 10.952.75 11.425.75 12.4c0 .671.444 1.107 1.162 1.381"
                    stroke="#fff"
                    strokeWidth={0.8}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </View>
    );
});

export { People };
