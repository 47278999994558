import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { AdminNavigatorDrawer } from './AdminNavigatorDrawer';
import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { Buildings } from '../../resources/svgComponents/Buildings';
import { People } from '../../resources/svgComponents/People';
import { AnalyticsIcon } from '../../resources/svgComponents/AnalyticsIcon';
import { Analytics } from '../../components/screens/admin/Analytics';
import { SuppliersAndAds } from '../../components/screens/admin/SuppliersAndAds';
import { getColors } from '../../constants/colors';
import { NavigatorScreenParams } from '@react-navigation/core';
import { CompaniesNavigator, CompaniesNavigatorParamList } from '../companiesNavigator/CompaniesNavigator';

export type AdminNavigatorParamList = {
    Companies: NavigatorScreenParams<CompaniesNavigatorParamList>,
    SuppliersAndAds: undefined,
    Analytics: undefined,
}

const COLORS = getColors();

const Drawer = createDrawerNavigator<AdminNavigatorParamList>();

function GetDrawerIcon(routeName: keyof AdminNavigatorParamList) {
    switch (routeName) {
        case 'Companies':
            return <Buildings />;
        case 'SuppliersAndAds':
            return <People />;
        case 'Analytics':
            return <AnalyticsIcon />;
    }
}

const AdminNavigator = () => {
    return (
        <Drawer.Navigator
            screenOptions={{
                headerShown: false,
                drawerType: 'permanent',
                drawerActiveBackgroundColor: COLORS.rowActiveBackground,
                drawerLabelStyle: styles.drawerLabelStyle,
                drawerItemStyle: styles.drawerItemStyle,
            }}
            drawerContent={AdminNavigatorDrawer}
        >
            <Drawer.Screen name="Companies" component={CompaniesNavigator} options={{ drawerIcon: () => GetDrawerIcon('Companies') }} />
            <Drawer.Screen name="SuppliersAndAds" component={SuppliersAndAds} options={{ drawerLabel: 'Suppliers & Ads', drawerIcon: () => GetDrawerIcon('SuppliersAndAds') }} />
            <Drawer.Screen name="Analytics" component={Analytics} options={{ drawerIcon: () => GetDrawerIcon('Companies') }} />
        </Drawer.Navigator>
    );
};

export { AdminNavigator };


const styles = StyleSheet.create({
    drawerLabelStyle: mbTextStyles([
        textStyles.smallText, {
            fontWeight: '600',
            textAlign: 'left',
            padding: 1,
            minWidth: 150,
        },
    ]),
    drawerItemStyle: {
        borderRadius: 7,
        paddingHorizontal: 16,
    },
});
