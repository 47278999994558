import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import React, { useCallback, useState } from 'react';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../context/SignedInContext';
import { UniversalProps } from '../../../typesAndInterfaces/componentProps';
import LoginUI from './LoginUI';
import { LOGIN_UI_BUTTON_PRESSED_TYPES } from './LoginUtils';
import { trpc } from '../../../apiCalls/trpcClient';

const Login = ({ navigation }: UniversalProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { setSignedInStatus } = useSignedInContext();
  const trpcContext = trpc.useContext();

  const signIn = trpc.user.login.useMutation({ trpc: { context: { skipBatch: true } } });

  const onButtonPressedCallback = useCallback((buttonType: LOGIN_UI_BUTTON_PRESSED_TYPES) => {
    switch (buttonType) {
      case LOGIN_UI_BUTTON_PRESSED_TYPES.SIGN_IN:
        signIn.mutate(
          { email, password },
          {
            onSuccess: data => {
              trpcContext.user.getCurrentUserData.invalidate();
              MB_accessTokenUtils.setAccessToken(data.tokenPair);
              setSignedInStatus?.(SIGNED_IN_STATUS.signedIn);
            },
            onError: () => {
              setErrorMessage(STRING_CONSTANTS.FAILED_TO_LOG_IN);
            },
          },
        );
        break;
      case LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_HOME:
        navigation.navigate('HomePage');
        break;
      case LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_SIGN_UP:
        navigation.navigate('SignUp');
        break;
      case LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_FORGOT_PASSWORD:
        //todo: navigation.navigate('ForgotPassword');
        break;
    }
  }, [email, navigation, password, setSignedInStatus, signIn, trpcContext.user.getCurrentUserData]);

  return (
    <LoginUI
      onButtonPressed={onButtonPressedCallback}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />
  );
};

export { Login };
