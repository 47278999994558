import React from 'react';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { getColors } from '../../constants/colors';
import { CaseIcon } from '../../resources/svgComponents/CaseIcon';
import { PeopleIcon } from '../../resources/svgComponents/PeopleIcon';
import { textStyles } from '../../constants/textStyles';

const colors = getColors();

type IconName = 'poeple' | 'case'

const StatisticsCard = ({ iconName, title, description, style }: { iconName: IconName, title: string, description: string | number, style?: StyleProp<ViewStyle> }) => {
    return (
        <View style={[styles.container, style]}>
            <View style={styles.icon}>
                {iconName === 'poeple' && <PeopleIcon color={colors.accentColor} size={24} />}
                {iconName === 'case' && <CaseIcon color={colors.accentColor} size={24} />}
            </View>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>
        </View>
    );
};

export { StatisticsCard };

const styles = StyleSheet.create({
    container: {
        width: 258,
        height: 152,
        paddingVertical: 24.5,
        paddingStart: 15,
        borderRadius: 7,
        backgroundColor: colors.white,
    },
    icon: {
        width: 42,
        height: 42,
        backgroundColor: '#EBEFFF',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    title: mbTextStyles([textStyles.tinyText, {
        color: colors.greyText,
        letterSpacing: -0.32,
        marginTop: 10,
        textAlign: 'left',
    }]),
    description: mbTextStyles([textStyles.largeText, {
        color: colors.headerText,
        letterSpacing: -0.8,
        marginTop: 4,
        textAlign: 'left',
    }]),
});
