import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

const LinkedInIcon = React.memo(({ color = '#EEF2F7' }: { color?: string }) => {
    return (
        <Svg
            width={17}
            height={18}
            viewBox="0 0 17 18"
            fill="none"
        >
            <G clipPath="url(#clip0_1021_13350)" fill={color}>
                <Path d="M5.047 5.19H2.111a.236.236 0 00-.236.237v9.433c0 .13.106.236.236.236h2.936c.13 0 .236-.105.236-.236V5.427a.236.236 0 00-.236-.236zM3.58.501a1.939 1.939 0 00-1.937 1.936A1.94 1.94 0 003.58 4.373a1.938 1.938 0 001.936-1.936A1.938 1.938 0 003.58.5zM12.516 4.957c-1.18 0-2.05.507-2.58 1.083v-.613a.236.236 0 00-.236-.236H6.888a.236.236 0 00-.236.236v9.434c0 .13.106.236.236.236h2.93c.13 0 .236-.106.236-.236v-4.668c0-1.573.427-2.185 1.524-2.185 1.194 0 1.289.982 1.289 2.266v4.587c0 .13.105.236.236.236h2.93c.131 0 .237-.106.237-.236V9.686c0-2.338-.446-4.73-3.754-4.73z" />
            </G>
            <Defs>
                <ClipPath id="clip0_1021_13350">
                    <Path
                        fill="#fff"
                        transform="translate(.25 .483)"
                        d="M0 0H16.72V16.72H0z"
                    />
                </ClipPath>
            </Defs>
        </Svg>
    );
});

export { LinkedInIcon };
