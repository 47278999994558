import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const HamBurgerIcon = ({ size = 18, color = '#1F2136' }: { size?: number; color?: string }) => {
  return (
    <Svg
      width={size}
      height={size * (12 / 18)}
      viewBox="0 0 18 12"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0a.667.667 0 000 1.333h16A.667.667 0 0017 0H1zM.335 6a.667.667 0 01.667-.667h16a.667.667 0 010 1.334H1A.667.667 0 01.334 6zm0 5.333a.667.667 0 01.667-.666h16A.667.667 0 0117 12H1a.666.666 0 01-.667-.667z"
        fill={color}
      />
    </Svg>
  );
};

export { HamBurgerIcon };
