import React, {useRef} from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import SignupInput from './SignupInput';
import { SignupUIProps } from './SignupUtils';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MobileHeaderProps } from '../../helperComponents/Header/MobileHeader';

const SignupUI = (props: SignupUIProps) => {
    const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: true, showBackArrow: true });
    return (
        <ComponentWrapper mobileHeaderOptions={mobileHeaderOptions.current}>
        <View>
            <Text style={styles.titleLabel}>{'Create an account'}</Text>
            <SignupInput {...props} containerStyle={[styles.inputContainer, { marginTop: 26 }]} />
        </View>
        </ComponentWrapper>
    );
};

export default SignupUI;

const styles = StyleSheet.create({
    titleLabel: mbTextStyles([
        textStyles.largestText, {
            fontSize: 24,
            marginTop: 8,
        },
    ]),

    inputContainer: {
        marginTop: 26,
    },
    logoImage: {
        width: 123,
        height: 81,
        alignSelf: 'center',
    },
});
