import React from 'react';
import { StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { getColors } from '../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { MB_Button } from '@mightybyte/rnw.components.button';

const COLORS = getColors();


const BackButton = ({ arrowColor, title, style, textStyle, onPress }: { arrowColor?: string, title?: string, style?: StyleProp<ViewStyle>, textStyle?: StyleProp<TextStyle>, onPress: () => void }) => {

    return (
        <MB_Button
            title={title ?? 'Back'}
            textStyle={[styles.backText, textStyle]}
            style={[styles.buttonStyle, style]}
            onPress={onPress}
            leftElement={<AntDesign name="arrowleft" color={arrowColor ?? COLORS.accentColor} size={18} />}
        />
    );
};

export { BackButton };

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'red',
    },
    backText: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.accentColor,
            textAlign: 'left',
            fontSize: 14,
        },
    ]),
    buttonStyle: {
        alignSelf: 'flex-start',
        backgroundColor: 'transparent',
    },
});
