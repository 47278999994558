import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { getColors } from '../../../constants/colors';
import { Header } from '../Header/Header';
import { ComponentWrapperProps } from './ComponentWrapperUtils';

const ComponentWrapper = (props: ComponentWrapperProps) => {
    const scrollRef = useRef<ScrollView>(null);

    useImperativeHandle(props.MB_Ref, () => ({
        scrollTo: ({ x, y, animated }) => {
            if (!scrollRef.current) {
                console.warn('Warning: ScrollRef was not set. Was wrapInScrollView set to false?');
                return;
            }
            scrollRef.current.scrollTo({ x, y, animated });
        },
    }));

    const content = useMemo(() => {
        return (
            <>
                <View style={[styles.innerContainer, props.innerContainerStyle]} onLayout={props.onInnerComponentLayout}>
                    {props.children}
                </View>
            </>
        );
    }, [props]);

    if (props.wrapInScrollView) {
        return (
            <ScrollView
                ref={scrollRef}
                onLayout={props.onLayout}
                onScroll={props.onScroll}
                scrollEventThrottle={64}
                contentContainerStyle={[styles.container, props.containerStyle]}
                keyboardShouldPersistTaps={props.keyboardShouldPersistTapsForScrollView}
            >
                {content}
            </ScrollView>
        );
    }

    return (
        <View style={[styles.container, props.containerStyle]} onLayout={props.onLayout}>
            {!props.hideWebHeader &&
                <Header hideLogo={props.hideHeaderLogo ?? false} containerStyle={props.webHeaderContainerStyle} />
            }
            {content}
        </View>
    );
};

export { ComponentWrapper };

const COLORS = getColors();

const styles = StyleSheet.create({
    container: {
        minHeight: '100%',
        backgroundColor: COLORS.backgroundPrimary,
        justifyContent: 'space-between',
    },
    innerContainer: {
        //maxWidth: 1680,
        //paddingHorizontal: 54,
        alignSelf: 'center',
        width: '100%',
        flex: 1,
    },
});
