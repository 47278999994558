import React, { useState } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { StyleSheet, Text, View } from 'react-native';
import { Dropdown } from '../../helperComponents/Dropdown';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { getColors } from '../../../constants/colors';
import { StatisticsCard } from '../../helperComponents/StatisticsCard';

const colors = getColors();

enum StatsFilter {
  ThisWeek = 'This week',
  ThisYear = 'This year',
  ThisMonth = 'This month'
}

const Analytics = () => {

  const [statsFilter, setStatsFilter] = useState<StatsFilter>(StatsFilter.ThisWeek);
  // TODO: Add accurate data
  return (
    <ComponentWrapper
      innerContainerStyle={styles.innerContainer}
      hideHeaderLogo
    >
      <View style={styles.content}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>App Analytics</Text>
          <Dropdown
            options={Object.values(StatsFilter)}
            option={statsFilter}
            onSelect={(value) => setStatsFilter(value as StatsFilter)}
            style={styles.dropdownContainer}
            dropdownStyle={styles.dropdown}
          />
        </View>
        <Text style={[textStyles.largestText, {color: 'black'}]}>TODO: Data is not accurate yet</Text>
        <View style={styles.cards}>
          <StatisticsCard iconName="case" title="NO. OF COMPANIES" description={372} style={styles.card} />
          <StatisticsCard iconName="poeple" title="NO. OF REGISTERED ACCOUNTS" description={95} style={styles.card} />
          <StatisticsCard iconName="case" title="ADS REVENUE GENERATED" description="$5,460" style={styles.card} />
        </View>
      </View>
    </ComponentWrapper>
  );
};

export { Analytics };

const styles = StyleSheet.create({
  innerContainer: {
    backgroundColor: '#F7F8FA',
    paddingHorizontal: 43,
    paddingTop: 24,
  },
  content: {

  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: mbTextStyles([textStyles.largeText, {
    color: colors.bodyText,
    textAlign: 'left',
  }]),
  dropdownContainer: {
    width: 120,
  },
  dropdown: {
    height: 120,
  },
  cards: {
    maxWidth: 1200,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  card: {
    marginTop: 30,
    marginHorizontal: 5,
  },
});
