import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { getColors } from '../../../../constants/colors';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { MB_Tag, MB_TagsRef } from '@mightybyte/rnw.components.tags';
import { trpc } from '../../../../apiCalls/trpcClient';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { AddNewTag } from '../../../helperComponents/AddNewTag';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { CustomTags } from '../../../helperComponents/CustomTags';
import { CompanyDataForClient, TagDataForClient, TagType } from '@id/business';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { isEqual } from 'lodash';

const COLORS = getColors();

const CreateCompanyTagsAndDescription = ({ companyToEdit, onEdited }: { companyToEdit: CompanyDataForClient, onEdited: (companyId: string) => void }) => {
    const [companyTags, setCompanyTags] = useState<Omit<TagDataForClient, 'id'>[] | undefined>();
    const [description, setDescription] = useState('');
    const [isAddNewTagVisible, showAddNewTag, hideAddNewTag] = MB_utilHooks.useBool();

    const { data: tags } = trpc.tag.getAll.useQuery({ type: TagType.sports });

    useEffect(() => {
        setDescription(companyToEdit.description ?? '');
    }, [companyToEdit.description]);

    const companyEditor = trpc.admin.company.edit.useMutation({
        onSuccess: () => {
            onEdited(companyToEdit.id);
            // TODO: Hovo: Address this
            mbShowToast({ text1: 'Success' });
        },
        onError: () => {
            // TODO: Hovo: Address this
            mbShowToast({ text1: 'Error' });
        },
    });

    const initialTags = useMemo(() => {
        if (!tags) {
            return undefined;
        }
        return tags.tags.filter(tg => companyToEdit.sportsTagsIds?.includes(tg.id)).map(tag => ({ title: tag.name }));
    }, [companyToEdit.sportsTagsIds, tags]);

    const tagsRef = useRef<MB_TagsRef>(null);

    const filteredTags = useMemo(() => {
        return (tags?.tags ?? []).map(tg => ({ id: tg.id, title: tg.name }));
    }, [tags?.tags]);

    const onTagsChange = useCallback((newTags: MB_Tag[]) => { // TODO: Hovo: This is a mess
        setCompanyTags(newTags.map(newTag => ({ name: newTag.title, type: TagType.sports })));
    }, []);

    function onTagCreatorDismissed(props: { name: string, createdOrSelected: 'created' | 'selected' } | undefined) {
        hideAddNewTag();

        if (props && companyTags && !companyTags.map(t => t.name).includes(props.name)) {
            tagsRef.current?.addTag({ title: props.name.toLowerCase() });
            if (props.createdOrSelected === 'created') {
                mbShowToast({ text1: 'Success' }); // TODO: Hovo: Style this
            }
        }
    }

    function isButtonDisabled() {
        if (companyEditor.isLoading) {
            return true;
        }

        const companyTagsIds = (tags?.tags ?? []).filter(tag => companyTags?.map(tg => tg.name).includes(tag.name)).map(tag => tag.id);

        const tagsEqual = isEqual(companyToEdit.sportsTagsIds, companyTagsIds);

        if (description !== (companyToEdit.description ?? '') || !tagsEqual) {
            return false;
        }

        return true;
    }

    function onSavePressed() {
        if (tags) {
            const tagsToUpload = tags.tags.filter(tag => companyTags?.map(tg => tg.name).includes(tag.name));
            const sportsTagsIds = tagsToUpload.map(tag => tag.id);
            companyEditor.mutate({ id: companyToEdit.id, sportsTagsIds, description });
        }
    }

    if (!tags) {
        return <LoadingPage />;
    }

    return (
        <View style={{ backgroundColor: COLORS.white, borderRadius: 10, paddingHorizontal: 26, paddingVertical: 26 }}>
            <AddNewTag
                isVisible={isAddNewTagVisible}
                onDismiss={onTagCreatorDismissed}
                allTags={tags?.tags ?? []}
                type={TagType.sports}
            />
            <CustomTags
                MB_Ref={tagsRef}
                title="Sports Tags"
                initialTags={initialTags}
                allTags={filteredTags}
                onTagsChange={onTagsChange}
                onAddNewPressed={showAddNewTag}
            />
            <MB_TextInput
                title="Description"
                placeholder="Company description"
                multiline
                style={styles.textInput}
                showCharacterLimit
                maxLength={300}
                value={description}
                onChangeText={setDescription}
            />

            <MB_Button
                title="Save"
                style={{ marginTop: 42, alignSelf: 'flex-end', paddingHorizontal: 32 }}
                onPress={onSavePressed}
                disabled={isButtonDisabled()}
                loading={companyEditor.isLoading}
            />
        </View>
    );
};


export { CreateCompanyTagsAndDescription };

const styles = StyleSheet.create({
    container: {
    },
    textInput: {
        width: '100%',
        marginTop: 22,
        height: 168,
    },
    uploadLogoTitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.accentColor,
            marginTop: 12,
        },
    ]),
    uploadLogoSubtitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.bodyText2,
            fontSize: 11,
            fontWeight: '300',
        },
    ]),
});
