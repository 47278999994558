import React, { useCallback, useRef } from 'react';
import { ModalDropdown } from '@mightybyte/rnw.components.modal-dropdown';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import { StyleSheet, Text, View } from 'react-native';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_AnimatedChevron, MB_AnimatedChevronRef } from '@mightybyte/rnw.components.animated-chevron';
import { getColors } from '../../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';

export enum SearchFilter {
    Equipment = 'Equipment',
    Sport = 'Sport',
    Supplier = 'Supplier'
}

const COLORS = getColors();
interface FilterButtonProps {
    filterType: SearchFilter
    onFilterChange: (filterType: SearchFilter) => void
    onPress?: () => void
}

const FilterButton = ({ filterType, onFilterChange, onPress }: FilterButtonProps) => {

    const modalDropdownRef = useRef<ModalDropdown>(null);
    const filterChevronRef = useRef<MB_AnimatedChevronRef>(null);

    const onShowDropdown = useCallback(() => {
        modalDropdownRef.current?.show();
        filterChevronRef.current?.setToFinalRotation();
        onPress?.();
    }, [onPress]);

    const onDropdownWillHide = useCallback(() => filterChevronRef.current?.setToInitialRotation(), []);
    const onDropdownWillShow = useCallback(() => filterChevronRef.current?.setToFinalRotation(), []);

    const renderRow = useCallback((option: SearchFilter) => (
        <MB_OnHoverWrapper
            wrapperStyle={styles.option}
            onHoverWrapperStyleModifier={styles.hover}
            onPress={() => {
                modalDropdownRef.current?.hide();
                filterChevronRef.current?.setToInitialRotation();
                onFilterChange(option);
            }}
        >
            <Text style={styles.optionText}>{option}</Text>
        </MB_OnHoverWrapper>
    ), [onFilterChange]);

    return (
        <View style={{ alignItems: 'flex-end' }}>
            <MB_Button
                title={filterType}
                style={styles.filterButton}
                textStyle={styles.filterButtonText}
                rightElement={<MB_AnimatedChevron chevronColor="#000" ref={filterChevronRef} chevronSize={13} />}
                onPress={onShowDropdown}
            />
            <ModalDropdown
                ref={modalDropdownRef}
                options={[SearchFilter.Equipment, SearchFilter.Sport, SearchFilter.Supplier]}
                dropdownStyle={styles.filterModal}
                onDropdownWillHide={onDropdownWillHide}
                onDropdownWillShow={onDropdownWillShow}
                renderSeparator={() => null}
                renderRow={renderRow}
            >
                <View style={{ width: 140, height: 0 }} />
            </ModalDropdown>
        </View>
    );
};

export { FilterButton };

const styles = StyleSheet.create({
    filterModal: {
        width: 140,
        height: 110,
        elevation: 5,
        backgroundColor: COLORS.white,
        marginTop: 5,
        borderRadius: 7,
        overflow: 'hidden',
    },
    filterButton: {
        paddingHorizontal: 10,
        backgroundColor: COLORS.white,
        width: 140,
        height: 58,
        borderRadius: 7,
    },
    filterButtonText: mbTextStyles([textStyles.smallerText, {
        color: COLORS.primaryColor,
        fontSize: 13,
        marginRight: 'auto',
    }]),
    hover: {
        backgroundColor: COLORS.lightBlue,
    },
    option: {
        paddingLeft: 15,
        justifyContent: 'center',
        height: 36,
    },
    optionText: mbTextStyles([
        textStyles.smallText, {
            fontSize: 13,
            textAlign: 'left',
            fontWeight: '400',
            color: COLORS.primaryColor,
        },
    ]),
});
