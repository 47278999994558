import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, Rect } from 'react-native-svg';

const UploadLogo = React.memo((props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 94}
                height={props.size ? props.size * (93 / 94) : 93}
                viewBox="0 0 94 93"
                fill="none"
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.42 39.794a1.916 1.916 0 011.916-1.916h5.288a1.915 1.915 0 011.495.719l1.342 1.676h7.203a1.916 1.916 0 011.916 1.916v11.017a1.916 1.916 0 01-1.916 1.916H39.336a1.916 1.916 0 01-1.916-1.916V39.794zm8.903 4.956a.958.958 0 011.354 0l2.032 2.032a.958.958 0 11-1.355 1.354l-.396-.396v2.592a.958.958 0 11-1.916 0V47.74l-.397.396a.959.959 0 11-1.354-1.354l2.03-2.032h.002z"
                    fill="#1D52E8"
                />
                <Rect
                    x={0.81}
                    y={0.31}
                    width={92.38}
                    height={92.38}
                    rx={46.19}
                    stroke="#1D52E8"
                    strokeWidth={0.62}
                    strokeDasharray="2.32 2.32"
                />
            </Svg>
        </View>
    );
});

export { UploadLogo };
