import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import {
    DrawerContentComponentProps,
    DrawerContentScrollView,
    DrawerItemList,
} from '@react-navigation/drawer';
import { textStyles } from '../../constants/textStyles';
import { getVersionNumber } from '../../utils/getVersionNumber/getVersionNumber';
import { envs } from '../../../env';
import { LogoText } from '../../components/helperComponents/LogoText';
import { getColors } from '../../constants/colors';
import { trpc } from '../../apiCalls/trpcClient';

const versions = getVersionNumber();

function AdminNavigatorDrawer(props: DrawerContentComponentProps) {
    const { data: serverVersion } = trpc.util.getVersion.useQuery();

    return (
        <DrawerContentScrollView {...props} style={styles.drawerContainerStyle} contentContainerStyle={styles.drawerContentContainer}>
            <LogoText style={{ marginBottom: 48, marginTop: 16, width: 180, alignSelf: 'center' }} />
            <DrawerItemList {...props} />

            <View style={{ marginTop: 'auto', alignItems: 'flex-start', paddingBottom: 16 }}>
                <Text style={textStyles.tinyText}>JS Version: {versions.jsVersion}</Text>
                {isMobileApp &&
                    <Text style={textStyles.tinyText}>App Version: {`${versions.appVersion}(${versions.buildVersion})`}</Text>
                }
                <Text style={textStyles.tinyText}>Flavor: {envs.FLAVOR}</Text>
                <Text style={textStyles.tinyText}>Mode: {process.env.NODE_ENV}, isDev: {__DEV__?.toString()}</Text>
                <Text style={textStyles.tinyText}>Server Version: {serverVersion?.version}</Text>
            </View>
        </DrawerContentScrollView>
    );
}

export { AdminNavigatorDrawer };

const COLORS = getColors();

const styles = StyleSheet.create({
    drawerContainerStyle: {
        backgroundColor: COLORS.primaryColor,
        paddingTop: 6,
        paddingHorizontal: 20,
    },
    drawerContentContainer: {
        flex: 1,
    },
});
