import React, { useCallback, useMemo } from 'react';
import { Header } from '../../helperComponents/Header/Header';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { imageUtils } from '../../../utils/imageUtils';
import { StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { utils } from '../../../utils/utils';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { LinkedInIcon } from '../../../resources/svgComponents/LinkedInIcon';
import { TwitterIcon } from '../../../resources/svgComponents/TwitterIcon';
import { InstagramIcon } from '../../../resources/svgComponents/InstagramIcon';
import { getColors } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { FacebookIcon } from '../../../resources/svgComponents/FacebookIcon';
import { Footer } from '../HomePage/Footer';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';

const colors = getColors();

const ContactUs = () => {

    const styles = useGetStyles();

    const comingSoon = useCallback(() => {
        mbShowPopUp({
            title: 'Coming Soon',
            message: 'this feather coming soon!',
        });
    }, []);

    return (
        <ComponentWrapper hideWebHeader innerContainerStyle={{justifyContent: 'space-between'}}>
            <Header
                hideLogo={false}
                containerStyle={styles.header}
            />
            <MB_Image
                style={styles.cover}
                source={imageUtils.images.contactUsCover}
                disableInteraction
            />
            <View style={styles.content}>
                <View style={styles.badge}>
                    <Text style={styles.badgeText}>CONNECT WITH US</Text>
                </View>
                <Text style={styles.title}>We’d love to hear from you.</Text>
                <Text style={styles.subText}>Fill out the form provided, and we’ll get back to you shortly.</Text>
                <View style={styles.socialIcons}>
                    <TouchableOpacity style={styles.iconCircle} onPress={comingSoon}>
                        <FacebookIcon color={colors.accentColor} />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.iconCircle} onPress={comingSoon}>
                        <LinkedInIcon color={colors.accentColor} />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.iconCircle} onPress={comingSoon}>
                        <TwitterIcon color={colors.accentColor} />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.iconCircle} onPress={comingSoon}>
                        <InstagramIcon color={colors.accentColor} />
                    </TouchableOpacity>
                </View>
            </View>
            <View style={styles.form}>
                <View style={styles.formRow}>
                    <MB_TextInput
                        title="First name"
                        showRequiredAsteriks
                        placeholder="First name"
                        style={styles.nameField}
                    />
                    <MB_TextInput
                        title="Last name"
                        showRequiredAsteriks
                        placeholder="Last name"
                        style={[styles.nameField, styles.lastNameField]}
                    />
                </View>
                <MB_TextInput
                    title="Email address"
                    showRequiredAsteriks
                    placeholder="Email address"
                    style={styles.emailField}
                />
                <MB_TextInput
                    title="Message"
                    showRequiredAsteriks
                    placeholder="How can we help?"
                    multiline
                    style={styles.messageField}
                />
                <View style={styles.submitContainer}>
                    <MB_Button
                        title="Submit"
                        style={styles.submitBtn}
                        onPress={comingSoon}
                    />
                </View>
            </View>
            <Footer style={styles.footer} />
        </ComponentWrapper>
    );
};

export { ContactUs };

const useGetStyles = () => {
    const { width } = useWindowDimensions();
    const minWidth = 375;
    const maxWidth = 1440;
    return useMemo(() => StyleSheet.create({
        ...styles,
        cover: StyleSheet.flatten([styles.cover, {
            height: utils.getRelativeValue(minWidth, 235, maxWidth, 244, width),
        }]),
        content: StyleSheet.flatten([styles.content, {
            width: utils.getRelativeValue(minWidth, 344, maxWidth, 531, width),
            marginLeft: width < 1100 ? undefined : utils.getRelativeValue(minWidth, 19, maxWidth, 109, width),
            alignSelf: width < 1100 ? 'center' : undefined,
        }]),
        badgeText: StyleSheet.flatten([styles.badgeText, {
            fontSize: utils.getRelativeValue(minWidth, 11, maxWidth, 14, width),
        }]),
        title: StyleSheet.flatten([styles.title, {
            fontSize: utils.getRelativeValue(minWidth, 24, maxWidth, 42, width),
            marginTop: utils.getRelativeValue(minWidth, 15, maxWidth, 35, width),
        }]),
        socialIcons: StyleSheet.flatten([styles.socialIcons, {
            marginTop: utils.getRelativeValue(minWidth, 19, maxWidth, 46, width),
            width: utils.getRelativeValue(minWidth, 185, maxWidth, 231, width),
        }]),
        iconCircle: StyleSheet.flatten([styles.iconCircle, {
            width: utils.getRelativeValue(minWidth, 36, maxWidth, 45, width),
            height: utils.getRelativeValue(minWidth, 36, maxWidth, 45, width),
        }]),
        form: StyleSheet.flatten([styles.form, {
            width: utils.getRelativeValue(minWidth, 336, maxWidth, 507, width),
            position: width < 1100 ? 'relative' : 'absolute',
            top: width < 1100 ? undefined : 122,
            right: width < 1100 ? undefined : 90,
            marginTop: width < 1100 ? 54 : undefined,
            alignSelf: width < 1100 ? 'center' : undefined,
        }]),
        formRow: StyleSheet.flatten([styles.formRow, {
            width: utils.getRelativeValue(minWidth, 290, maxWidth, 451, width),
            flexDirection: width < 1100 ? undefined : 'row',
        }]),
        nameField: StyleSheet.flatten([styles.nameField, {
            width: width < 1100 ? utils.getRelativeValue(minWidth, 290, maxWidth, 451, width) : utils.getRelativeValue(minWidth, 140, maxWidth, 220, width),
        }]),
        lastNameField: StyleSheet.flatten([styles.lastNameField, {
            marginTop: width < 1100 ? 22 : undefined,
        }]),
        emailField: StyleSheet.flatten([styles.emailField, {
            width: utils.getRelativeValue(minWidth, 290, maxWidth, 451, width),
        }]),
        messageField: StyleSheet.flatten([styles.messageField, {
            width: utils.getRelativeValue(minWidth, 290, maxWidth, 451, width),
            height: utils.getRelativeValue(minWidth, 126, maxWidth, 120, width),
        }]),
        submitContainer: StyleSheet.flatten([styles.submitContainer, {
            width: utils.getRelativeValue(minWidth, 290, maxWidth, 451, width),
        }]),
        footer: StyleSheet.flatten([styles.footer, {
            marginTop: utils.getRelativeValue(minWidth, 63, maxWidth, 180, width),
        }]),
    }), [width]);
};

const styles = StyleSheet.create({
    header: {
        backgroundColor: 'transparent',
    },
    cover: {
        position: 'absolute',
        width: '100%',
        zIndex: -2,
    },
    content: {
        marginTop: 227,
    },
    badge: {
        backgroundColor: '#EBEFFF',
        paddingHorizontal: 24,
        paddingVertical: 7,
        borderRadius: 30,
        alignSelf: 'flex-start',
    },
    badgeText: mbTextStyles([textStyles.smallText, {
        color: colors.accentColor,
        alignSelf: 'center',
    }]),
    title: mbTextStyles([textStyles.superLargeText, {
        color: colors.primaryColor,
        marginTop: 35,
        fontWeight: '600',
        textAlign: 'left',
        letterSpacing: -0.86,
    }]),
    subText: mbTextStyles([textStyles.smallText, {
        fontSize: 15,
        color: colors.textSecondary2,
        textAlign: 'left',
    }]),
    socialIcons: {
        flexDirection: 'row',
        width: 231,
        justifyContent: 'space-between',
        marginTop: 46,
    },
    iconCircle: {
        width: 45,
        height: 45,
        backgroundColor: '#EBEFFF',
        borderRadius: 360,
        alignItems: 'center',
        justifyContent: 'center',
    },
    form: {
        top: 122,
        right: 90,
        position: 'absolute',
        backgroundColor: '#FFF',
        borderRadius: 10,
        alignItems: 'center',
        paddingVertical: 36,
        ...mbShadow({
            color: '#000000',
            offsetWidth: 0,
            offsetHeight: 1,
            radius: 15,
            opacity: 0.1,
            elevation: 15,
        }),
    },
    formRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 451,
    },
    nameField: {
        width: 220,
        height: 48,
    },
    lastNameField: {},
    emailField: {
        width: 451,
        marginTop: 22,
        height: 48,
    },
    messageField: {
        width: 451,
        height: 120,
        marginTop: 22,
    },
    submitContainer: {
        paddingTop: 30,
        justifyContent: 'flex-end',
    },
    submitBtn: {
        backgroundColor: colors.secondaryColor,
        paddingVertical: 15,
        paddingHorizontal: 30,
        marginLeft: 'auto',
        marginRight: 6,
    },
    footer: {},
});
