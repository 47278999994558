import React, { useCallback, useMemo } from 'react';
import { RouteProp, useNavigation } from '@react-navigation/core';
import { UniversalScreenNavigationProp } from '../../typesAndInterfaces/componentProps';
import { View, Text, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { RootStackParamList } from '../../navigations/RootNavigator';
import { textStyles } from '../../constants/textStyles';
import { getColors } from '../../constants/colors';

const colors = getColors();

const NavIndicator = ({ style }: { style?: StyleProp<ViewStyle> }) => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const routes = navigation.getState().routes;
    const previousRoute = routes[routes.length - 2] as RouteProp<RootStackParamList, 'SearchPage'> | undefined;
    const currentRoute = routes[routes.length - 1] as RouteProp<RootStackParamList, 'SearchPage' | 'CompanyPage'> | undefined;

    const [searchRouteType, searchParams] = useMemo(() => {
        if (currentRoute?.name === 'SearchPage') {
            return ['current', (currentRoute as RouteProp<RootStackParamList, 'SearchPage'>).params];
        } else if (previousRoute?.name === 'SearchPage') {
            return ['previous', previousRoute.params];
        }
        return [];
    }, [currentRoute, previousRoute]);

    const navigateToHome = useCallback(() => navigation.navigate('HomePage'), [navigation]);
    const navigateToSearch = useMemo(() => {
        if (searchRouteType === 'previous' && searchParams) {
            return () => navigation.navigate('SearchPage', searchParams);
        }
    }, [navigation, searchParams, searchRouteType]);

    return (
        <View style={[styles.container, style]}>
            <Text style={styles.route} onPress={navigateToHome}>Home</Text>
            <Text style={styles.route}> / </Text>
            {searchParams &&
                <>
                    <Text
                        style={[styles.route, searchRouteType === 'current' && styles.active]}
                        onPress={navigateToSearch}
                    >
                        Search results for “{searchParams.query} {searchParams.filter}”
                    </Text>
                    {searchRouteType !== 'current' && <Text style={styles.route}> / </Text>}
                </>
            }
            {currentRoute?.name === 'CompanyPage' &&
                <Text style={[styles.route, styles.active]}>Supplier Profile</Text>
            }
        </View>
    );
};

export { NavIndicator };



const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    route: {
        ...textStyles.smallText,
        fontSize: 13,
        color: colors.bodyText2,
        textAlign: 'left',
        letterSpacing: -0.13,
    },
    active: {
        color: colors.primaryColor,
    },
});
