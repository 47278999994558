import React, { useMemo } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { Header } from '../../helperComponents/Header/Header';
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import { utils } from '../../../utils/utils';
import { getColors } from '../../../constants/colors';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { imageUtils } from '../../../utils/imageUtils';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { CheckMark } from '../../../resources/svgComponents/CheckMark';
import { Footer } from '../HomePage/Footer';

const colors = getColors();

const AboutUs = () => {

    const styles = useGetStyles();

    return (
        <ComponentWrapper hideWebHeader>
            <Header
                hideLogo={false}
                containerStyle={styles.header}
                altLogoColors
            />
            <View style={styles.cover} />
            <Text style={styles.pageTitle}>About Us</Text>
            <Text style={styles.pageDesc}>Lorem ipsum dolor sit amet consectetur. Cras id commodo dui eget sapien neque gravida. Tempor praesent gravida ante ornare magna. Commodo dignissim sapien tristique cras cursus. Etiam at eu nisi neque aliquet in sem. Viverra nulla quam non egestas pretium. Tortor odio.</Text>
            <View style={styles.topSection}>
                <View style={styles.topInformation}>
                    <Text style={styles.sectionTitle}>Search for equipments to build your sports arena</Text>
                    <Text style={styles.sectionDesc}>Lorem ipsum dolor sit amet consectetur. Eu facilisi fermentum dignissim turpis nulla nunc risus urna varius. Felis tincidunt fames faucibus cursus magna eget euismod ultricies vestibulum. Faucibus feugiat eu eros id risus. Nunc mollis metus purus sed egestas risus arcu turpis. Congue curabitur quam ut suspendisse dignissim mollis placerat. Magna egestas convallis tellus et iaculis sed.</Text>
                    <View style={styles.benefitContainer}>
                        <CheckMark />
                        <Text style={styles.benefitText}>Benefit of ID to buyers</Text>
                    </View>
                    <View style={styles.benefitContainer}>
                        <CheckMark />
                        <Text style={styles.benefitText}>Benefit of ID to buyers</Text>
                    </View>
                    <View style={styles.benefitContainer}>
                        <CheckMark />
                        <Text style={styles.benefitText}>Benefit of ID to buyers</Text>
                    </View>
                </View>
                <MB_Image
                    style={styles.equipments}
                    source={imageUtils.images.equipments}
                    disableInteraction
                />
            </View>
            <View style={styles.bottomSection}>
                <View style={styles.supplierContainer}>
                    <MB_Image
                        style={styles.suppliersImageCollection}
                        source={imageUtils.images.suppliersImageCollection}
                        disableInteraction
                    />
                </View>
                <View style={styles.bottomInformation}>
                    <Text style={styles.sectionTitle}>Promote your business and reach customers</Text>
                    <Text style={styles.sectionDesc}>Lorem ipsum dolor sit amet consectetur. Eu facilisi fermentum dignissim turpis nulla nunc risus urna varius. Felis tincidunt fames faucibus cursus magna eget euismod ultricies vestibulum. Faucibus feugiat eu eros id risus. Nunc mollis metus purus sed egestas risus arcu turpis. Congue curabitur quam ut suspendisse dignissim mollis placerat. Magna egestas convallis tellus et iaculis sed.</Text>
                    <Text style={styles.sectionSecondDesc}>Lorem ipsum dolor sit amet consectetur. Eu facilisi fermentum dignissim turpis nulla nunc risus urna varius. Felis tincidunt fames faucibus cursus magna eget euismod ultricies vestibulum. Faucibus feugiat eu eros id risus. Nunc mollis metus purus sed egestas risus arcu turpis. Congue curabitur quam ut suspendisse dignissim mollis placerat. Magna egestas convallis tellus et iaculis sed.</Text>
                    <View style={styles.benefitContainer}>
                        <CheckMark />
                        <Text style={styles.benefitText}>Benefit of ID to buyers</Text>
                    </View>
                    <View style={styles.benefitContainer}>
                        <CheckMark />
                        <Text style={styles.benefitText}>Benefit of ID to buyers</Text>
                    </View>
                    <View style={styles.benefitContainer}>
                        <CheckMark />
                        <Text style={styles.benefitText}>Benefit of ID to buyers</Text>
                    </View>
                </View>
            </View>
            <Footer style={styles.footer} />
        </ComponentWrapper>
    );
};

export { AboutUs };

const useGetStyles = () => {
    const { width } = useWindowDimensions();
    const minWidth = 375;
    const maxWidth = 1440;
    return useMemo(() => StyleSheet.create({
        ...styles,
        cover: StyleSheet.flatten([styles.cover, {
            height: utils.getRelativeValue(minWidth, 300, maxWidth, 239, width),
        }]),
        pageTitle: StyleSheet.flatten([styles.pageTitle, {
            fontSize: utils.getRelativeValue(minWidth, 24, maxWidth, 35, width),
        }]),
        pageDesc: StyleSheet.flatten([styles.pageDesc, {
            width: utils.getRelativeValue(minWidth, 299, maxWidth, 995, width),
        }]),
        topSection: StyleSheet.flatten([styles.topSection, {
            marginTop: utils.getRelativeValue(minWidth, 68, maxWidth, 114, width),
            width: utils.getRelativeValue(minWidth, 324, maxWidth, 1310, width),
            alignItems: width < 1280 ? 'center' : undefined,
            flexDirection: width < 1280 ? undefined : 'row',
        }]),
        bottomSection: StyleSheet.flatten([styles.bottomSection, {
            marginTop: utils.getRelativeValue(minWidth, 42, maxWidth, 130, width),
            width: utils.getRelativeValue(minWidth, 324, maxWidth, 1310, width),
            alignItems: width < 1280 ? 'center' : undefined,
            flexDirection: width < 1280 ? 'column-reverse' : 'row',
        }]),
        sectionTitle: StyleSheet.flatten([styles.sectionTitle, {
            fontSize: utils.getRelativeValue(minWidth, 21, maxWidth, 26, width),
        }]),
        topInformation: StyleSheet.flatten([styles.topInformation, {
            width: width < 1280 ? utils.getRelativeValue(minWidth, 335, maxWidth, 642, width) : utils.getRelativeValue(minWidth, 324, maxWidth, 577, width),
        }]),
        equipments: StyleSheet.flatten([styles.equipments, {
            height: utils.getRelativeValue(minWidth, 188, maxWidth, 346, width),
            width: utils.getRelativeValue(minWidth, 335, maxWidth, 642, width),
            marginTop: width < 1280 ? 28 : undefined,
        }]),
        bottomInformation: StyleSheet.flatten([styles.bottomInformation, {
            width: utils.getRelativeValue(minWidth, 335, maxWidth, 642, width),
        }]),
        supplierContainer: StyleSheet.flatten([styles.supplierContainer, {
            width: width < 1280 ? utils.getRelativeValue(minWidth, 335, maxWidth, 642, width) : undefined,
        }]),
        suppliersImageCollection: StyleSheet.flatten([styles.suppliersImageCollection, {
            height: utils.getRelativeValue(minWidth, 300, maxWidth, 497, width),
            width: utils.getRelativeValue(minWidth, 333, maxWidth, 577, width),
            marginTop: width < 1280 ? 28 : undefined,
        }]),
        footer: StyleSheet.flatten([styles.footer, {
            marginTop: utils.getRelativeValue(minWidth, 64, maxWidth, 81, width),
        }]),
    }), [width]);
};

const styles = StyleSheet.create({
    header: {
        backgroundColor: 'transparent',
    },
    cover: {
        position: 'absolute',
        width: '100%',
        zIndex: -1,
        backgroundColor: colors.lightBlue,
    },
    pageTitle: mbTextStyles([textStyles.largestText, {
        fontSize: 35,
        marginTop: 30,
        alignSelf: 'center',
        color: colors.primaryColor,
    }]),
    pageDesc: mbTextStyles([textStyles.smallText, {
        fontSize: 14,
        marginTop: 12,
        alignSelf: 'center',
        color: colors.textSecondary2,
    }]),
    topSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 78,
        alignSelf: 'center',
    },
    bottomSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 130,
        alignSelf: 'center',
    },
    topInformation: {},
    equipments: {
        borderRadius: 7,
        overflow: 'hidden',
    },
    bottomInformation: {},
    supplierContainer: {},
    suppliersImageCollection: {},
    sectionTitle: mbTextStyles([textStyles.largeText, {
        fontSize: 26,
        color: colors.primaryColor,
        textAlign: 'left',
    }]),
    sectionDesc: mbTextStyles([textStyles.smallText, {
        color: colors.primaryColor,
        marginTop: 11,
        textAlign: 'left',
    }]),
    sectionSecondDesc: mbTextStyles([textStyles.smallText, {
        color: colors.primaryColor,
        marginTop: 26,
        marginBottom: 2,
        textAlign: 'left',
    }]),
    benefitContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 24,
    },
    benefitText: mbTextStyles([textStyles.smallText, {
        color: colors.primaryColor,
        marginLeft: 12,
    }]),
    footer: {},
});
