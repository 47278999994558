import React, { useCallback, useMemo } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { imageUtils } from '../../../utils/imageUtils';
import { StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { Header } from '../../helperComponents/Header/Header';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { getColors } from '../../../constants/colors';
import { ShopIcon } from '../../../resources/svgComponents/ShopIcon';
import { RightArrow } from '../../../resources/svgComponents/RightArrow';
import { utils } from '../../../utils/utils';
import { Footer } from './Footer';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { Search } from './Search';

const colors = getColors();

type ItemKeys = 'baseball' | 'soccer' | 'basketball' | 'hockey' | 'football' | 'badminton' | 'tennis'

const HomePage = () => {

    const styles = useGetStyles();

    const comingSoon = useCallback(() => {
        mbShowPopUp({
            title: 'Coming Soon',
            message: 'this feather coming soon!',
        });
    }, []);

    return (
        <ComponentWrapper hideWebHeader>
            <Header
                hideLogo={false}
                containerStyle={styles.header}
            />
            <MB_Image
                style={styles.backgroundImage}
                source={imageUtils.images.homePageCover}
                disableInteraction
            />
            <Text style={styles.title}>Discover everything you need to build your sports arena</Text>
            <Search />
            <View style={styles.itemsContainer}>
                {['baseball', '|', 'soccer', '|', 'basketball', '|', 'hockey', '|', 'football', '|', 'badminton', '|', 'tennis'].map((key, index) => (
                    key === '|' ?
                        <View key={`${key}${index}`} style={styles.spacer} />
                        :
                        <TouchableOpacity
                            key={key}
                            style={styles.itemContainer}
                            onPress={comingSoon}
                        >
                            <MB_Image
                                style={styles.item}
                                source={imageUtils.images[key as ItemKeys]}
                                disableInteraction
                            />
                            <Text style={styles.itemText}>{key}</Text>
                        </TouchableOpacity>
                ))}
            </View>
            <Text style={styles.howItWorksTitleText}>How it works</Text>
            <Text style={styles.howItWorksDescText}>Whether you're building a baseball complex, small recreational field or a large professional stadium, we have a comprehensive list of suppliers who can meet your needs. Browse our list of suppliers today and find the perfect sporting equipment for your arena.</Text>
            <View style={styles.featersContainer}>
                <View style={styles.feater}>
                    <View style={styles.iconConttainer}>
                        <Text style={styles.iconText}>🔎</Text>
                    </View>
                    <Text style={styles.featerTitle}>Discover suppliers</Text>
                    <Text style={styles.featerDesc}>Lorem ipsum dolor sit amet consectetur. Ultrices pulvinar semper amet cum sed fermentum pharetra sodales lectus. </Text>
                </View>
                <View style={styles.feater}>
                    <View style={styles.iconConttainer}>
                        <ShopIcon />
                    </View>
                    <Text style={styles.featerTitle}>Contact supplier</Text>
                    <Text style={styles.featerDesc}>Lorem ipsum dolor sit amet consectetur. Ultrices pulvinar semper amet cum sed fermentum pharetra sodales lectus. </Text>
                </View>
                <View style={styles.feater}>
                    <View style={styles.iconConttainer}>
                        <Text style={styles.iconText}>📱</Text>
                    </View>
                    <Text style={styles.featerTitle}>Search for equipment</Text>
                    <Text style={styles.featerDesc}>Lorem ipsum dolor sit amet consectetur. Ultrices pulvinar semper amet cum sed fermentum pharetra sodales lectus. </Text>
                </View>
            </View>
            <View style={styles.suppliers}>
                <View style={styles.suppliersInformations}>
                    <Text style={styles.suppliersTitle}>FOR SUPPLIERS</Text>
                    <Text style={styles.suppliersSubTitle}>List and promote your business to reach potential customers.</Text>
                    <Text style={styles.suppliersDesc}>Lorem ipsum dolor sit amet consectetur. Orci eget laoreet sed cras ipsum justo. Enim bibendum odio gravida odio elit egestas tortor erat magna. Ultrices at orci mauris augue pellentesque commodo. Luctus sit.</Text>
                    <TouchableOpacity
                        style={styles.learnMore}
                        onPress={comingSoon}
                    >
                        <Text style={styles.learnMoreText}>Learn more</Text>
                        <RightArrow />
                    </TouchableOpacity>
                </View>
                <MB_Image
                    style={styles.collection}
                    source={imageUtils.images.suppliersImageCollection}
                    disableInteraction
                />
            </View>
            <Footer style={styles.footer} />
        </ComponentWrapper>
    );
};

export { HomePage };

const useGetStyles = () => {
    const { width } = useWindowDimensions();
    const minWidth = 375;
    const maxWidth = 1440;
    return useMemo(() => StyleSheet.create({
        ...styles,
        backgroundImage: StyleSheet.flatten([styles.backgroundImage, {
            height: utils.getRelativeValue(minWidth, 442, maxWidth, 580, width),
        }]),
        title: StyleSheet.flatten([styles.title, {
            fontSize: utils.getRelativeValue(minWidth, 30, maxWidth, 45, width),
            width: utils.getRelativeValue(minWidth, 295, maxWidth, 620, width),
            marginTop: utils.getRelativeValue(minWidth, 53, maxWidth, 75, width),
        }]),
        itemsContainer: StyleSheet.flatten([styles.itemsContainer, {
            paddingHorizontal: utils.getRelativeValue(minWidth, 13, maxWidth, 103, width),
            top: utils.getRelativeValue(minWidth, 401, maxWidth, 530, width),
            width: utils.getRelativeValue(minWidth, 316, maxWidth, 1100, width),
        }]),
        item: StyleSheet.flatten([styles.item, {
            width: utils.getRelativeValue(minWidth, 70, maxWidth, 80, width),
            height: utils.getRelativeValue(minWidth, 70, maxWidth, 80, width),
        }]),
        howItWorksTitleText: StyleSheet.flatten([styles.howItWorksTitleText, {
            fontSize: utils.getRelativeValue(minWidth, 24, maxWidth, 35, width),
            marginTop: utils.getRelativeValue(minWidth, 460, maxWidth, 390, width),
        }]),
        howItWorksDescText: StyleSheet.flatten([styles.howItWorksDescText, {
            width: utils.getRelativeValue(minWidth, 318, maxWidth, 995, width),
        }]),
        feater: StyleSheet.flatten([styles.feater, {
            marginTop: utils.getRelativeValue(minWidth, 33, maxWidth, 70, width),
        }]),
        iconText: StyleSheet.flatten([styles.iconText, {
            fontSize: utils.getRelativeValue(minWidth, 38, maxWidth, 48, width),
        }]),
        iconConttainer: StyleSheet.flatten([styles.iconConttainer, {
            width: utils.getRelativeValue(minWidth, 80, maxWidth, 100, width),
            height: utils.getRelativeValue(minWidth, 80, maxWidth, 100, width),
        }]),
        featerTitle: StyleSheet.flatten([styles.featerTitle, {
            fontSize: utils.getRelativeValue(minWidth, 18, maxWidth, 22, width),
        }]),
        suppliers: StyleSheet.flatten([styles.suppliers, {
            marginTop: utils.getRelativeValue(minWidth, 51, maxWidth, 130, width),
            paddingBottom: utils.getRelativeValue(minWidth, 26, maxWidth, 53, width),
            paddingHorizontal: utils.getRelativeValue(minWidth, 24, maxWidth, 50, width),
        }, width < 1020 && { width: utils.getRelativeValue(minWidth, 293, maxWidth, 671, width) + 50 }]),
        collection: StyleSheet.flatten([styles.collection, {
            width: utils.getRelativeValue(minWidth, 293, maxWidth, 435, width),
            height: utils.getRelativeValue(minWidth, 263, maxWidth, 391, width),
            marginTop: utils.getRelativeValue(minWidth, 26, maxWidth, 53, width),
        }]),
        suppliersInformations: StyleSheet.flatten([styles.suppliersInformations, {
            width: utils.getRelativeValue(minWidth, 293, maxWidth, 671, width),
            marginTop: utils.getRelativeValue(minWidth, 26, maxWidth, 53, width),
        }]),
        suppliersSubTitle: StyleSheet.flatten([styles.suppliersSubTitle, {
            fontSize: utils.getRelativeValue(minWidth, 21, maxWidth, 35, width),
        }]),
        learnMore: StyleSheet.flatten([styles.learnMore, {
            marginTop: utils.getRelativeValue(minWidth, 19, maxWidth, 27, width),
        }]),
        footer: StyleSheet.flatten([styles.footer, {
            marginTop: utils.getRelativeValue(minWidth, 28, maxWidth, 156, width),
        }]),
    }), [width]);
};

const styles = StyleSheet.create({
    header: {
        backgroundColor: 'transparent',
    },
    backgroundImage: {
        position: 'absolute',
        width: '100%',
        zIndex: -1,
    },
    title: mbTextStyles([textStyles.largestText, {
        fontSize: 30,
        color: colors.lightBlue,
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: undefined,
        letterSpacing: -0.3,
        alignSelf: 'center',
    }]),
    itemsContainer: {
        position: 'absolute',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        borderRadius: 7,
        alignSelf: 'center',
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: 3,
            color: '#000000',
            opacity: 0.1,
            radius: 10,
            elevation: 10,
        }),
    },
    itemContainer: {
        marginVertical: 11,
        marginHorizontal: 'auto',
        marginBottom: 22,
    },
    item: {
        width: 80,
        height: 80,
    },
    itemText: mbTextStyles([textStyles.smallText, {
        color: colors.bodyText,
    }]),
    spacer: {
        width: 12,
    },
    howItWorksTitleText: mbTextStyles([textStyles.largeText, {
        marginTop: 370,
        color: colors.primaryColor,
    }]),
    howItWorksDescText: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        color: colors.textSecondary2,
        marginTop: 12,
        alignSelf: 'center',
    }]),
    featersContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    iconConttainer: {
        width: 100,
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.lightBlue,
        borderRadius: 360,
    },
    iconText: {
        fontSize: 48,
    },
    feater: {
        width: 292,
        alignItems: 'center',
        marginTop: 70,
    },
    featerTitle: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        color: colors.primaryColor,
        marginTop: 21,
    }]),
    featerDesc: mbTextStyles([textStyles.smallText, {
        color: colors.textSecondary2,
        marginTop: 11,
    }]),
    suppliers: {
        backgroundColor: colors.primaryColor,
        alignSelf: 'center',
        borderRadius: 15,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    collection: {},
    suppliersInformations: {
        justifyContent: 'center',
        marginHorizontal: 'auto',
    },
    suppliersTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 13,
        color: colors.lightBlue,
        textAlign: 'left',
    }]),
    suppliersSubTitle: mbTextStyles([textStyles.largestText, {
        fontSize: 35,
        marginTop: 18,
        color: colors.lightBlue,
        textAlign: 'left',
    }]),
    suppliersDesc: mbTextStyles([textStyles.smallText, {
        fontSize: 14,
        marginTop: 8,
        color: colors.lightBlue,
        textAlign: 'left',
    }]),
    learnMore: {
        marginTop: 19,
        flexDirection: 'row',
    },
    learnMoreText: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        color: colors.secondaryColor,
        textAlign: 'left',
        marginRight: 7,
    }]),
    footer: {},
});
