import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { MB_PopUp, MB_PopUpSetDefaultStyles } from '@mightybyte/rnw.components.pop-up';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { isMobile, MB_deviceInfoInitialize } from '@mightybyte/rnw.utils.device-info';
import { MB_orientationLocker, MB_ORIENTATION_LOCK } from '@mightybyte/rnw.utils.orientation-locker';
import { mbApplyTransparency, mbPlatformStyle, mbTextStyles, mbTextStylesSetDefaultFontFamily } from '@mightybyte/rnw.utils.style-utils';
import { userApiCalls } from '../apiCalls/userApiCalls';
import { getColors } from '../constants/colors';
import { FONT_STYLES, textStyles } from '../constants/textStyles';
import { fontLoader } from '../utils/fontLoader';
import { MB_Toast } from '@mightybyte/rnw.components.toast';
import { MB_ModalAttachGlobalChildren, MB_ModalSetDefaultProps } from '@mightybyte/rnw.components.modal';
import { MB_ResourceUploaderSetDefaultStyles } from '@mightybyte/rnw.components.resource-uploader';
import { CustomToastConfig } from '../components/helperComponents/CustomToastConfig';
import { TextProps, TextInputProps, Text, TextInput } from 'react-native';
import { MB_TextInputSetDefaultStyles } from '@mightybyte/rnw.components.text-input';
import { MB_ButtonSetDefaultStyles } from '@mightybyte/rnw.components.button';

const COLORS = getColors();

type WithDefaultProps<T> = T & {
    defaultProps?: Object
}

function disableFontScaling() {
    (Text as WithDefaultProps<TextProps>).defaultProps = { allowFontScaling: false };
    (TextInput as WithDefaultProps<TextInputProps>).defaultProps = { allowFontScaling: false };
}

function useAppInitialSetup() {
    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
        async function performInitialSetup() {

            disableFontScaling();

            await MB_deviceInfoInitialize();

            MB_orientationLocker.setDefaultOrientation(MB_ORIENTATION_LOCK.PORTRAIT);

            mbTextStylesSetDefaultFontFamily(FONT_STYLES.JakartaBase);

            //todo: ASK Hovo about errors in userApiCalls.requestNewAccessToken (return void??? and "as any" here)
            MB_accessTokenUtils.setAccessTokenRefreshEndpointFunction(userApiCalls.requestNewAccessToken as any);

            MB_TextInputSetDefaultStyles({
                titleStyle: styles.textInputTitle,
                style: styles.textInputStyle,
                placeholderTextColor: COLORS.placeholderTextColor,
                textInputStyle: styles.textInputTextStyle,
            });

            MB_ButtonSetDefaultStyles({
                style: styles.button,
                textStyle: styles.buttonText,
            });

            MB_PopUpSetDefaultStyles({
                defaultContainerStyle: styles.modalPopUpDefaultContainer,
                defaultTitleStyle: styles.modalPopUpDefaultTitle,
                defaultMessageStyle: styles.modalPopUpDefaultMessage,
                defaultPrimaryButtonStyle: styles.modalPopUpDefaultPrimaryButton,
                defaultPrimaryButtonTextStyle: styles.modalPopUpDefaultPrimaryButtonText,
                defaultSeconaryButtonStyle: styles.modalPopUpDefaultSecondaryButton,
                defaultSecondaryButtonTextStyle: styles.modalPopUpDefaultSecondaryButtonText,
                defaultButtonsContainerStyleModifier: styles.modalPopUpDefaultButtonsContainerModifier,
            });

            MB_ModalSetDefaultProps({
                childrenWrapperStyle: styles.modalChildrenWrapper,
                titleStyle: styles.modalTitle,
                closeButtonXProps: {
                    size: 16,
                    color: COLORS.primaryColor,
                },
            });

            MB_ResourceUploaderSetDefaultStyles({
                wrapperStyle: styles.resourceUploaderDefaultContainer,
                uploadButtonStyle: styles.resourceUploaderButton,
                previewContainerStyle: styles.resourceUploaderPreviewContainer,
                previewImageBackgroundStyle: styles.resourceUploaderPreviewImageBackground,
                dragAndDropAndProcessingResourceTextStyle: styles.resourceUploaderDragAndDropText,
            });

            try {
                await fontLoader();
            } catch (error) {
                console.error('Prepare stage failed', error);
            } finally {
                setIsDone(true);
            }
        }

        MB_ModalAttachGlobalChildren([
            <MB_PopUp key={'popUp'} />,
            <MB_Toast config={CustomToastConfig} key={'toast'} />,
        ]);

        if (!isDone) {
            performInitialSetup();
        }
    }, [isDone]);

    return isDone;
}


export { useAppInitialSetup };

const styles = StyleSheet.create({
    modalPopUpDefaultContainer: {
        backgroundColor: COLORS.backgroundPrimary,
        borderRadius: 8,
        paddingTop: 32,
        paddingHorizontal: isMobile ? 18 : 76,
        paddingBottom: 32,
        marginHorizontal: 16,
    },
    modalPopUpDefaultTitle: mbTextStyles([
        textStyles.largeText, {
            fontWeight: '700',
            color: COLORS.primaryColor,
            ...mbPlatformStyle({
                mobile: {
                    fontSize: 18,
                },
            }),
        },
    ]),
    modalPopUpDefaultMessage: mbTextStyles([
        textStyles.smallText, {
            marginTop: isMobile ? 10 : 16,
            color: COLORS.textPrimary,
            ...mbPlatformStyle({
                mobile: {
                    fontSize: 14,
                },
            }),
        },
    ]),
    modalPopUpDefaultPrimaryButton: {
        height: isMobile ? 38 : 38,
        minWidth: 115,
        alignSelf: 'center',
        backgroundColor: COLORS.buttonPrimaryBg,
    },
    modalPopUpDefaultPrimaryButtonText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.textPrimary,
        },
    ]),
    modalPopUpDefaultSecondaryButtonText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.textSecondary,
        },
    ]),
    modalPopUpDefaultSecondaryButton: {
        height: isMobile ? 38 : 38,
        minWidth: 115,
        backgroundColor: COLORS.textPrimary,
    },
    modalPopUpDefaultButtonsContainerModifier: {
        marginTop: 33,
    },
    resourceUploaderDefaultContainer: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 68.5,
    },
    resourceUploaderPreviewContainer: {
        backgroundColor: mbApplyTransparency('#F4F9FF', 0.4),
        borderWidth: 1,
        borderColor: COLORS.accentColor,
        borderStyle: 'dashed',
    },
    resourceUploaderDragAndDropText: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.accentColor,
            fontWeight: '600',
        },
    ]),
    resourceUploaderButton: {
        minWidth: 228,
    },
    resourceUploaderPreviewImageBackground: {
        borderRadius: 300,
        width: 82,
        height: 82,
        backgroundColor: COLORS.accentColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
    textInputTitle: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.bodyText,
            fontWeight: '600',
            fontSize: 13,
        },
    ]),
    textInputStyle: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: COLORS.strokes,
    },
    textInputTextStyle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.textInputText,
            textAlign: 'left',
        },
    ]),

    button: {
        backgroundColor: COLORS.secondaryColor,
        borderRadius: 5,
        height: 44,
    },
    buttonText: mbTextStyles([
        textStyles.smallText, {
            fontSize: 13,
            fontWeight: '600',
            color: COLORS.primaryColor,
        },
    ]),
    modalChildrenWrapper: {
        backgroundColor: COLORS.white,
    },
    modalTitle: mbTextStyles([
        textStyles.largeText, {
            fontWeight: '600',
            color: COLORS.primaryColor,
        },
    ]),
});
