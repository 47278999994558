import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import { getColors } from '../../../../constants/colors';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { CustomTags } from '../../../helperComponents/CustomTags';
import { UploadLogo } from '../../../../resources/svgComponents/UploadLogo';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { MB_RESOURCE_PICKER_TYPE, MB_ResourceUploader, MB_ResourceUploaderFunctionProps } from '@mightybyte/rnw.components.resource-uploader';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { ImageFileExtension, ProductDataForClient, TagDataForClient, TagType } from '@id/business';
import { trpc } from '../../../../apiCalls/trpcClient';
import { MB_Tag, MB_TagsRef } from '@mightybyte/rnw.components.tags';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { AddNewTag } from '../../../helperComponents/AddNewTag';
import { isEqual } from 'lodash';
import { utils } from '../../../../utils/utils';
import { resourceUploadApiCalls } from '../../../../apiCalls/resourceUploadApiCalls';
import { mbGetSmallestImage } from '../../../../utils/imageUtils';

const COLORS = getColors();

const ProductManagementModalContent = ({ companyId, productToEdit, onCreateOrEdit }: { companyId: string, productToEdit?: ProductDataForClient, onCreateOrEdit: () => void }) => {
    const [isAddNewTagVisible, showAddNewTag, hideAddNewTag] = MB_utilHooks.useBool();
    const [productTags, setProductTags] = useState<Omit<TagDataForClient, 'id'>[]>([]);
    const tagsRef = useRef<MB_TagsRef>(null);
    const [logo, setLogo] = useState<MB_ResourceUploaderFunctionProps | undefined>();
    const [isResourcePickerVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const getUploadUrl = trpc.admin.product.getImageUploadUrl.useMutation();

    const { data: tags } = trpc.tag.getAll.useQuery({ type: TagType.product });
    const productCreator = trpc.admin.product.create.useMutation({
        onSuccess: () => {
            onCreateOrEdit();
        }, // TODO: Hovo: Handle
        onError: () => { }, // TODO: Hovo: Handle
    });
    const productEditor = trpc.admin.product.edit.useMutation({
        onSuccess: () => {
            onCreateOrEdit();
        }, // TODO: Hovo: Handle
        onError: () => { }, // TODO: Hovo: Handle
    });

    useEffect(() => {
        if (productToEdit) {
            setName(productToEdit.name);
            setDescription(productToEdit.description ?? '');
        }
    }, [productToEdit]);

    // TODO: Hovo: We need to combine this into a util
    function imageUploader({ fileProps, uploadProgressListener }: { fileProps: MB_ResourceUploaderFunctionProps, uploadProgressListener: (percentCompleted: number) => void }): Promise<string> {

        return new Promise<string>((resolve, reject) => {
            if (!fileProps.blob) {
                reject('Something went wrong');
            }

            const extension = utils.enumFromStringValue(ImageFileExtension, fileProps.mimeType.split('/')[1]);

            if (!extension) {
                reject('Error: Invalid image format');
                return;
            }

            getUploadUrl.mutate({ fileExtension: extension }, {
                onSuccess: async (data) => {
                    resourceUploadApiCalls.uploadResource({
                        method: data.requestMethod,
                        url: data.signedUrl,
                        imageData: fileProps.blob!,
                        headers: data.headers,
                        uploadProgress: uploadProgressListener,
                    })
                        .then(() => {
                            resolve(data.fileName);
                        })
                        .catch(() => reject('Something went wrong during upload'));
                },
                onError: (error) => {
                    // TODO: HOVO: Finish
                    console.log('Error data', error);
                },
            });
        });
    }

    const imageVideoUploader = (uploadProgressListener: (percentCompleted: number) => void) => async (fileProps: MB_ResourceUploaderFunctionProps): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!productToEdit) {
                setLogo(fileProps);
                resolve();
                return;
            }

            imageUploader({ fileProps, uploadProgressListener })
                .then(fileName => {
                    productEditor.mutate({ id: productToEdit.id, imageFilename: fileName }, {
                        onSuccess: () => {
                            onCreateOrEdit();
                            resolve();
                        },
                        onError: () => reject('Something went wrong during upload'),
                    });
                })
                .catch(error => reject(error));
        });
    };

    const initialTags = useMemo(() => {
        if (!tags) {
            return undefined;
        }
        return tags.tags.filter(tg => productToEdit?.tagsIds?.includes(tg.id)).map(tag => ({ title: tag.name }));
    }, [productToEdit, tags]);

    const filteredTags = useMemo(() => {
        return (tags?.tags ?? []).map(tg => ({ id: tg.id, title: tg.name }));
    }, [tags?.tags]);

    const onTagsChange = useCallback((newTags: MB_Tag[]) => { // TODO: Hovo: This is a mess
        setProductTags(newTags.map(newTag => ({ name: newTag.title, type: TagType.sports })));
    }, []);

    function onTagCreatorDismissed(props: { name: string, createdOrSelected: 'created' | 'selected' } | undefined) {
        hideAddNewTag();

        if (props && productTags && !productTags.map(t => t.name).includes(props.name)) {
            tagsRef.current?.addTag({ title: props.name.toLowerCase() });
            if (props.createdOrSelected === 'created') {
                mbShowToast({ text1: 'Success' }); // TODO: Hovo: Style this
            }
        }
    }

    function isButtonDisabled() {
        if (productCreator.isLoading || productEditor.isLoading || !name || productTags?.length < 1) {
            return true;
        }

        if (!productToEdit) {
            return false;
        }

        const productTagsIds = (tags?.tags ?? []).filter(tag => productTags?.map(tg => tg.name).includes(tag.name)).map(tag => tag.id);

        const tagsEqual = isEqual(productToEdit?.tagsIds, productTagsIds);

        if (name !== productToEdit.name || (description !== (productToEdit.description ?? '')) || !tagsEqual) {
            return false;
        }

        return true;
    }

    async function onAddOrEditPressed() {
        if (name && productTags.length > 0) {
            if (!productToEdit) {

                let imageFilename: string | undefined;

                if (logo !== undefined) {
                    try {
                        imageFilename = await imageUploader({ fileProps: logo, uploadProgressListener: () => { } });
                    } catch {
                        console.error('ERROR CREATING COMPANY LOGO');
                    }
                }

                productCreator.mutate({
                    imageFilename,
                    companyId,
                    name,
                    tagsIds: (tags?.tags ?? []).filter(tag => productTags?.map(tg => tg.name).includes(tag.name)).map(tag => tag.id),
                    description,
                });
            } else {
                productEditor.mutate({
                    id: productToEdit.id,
                    name,
                    tagsIds: (tags?.tags ?? []).filter(tag => productTags?.map(tg => tg.name).includes(tag.name)).map(tag => tag.id),
                    description,
                });
            }
        }
    }

    const productImage = mbGetSmallestImage(productToEdit?.imageUrl);

    return ( // TODO: Add tags
        <>
            <AddNewTag
                isVisible={isAddNewTagVisible}
                onDismiss={onTagCreatorDismissed}
                allTags={tags?.tags ?? []}
                type={TagType.product}
            />
            <MB_ResourceUploader
                isVisible={isResourcePickerVisible}
                onDismiss={() => {
                    hideResourcePicker();
                }}
                uploaderFunction={imageVideoUploader}
                pageTitle={'Select Company Logo'}
                uploadButtonTitle="Upload"
                resourcePickerType={MB_RESOURCE_PICKER_TYPE.image}
            />
            <Pressable style={{ alignItems: 'center', alignSelf: 'center' }} onPress={showResourcePicker}>
                {(!productImage && logo === undefined) ?
                    <>
                        <UploadLogo />
                    </>
                    :
                    <>
                        <MB_Image source={productImage ?? logo?.uri ?? ''} style={{ width: 94, height: 94, borderRadius: 300 }} disableInteraction />
                    </>
                }
                <Text style={styles.uploadLogoTitle}>Upload Logo</Text>
                <Text style={styles.uploadLogoSubtitle}>{'(.jpg, .jpeg or .png file\nformat supported)'}</Text>
            </Pressable>
            <MB_TextInput
                title="Product name"
                placeholder="Product name"
                showRequiredAsteriks
                value={name}
                onChangeText={setName}
                style={{ width: '100%' }}
                maxLength={20}
                showCharacterLimit
            />
            <CustomTags
                MB_Ref={tagsRef}
                title="Product tags *"
                initialTags={initialTags}
                allTags={filteredTags}
                onTagsChange={onTagsChange}
                onAddNewPressed={showAddNewTag}
            />
            <MB_TextInput
                title="Description"
                placeholder="Product description"
                multiline
                style={styles.textInputDescription}
                showCharacterLimit
                maxLength={100}
                value={description}
                onChangeText={setDescription}
            />

            <MB_Button
                title={productToEdit === undefined ? 'Add New Product' : 'Save Changes'}
                style={{
                    alignSelf: 'flex-end',
                    marginTop: 25,
                }}
                disabled={isButtonDisabled()}
                loading={productCreator.isLoading || productEditor.isLoading}
                onPress={onAddOrEditPressed}
            />
        </>
    );
};



export { ProductManagementModalContent };

const styles = StyleSheet.create({
    textInputDescription: {
        width: '100%',
        marginTop: 22,
        height: 168,
    },
    uploadLogoTitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.accentColor,
            marginTop: 12,
        },
    ]),
    uploadLogoSubtitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.bodyText2,
            fontSize: 11,
            fontWeight: '300',
        },
    ]),
});
