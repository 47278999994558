import { useEffect, useRef } from 'react';
import { Dimensions, useWindowDimensions } from 'react-native';

const utilHooks = {
    useWidthChange: (isActive: boolean, callBack: () => void) => {
        const dims = useWindowDimensions();
        const windowWidth = useRef(dims.width);

        // We need to set the width when window gets activated, otherwise if you change the window width and
        // then mount this, callback will get called.
        useEffect(() => {
            if (isActive) {
                windowWidth.current = Dimensions.get('window').width;
            }
        }, [isActive]);

        useEffect(() => {
            if (!isActive) {
                return;
            }

            if (dims.width !== windowWidth.current) {
                callBack();
                windowWidth.current = dims.width;
            }
        }, [callBack, dims.width, isActive]);
    },
};

export { utilHooks };
