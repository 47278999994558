import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const LongArrowLeftIcon = () => {
    return (
        <Svg
            width={13}
            height={12}
            viewBox="0 0 13 12"
            fill="none"
        >
            <Path
                d="M2.625 6.47h7.5M5.65 9.483L2.623 6.47 5.65 3.458"
                stroke="#1F2136"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export { LongArrowLeftIcon };
