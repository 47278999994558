import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const Buildings = React.memo((props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 21}
                height={props.size ? props.size * (20 / 21) : 20}
                viewBox="0 0 21 20"
                fill="none"
            >
                <Path
                    d="M7.68 12.375H4.71a.742.742 0 010-1.484h2.97a.742.742 0 010 1.484zM7.68 9.406H4.71a.742.742 0 110-1.484h2.97a.742.742 0 110 1.484zM7.68 6.438H4.71a.742.742 0 110-1.485h2.97a.742.742 0 110 1.484zM17.773 4.953H13.32a.742.742 0 100 1.484h4.453c.41 0 .743.333.743.743v10.093c0 .41-.333.743-.743.743h-.742v-3.414a.742.742 0 00-.742-.743H13.32a.742.742 0 00-.742.743v4.156c0 .41.332.742.742.742h4.453A2.23 2.23 0 0020 17.273V7.18a2.23 2.23 0 00-2.227-2.227zm-2.226 10.39v2.673h-1.485v-2.672h1.485z"
                    fill="#EEF2F7"
                    stroke="#1F2136"
                    strokeWidth={0.8}
                />
                <Path
                    d="M13.32 12.375h2.97a.742.742 0 000-1.484h-2.97a.742.742 0 000 1.484zM13.32 9.406h2.97a.742.742 0 000-1.484h-2.97a.742.742 0 100 1.484zM9.705 2.05L3.768.567A2.23 2.23 0 001 2.726v14.547A2.23 2.23 0 003.227 19.5h5.937a2.229 2.229 0 002.227-2.227V4.211a2.223 2.223 0 00-1.686-2.16zM6.937 18.017H5.454v-2.672h1.484v2.672zm2.97-.743c0 .41-.334.743-.743.743h-.742v-3.414a.742.742 0 00-.742-.743H4.71a.742.742 0 00-.741.743v3.414h-.742a.743.743 0 01-.743-.743V2.727a.743.743 0 01.924-.72L9.345 3.49a.74.74 0 01.561.72v13.062z"
                    fill="#EEF2F7"
                    stroke="#1F2136"
                    strokeWidth={0.8}
                />
            </Svg>
        </View>
    );
});

export { Buildings };
