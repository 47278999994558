import React from 'react';

import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { getColors } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

const COLORS = getColors();

const LogoText = ({style, altColors}: {style?: StyleProp<ViewStyle>, altColors?: boolean}) => {
    return (
        <View style={[styles.container, style]}>
            <Text style={[styles.primaryText, {color: altColors ? COLORS.primaryColor : COLORS.white}]}>
                International <Text style={{color: altColors ? COLORS.textSecondary2 : COLORS.textSecondary}}>Sports Directory</Text>
            </Text>
            <Text style={[styles.secondaryText, {color: altColors ? COLORS.textSecondary2 : COLORS.textSecondary} ]}>Powered by ScoutUs Pro.</Text>
        </View>
    );
};

export { LogoText };



const styles = StyleSheet.create({
    container: {

    },
    loginBannerContainer: {
    },
    primaryText: mbTextStyles([
        textStyles.largeText, {
            fontSize: 22,
            fontWeight: '600',
            textAlign: 'left',
        },
    ]),
    secondaryText: mbTextStyles([
        textStyles.smallText, {
            fontSize: 11,
            fontWeight: '600',
            textAlign: 'left',
            color: COLORS.textSecondary,
        },
    ]),
});
