import React from 'react';
import { utils } from '../utils/utils';
import { SignedInStatusContextProvider } from './SignedInContext';
import { QueryContextProvider } from './QueryContextProvider';

const providers: React.FC[] = [
    QueryContextProvider,
    SignedInStatusContextProvider,
];

const AppContextProvider = utils.combineComponents(providers);

export { AppContextProvider };
