import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { SearchPageNavProps, UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { Header } from '../../helperComponents/Header/Header';
import { FlatList, ListRenderItemInfo, Text, View } from 'react-native';
import { getColors } from '../../../constants/colors';
import DynamicStyleSheet, { useBreakpoints, useDynamicStyles } from '../../../utils/DynamicStyleSheet';
import { Search } from '../HomePage/Search';
import { SearchFilter } from '../HomePage/FilterButton';
import { trpc } from '../../../apiCalls/trpcClient';
import { CompanyDataForClient, TagDataForClient, TagType } from '@id/business';
import { mbTagSearch } from '@mightybyte/rnw.components.tags';
import { textStyles } from '../../../constants/textStyles';
import { Footer } from '../HomePage/Footer';
import { CompanyCard } from './CompanyCard';
import { MB_PageIndicator } from '@mightybyte/rnw.components.page-indicator';
import { LongArrowLeftIcon } from '../../../resources/svgComponents/LongArrowLeftIcon';
import { LongArrowRightIcon } from '../../../resources/svgComponents/LongArrowRightIcon';
import { NavIndicator } from '../../helperComponents/NavIndicator';
import { LoadingPage } from '../../helperComponents/LoadingPage';
import { useNavigation } from '@react-navigation/core';
import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';

const colors = getColors();

const useGetSuppliers = (queryOrTagId: string, page: number, isTagId: boolean) => {
    const [token, setToken] = useState<string | null | undefined>();

    const { data: suppliersByName, isLoading: isSuppliersByNameLoading } = trpc.supplier.getSuppliersCompaniesByName.useQuery({ name: queryOrTagId }, { enabled: !isTagId });
    const { data: suppliersById, isLoading: isSuppliersByIdLoading } = trpc.supplier.getSuppliersCompaniesByTag.useQuery({ tagId: queryOrTagId, page, pageSize: 10, lastToken: token }, { enabled: isTagId });

    useEffect(() => {
        if (page === 1) {
            setToken(undefined);
        } else if (suppliersById?.nextToken) {
            setToken(suppliersById.nextToken);
        }
    }, [page, suppliersById?.nextToken]);

    return {
        data: isTagId ? suppliersById?.companies : suppliersByName?.companies,
        isLoading: isTagId ? isSuppliersByIdLoading : isSuppliersByNameLoading,
        pages: Math.ceil((suppliersById?.totalItems ?? suppliersByName?.companies.length ?? 10) / 10),
        totalItems: suppliersById?.totalItems ?? suppliersByName?.companies.length ?? 10,
        nextToken: suppliersById?.nextToken,
    };
};


const IncorrectSearch = ({ tags, filter, query }: { tags: TagDataForClient[], filter: SearchFilter, query: string }) => {

    const styles = useDynamicStyles(dynamicStyles, { minWidth: 375, maxWidth: 1440 });
    const suggestions = mbTagSearch(tags.map(tag => ({ title: tag.name })) ?? [], query);
    const navigation = useNavigation<UniversalScreenNavigationProp>();

    return (
        <ComponentWrapper hideWebHeader>
            <Header hideLogo={false} containerStyle={styles.header} altLogoColors />
            <View style={styles.cover} />
            <Search filter={filter} value={query} />
            {query.trim() !== '' &&
                <View style={styles.incorrectSearch}>
                    <Text style={styles.incorrectSearchTitle}>Search results for “{query}”</Text>
                    <Text style={styles.incorrectSearchDesc}>Looking for one of these {filter}? Click any one to find suppliers around you.</Text>
                    <View style={styles.suggestions}>
                        {suggestions.map(tag => (
                            <View key={tag.item.title} style={styles.suggestionsItem}>
                                <Text>•</Text>
                                <Text
                                    onPress={() => navigation.navigate('SearchPage', { filter, query: tag.item.title })}
                                    style={styles.suggestionsItemText}
                                >
                                    {tag.item.title}
                                </Text>
                            </View>
                        ))}
                    </View>
                </View>
            }
            <Footer style={styles.footer} />
        </ComponentWrapper>
    );
};

const SearchPageWeb = ({ tagId, query, filter }: { tagId?: string, query: string, filter: SearchFilter }) => {

    const [page, setPage] = useState(1);
    const styles = useDynamicStyles(dynamicStyles, { minWidth: 375, maxWidth: 1440 });
    const [offsetY, setOffsetY] = useState(0);

    const { data: companies, isLoading, pages, totalItems } = useGetSuppliers(tagId ?? query, page, tagId !== undefined);

    const firstItemIndex = ((page - 1) * 10) + 1;

    const onScroll = useCallback((e: any) => setOffsetY(e.nativeEvent.contentOffset.y), []);

    const stickyAd = useMemo(() => offsetY > 250 ? ({ position: 'sticky' as 'absolute', top: 10 }) : undefined, [offsetY]);

    return (
        <ComponentWrapper
            hideWebHeader
            wrapInScrollView
            onScroll={onScroll}
        >
            <Header hideLogo={false} containerStyle={styles.header} altLogoColors />
            <View style={styles.cover} />
            <Search filter={filter} value={query} />
            <View>
                <NavIndicator style={styles.navIndicator} />
                <View style={styles.content}>
                    <View style={styles.filterSide} />
                    {(isLoading || !companies) ?
                        <View style={styles.products}>
                            <BarIndicator color={colors.secondaryColor} count={5} style={styles.loading} />
                        </View>
                        :
                        <View style={styles.products}>
                            <Text style={styles.searchTitle}>{query}</Text>
                            <Text style={styles.searchDesc}>{companies.length === 0 ? 'No company found' : `Displaying from ${firstItemIndex} to ${firstItemIndex + companies.length - 1} of ${totalItems} suppliers`}</Text>
                            {companies?.map(company => (
                                <CompanyCard
                                    key={company.id}
                                    company={company}
                                />
                            ))}
                        </View>
                    }
                    <View style={[styles.adSide, stickyAd]} />
                </View>
                <MB_PageIndicator
                    current={page}
                    onChange={setPage}
                    pages={pages}
                    style={styles.pageIndicator}
                    indicatorButtonProp={{
                        containerStyle: styles.indicatorWithoutEndSpace,
                        buttonStyle: styles.indicatorBtn,
                        activeColor: '#FFF',
                        hoverColor: colors.primaryColor,
                        selectColor: colors.primaryColor,
                        activeTextColor: '#FFF',
                        inactiveTextColor: colors.textSecondary2,
                    }}
                    rightButtonProps={{
                        title: '',
                        buttonStyle: styles.indicatorRightArrow,
                        icon: <LongArrowRightIcon />,
                        activeColor: '#FFF',
                        hoverColor: colors.primaryColor,
                        selectColor: colors.primaryColor,
                    }}
                    leftButtonProps={{
                        title: '',
                        containerStyle: styles.indicatorWithoutEndSpace,
                        buttonStyle: styles.indicatorLeftArrow,
                        icon: <LongArrowLeftIcon />,
                        activeColor: '#FFF',
                        hoverColor: colors.primaryColor,
                        selectColor: colors.primaryColor,
                    }}
                />
            </View>
            <Footer style={styles.footer} />
        </ComponentWrapper>
    );
};

const SearchPageMobile = ({ tagId, query, filter }: { tagId?: string, query: string, filter: SearchFilter }) => {

    const styles = useDynamicStyles(dynamicStyles, { minWidth: 375, maxWidth: 1440 });
    const [page, setPage] = useState(1);
    const [companies, setCompanies] = useState<CompanyDataForClient[]>();

    const { data, totalItems, isLoading, nextToken } = useGetSuppliers(tagId ?? query, page, tagId !== undefined);

    useEffect(() => {
        setCompanies(oldCompanies => [...oldCompanies ?? [], ...data ?? []]);
    }, [data]);

    const loadMore = useCallback(() => {
        if (nextToken) {
            setPage(page + 1);
        }
    }, [nextToken, page]);

    const ListHeaderComponent = useCallback(() => (
        <>
            <Header hideLogo={false} containerStyle={styles.header} altLogoColors />
            <View style={styles.cover} />
            <Search filter={filter} value={query} />
            <View style={styles.content}>
                <View>
                    <View style={styles.mobileAd} />
                    {!isLoading &&
                        <>
                            <Text style={styles.searchTitle}>{query}</Text>
                            <Text style={styles.searchDesc}>{companies?.length === 0 ? 'No company found' : `Displaying ${companies?.length} of ${totalItems} suppliers`}</Text>
                        </>
                    }
                </View>
            </View>
        </>
    ), [companies?.length, filter, isLoading, query, styles.content, styles.cover, styles.header, styles.mobileAd, styles.searchDesc, styles.searchTitle, totalItems]);

    const ListFooterComponent = useCallback(() => (
        (isLoading || nextToken) ?
            <>
                <BarIndicator color={colors.secondaryColor} count={5} style={styles.loading} />
                <Footer style={styles.footer} />
            </>
            :
            <Footer style={styles.footer} />
    ), [isLoading, nextToken, styles.footer, styles.loading]);

    const renderItem = useCallback(({ item }: ListRenderItemInfo<CompanyDataForClient>) => {
        return <CompanyCard company={item} style={styles.card} />;
    }, [styles.card]);

    const keyExtractor = useCallback((item: CompanyDataForClient) => item.id, []);

    return (
        <FlatList
            keyExtractor={keyExtractor}
            ListHeaderComponent={ListHeaderComponent}
            data={companies ?? []}
            renderItem={renderItem}
            onEndReached={loadMore}
            onEndReachedThreshold={0.5}
            ListFooterComponent={ListFooterComponent}
        />
    );
};

const SearchPage = ({ route }: SearchPageNavProps) => {

    const { isExtraLargeAndUp } = useBreakpoints();

    const { filter, query } = route.params;

    const { data: tagsData, isLoading: isTagsLoading } = trpc.tag.getAll.useQuery({ type: filter === SearchFilter.Sport ? TagType.sports : TagType.product });

    const queryTag = tagsData?.tags.find(tag => tag.name === query);
    const isIncorrectSearch = filter !== SearchFilter.Supplier && !queryTag && !isTagsLoading;

    if (isTagsLoading || !tagsData) {
        return <LoadingPage />;
    }

    if (isIncorrectSearch || query.trim() === '') {
        return <IncorrectSearch tags={tagsData.tags} filter={filter} query={query} />;
    }

    if (isExtraLargeAndUp) {
        return <SearchPageWeb tagId={queryTag?.id} query={query} filter={filter} />;
    }

    return <SearchPageMobile key={query} tagId={queryTag?.id} query={query} filter={filter} />;
};

export { SearchPage };

const dynamicStyles = DynamicStyleSheet.create({
    header: {
        backgroundColor: 'transparent',
    },
    cover: {
        position: 'absolute',
        width: '100%',
        zIndex: -1,
        backgroundColor: colors.lightBlue,
        height: [300, 199],
    },
    incorrectSearch: {
        marginLeft: 80,
    },
    incorrectSearchTitle: {
        ...textStyles.largeText,
        marginTop: [180, 77],
        color: colors.primaryColor,
        textAlign: 'left',
    },
    incorrectSearchDesc: {
        ...textStyles.smallText,
        fontSize: 15,
        marginTop: 8,
        color: colors.bodyText2,
        textAlign: 'left',
    },
    suggestions: {
        marginTop: 28,
    },
    suggestionsItem: {
        marginTop: 18,
        flexDirection: 'row',
    },
    suggestionsItemText: {
        ...textStyles.smallText,
        textDecorationLine: 'underline',
        marginLeft: 6,
        color: colors.bodyText,
    },
    footer: {
        marginTop: 122,
    },
    navIndicator: {
        marginTop: 90,
        marginLeft: [22, 60],
        opacity: {
            xs: 0,
            l: 1,
        },
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: ({ isExtraLargeAndUp }) => isExtraLargeAndUp ? 46 : 150,
        alignItems: 'flex-start',
    },
    filterSide: {
        width: 270,
        height: 659,
        backgroundColor: '#f00',
    },
    products: {
        marginRight: [0, 26],
        marginLeft: [0, 54],
        width: [352, 635],
    },
    card: {
        marginHorizontal: 'auto',
    },
    adSide: {
        width: 335,
        height: 1062,
        backgroundColor: '#f0f',
    },
    mobileAd: {
        backgroundColor: '#f0f',
        width: [352, 635],
        height: 234,
        marginBottom: 31,
    },
    loading: {
        marginTop: 20,
        alignSelf: 'center',
    },
    searchTitle: {
        ...textStyles.largestText,
        fontSize: 35,
        color: colors.primaryColor,
        textAlign: 'left',
    },
    searchDesc: {
        ...textStyles.smallText,
        color: colors.primaryColor,
        textAlign: 'left',
        marginTop: 7,
        marginBottom: [29, 47],
    },
    pageIndicator: {
        alignSelf: 'center',
        marginTop: 70,
    },
    indicatorBtn: {
        borderRadius: 0,
        width: 28,
        height: 28,
    },
    indicatorRightArrow: {
        borderRadius: 0,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        width: 28,
        height: 28,
    },
    indicatorWithoutEndSpace: {
        marginEnd: 0,
    },
    indicatorLeftArrow: {
        borderRadius: 0,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        width: 28,
        height: 28,
    },
});
